import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withStyles } from "@mui/styles";
import { Grid, Tooltip, Typography } from "@mui/material";
import Button from "../../../common/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ConfirmDialoag from "../../../common/ConfirmDialoag";
import instance from "../../../../axios";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../Helpers";
const styles = (theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing(1),
    },
});
let source;
class SyncVehicles extends Component {
    constructor() {
        super();
        this.state = {
            isShowSyncModal: false
        };
    }

    onVehicleSendData = () => {
        if (this.props.selectedAccount) {
            instance({
                url: `/api/vehicles/integrationwithcontractno?cn=${this.props.selectedAccount?.contractNumber}`,
                method: 'GET',
            })
                .then((response) => {
                    toast.success(this.props.translate(response));
                    this.setState({
                        isShowSyncModal: false
                    })
                }).catch(error => {
                    errorHandler(error, this.props.dispatch)
                    this.setState({
                        isShowSyncModal: false
                    })
                }

                )
        }

    }

    handleOpenModal = () => {
        this.setState({
            isShowSyncModal: true
        })
    }

    handleCancel = () => {
        this.setState({
            isShowSyncModal: false
        })
    }

    render() {
        return (
            <Fragment>
                <Grid container className="breadcrumb-row">
                    <Grid item xs={12} sm={12}>
                        <h3 style={{ margin: 0 }}>{this.props.translate("Sync Vehicles")}</h3>
                    </Grid>

                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ display: "flex", marginTop: 10, alignItems: "center", gap: "0.5rem" }}
                    >
                        <Button onClick={this.handleOpenModal}>{this.props.translate("syncVehicle")}</Button>
                        <Tooltip
                            title={
                                <Typography variant="body2">
                                    By clicking Sync Vehicle, all the vehicles of{" "}
                                    <strong style={{ fontWeight: "700" }}>{this.props.selectedAccount?.contractNumber}</strong> will be sent to servers.
                                </Typography>
                            }>
                            <InfoOutlinedIcon color="action" style={{ cursor: "pointer", fontSize: "2rem" }} />
                        </Tooltip>
                    </Grid>
                </Grid>
                {this.state.isShowSyncModal && (
                    <>
                        <ConfirmDialoag
                            onCancel={this.handleCancel}
                            onOk={this.onVehicleSendData}
                            title={this.props.translate("Are you sure ?")}
                            children={<p>
                                Vehicles of <strong>{this.props.selectedAccount?.contractNumber} </strong>
                                will be sent to servers.
                            </p>}
                        />
                    </>
                )}
            </Fragment>
        );
    }
}

const enhance = compose(withStyles(styles), connect());

export default enhance(SyncVehicles);


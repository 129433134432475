import React, { Component } from 'react'
import TextField from './../common/TextField';
import { withStyles } from '@mui/styles';
import { displayErrorMessages } from './../../Helpers'
import Button from '../common/Button';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { FormHelperText, InputAdornment } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});
const ValidationIcon = ({ isValid, message }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {isValid ? (
      <CheckCircleIcon style={{ color: 'green', fontSize: 12 }} />
    ) : (
      <CancelIcon style={{ color: 'red', fontSize: 12 }} />
    )}
    <span style={{ marginLeft: '5px',color:"gray" }}>{message}</span>
  </div>
);
const PasswordRequirements = ({
  hasLengthValid,
  hasNumberValid,
  hasUpperCaseValid,
  hasLowerCaseValid,
  hasSpecialCharValid,
  translate,
}) => (
  <FormHelperText style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
    <ValidationIcon
      isValid={hasLengthValid}
      message={translate('Length must be at least 8 characters')}
    />
    <ValidationIcon
      isValid={hasNumberValid}
      message={translate('Must contain at least one Number')}
    />
    <ValidationIcon
      isValid={hasUpperCaseValid}
      message={translate('Must contain at least one Uppercase letter')}
    />
    <ValidationIcon
      isValid={hasLowerCaseValid}
      message={translate('Must contain at least one Lowercase letter')}
    />
    <ValidationIcon
      isValid={hasSpecialCharValid}
      message={translate('Must contain at least one Special character (!@#$%^&*(),.?:{}|<></>)')}
    />
  </FormHelperText>
);

class changedPasword extends Component {
  constructor() {
    super();
    this.state = {
      currentPawsErrorMgs: false,
      nPawsErrorMgs: false,
      form: {
        newPassword: '',
        reapetNewPassword: '',
        currentPassword: ''
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  formReset() {
    this.setState({
      currentPawsErrorMgs: false,
      nPawsErrorMgs: false,
      form: {
        newPassword: '',
        reapetNewPassword: '',
        currentPassword: ''
      }
    })
  }
  formSubmit() {
    const { logInUser } = this.props;
    let { currentPassword, newPassword, reapetNewPassword } = this.state.form;

    // Check password validity
    const hasLengthValid = newPassword.length >= 8;
    const hasNumberValid = /[0-9]/.test(newPassword);
    const hasUpperCaseValid = /[A-Z]/.test(newPassword);
    const hasLowerCaseValid = /[a-z]/.test(newPassword);
    const hasSpecialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

    if (!hasLengthValid || !hasNumberValid || !hasUpperCaseValid || !hasLowerCaseValid || !hasSpecialCharValid) {
        toast.error(this.props.translate('Password does not meet requirements'));
        return;
    }

    if (newPassword !== reapetNewPassword) {
        toast.error(this.props.translate('Passwords do not match'));
        this.setState({ nPawsErrorMgs: true });
        return;
    }

    if (currentPassword.trim().length === 0) {
        toast.error(this.props.translate('Current password cannot be empty'));
        return;
    }

    // If all conditions pass, proceed with API call
    fetch(`api/users/${logInUser.id}/password`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(`cpass=${currentPassword}&npass=${newPassword}`)
    })
    .then(response => response.json())
    .then(res => {
        if (res.status === 'success') {
            toast.success(this.props.translate('passwordIsChanged'));
            this.formReset();
        } else if (res?.statusCode === '440') {
            window.location.replace('/login');
        } else if (res.statusCode) {
            toast.error(this.props.translate(res.message.split(':')[1].replace(')', '')));
        }
    })
    .catch(() => {
        toast.error(this.props.translate('somethingWentWrong'));
    });
}


  handleChange = name => event => {
    const { value } = event.target;
    this.setState(prevState => {
        const updatedForm = {
            ...prevState.form,
            [name]: value
        };

        const hasLengthValid = updatedForm.newPassword.length >= 8;
        const hasNumberValid = /[0-9]/.test(updatedForm.newPassword);
        const hasUpperCaseValid = /[A-Z]/.test(updatedForm.newPassword);
        const hasLowerCaseValid = /[a-z]/.test(updatedForm.newPassword);
        const hasSpecialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(updatedForm.newPassword);
        const isValidPassword =
        hasLengthValid &&
        hasLowerCaseValid &&
        hasUpperCaseValid &&
        hasNumberValid &&
        hasSpecialCharValid;
        return {
            form: updatedForm,
            hasLengthValid,
            hasNumberValid,
            hasUpperCaseValid,
            hasLowerCaseValid,
            hasSpecialCharValid,
            isValidPassword,
            isConfirmPassChk:updatedForm.newPassword===updatedForm.reapetNewPassword,
            nPawsErrorMgs: updatedForm.newPassword !== updatedForm.reapetNewPassword
        };
    },()=>this.checkRequiredFields());
};


  checkRequiredFields() {
    let { newPassword, reapetNewPassword, currentPassword } = this.state.form
    if (newPassword.trim().length > 0 && reapetNewPassword.trim().length > 0) {
      if (newPassword === reapetNewPassword) {
        this.setState({
          nPawsErrorMgs: false
        })
      } else {
        if (reapetNewPassword.trim().length > 0) {
          this.setState({
            nPawsErrorMgs: true
          })
        } else {
          this.setState({
            nPawsErrorMgs: false
          })
        }
      }

    }
    if (currentPassword.trim().length > 0) {
      this.setState({
        currentPawsErrorMgs: false
      })
    }
  }
  handleTogglePassword = () => {
    this.setState({
      showPassword:!this.state.showPassword
    })
};
handleToggleConfirmPassword = () => {
  this.setState({
    showRepeatNewPassword:!this.state.showRepeatNewPassword
  })
};
handleToggleCurrentPassword= () => {
  this.setState({
    showCurrentPassword:!this.state.showCurrentPassword
  })
};

  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id="newPassword"
              label={this.props.translate('newPassword')}
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder=""
              error={this.state.nPawsErrorMgs}
              value={this.state.form.newPassword || ''}
              onChange={this.handleChange('newPassword')}
              variant="outlined"
              margin='dense'
              fullWidth
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <div
                            style={{ cursor: 'pointer',marginTop:"3px" }}
                            onClick={this.handleTogglePassword}
                        >
                            {this.state.showPassword ? (
                                <VisibilityOffOutlinedIcon style={{ color:this.props.themecolors?.menuActiveBackground }} />
                            ) : (
                                <VisibilityOutlinedIcon style={{ color:this.props.themecolors?.menuActiveBackground}} />
                            )}
                        </div>
                    </InputAdornment>
                ),
            }}
            />
                                                          <PasswordRequirements
          hasLengthValid={this.state.hasLengthValid}
          hasNumberValid={this.state.hasNumberValid}
          hasUpperCaseValid={this.state.hasUpperCaseValid}
          hasLowerCaseValid={this.state.hasLowerCaseValid}
          hasSpecialCharValid={this.state.hasSpecialCharValid}
          translate={this.props.translate}
          />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id="reapetNewPassword"
              error={this.state.nPawsErrorMgs}
              label={this.props.translate('reNewPassword')}
              type={this.state.showRepeatNewPassword ? 'text' : 'password'}
              placeholder=""
              value={this.state.form.reapetNewPassword || ''}
              onChange={this.handleChange('reapetNewPassword')}
              variant="outlined"
              margin='dense'
              fullWidth
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <div
                            style={{ cursor: 'pointer',marginTop:"3px" }}
                            onClick={this.handleToggleConfirmPassword}
                        >
                            {this.state.showRepeatNewPassword ? (
                                <VisibilityOffOutlinedIcon style={{ color:this.props.themecolors?.menuActiveBackground }} />
                            ) : (
                                <VisibilityOutlinedIcon style={{ color:this.props.themecolors?.menuActiveBackground}} />
                            )}
                        </div>
                    </InputAdornment>
                ),
            }}
            />
                                               <FormHelperText style={{ color: this.props.themecolors.textColor }}>
                       <ValidationIcon
                         isValid={this.state.isConfirmPassChk}
                         message={this.props.translate('Both Passwords must be Equal')}
                       />
                     </FormHelperText>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              error={this.state.currentPawsErrorMgs}
              id="currentPassword"
              label={this.props.translate('userCurrentPassword')}
       type={this.state.showCurrentPassword?"text":"password"}
              placeholder=""
              value={this.state.form.currentPassword || ''}
              onChange={this.handleChange('currentPassword')}
              variant="outlined"
              margin='dense'
              fullWidth
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <div
                            style={{ cursor: 'pointer',marginTop:"3px" }}
                            onClick={this.handleToggleCurrentPassword}
                        >
                            {this.state.showCurrentPassword ? (
                                <VisibilityOffOutlinedIcon style={{ color:this.props.themecolors?.menuActiveBackground }} />
                            ) : (
                                <VisibilityOutlinedIcon style={{ color:this.props.themecolors?.menuActiveBackground}} />
                            )}
                        </div>
                    </InputAdornment>
                ),
            }}
            />

          </Grid>
        </Grid>

        <br />
        <Button variant="contained"
          style={{ marginLeft: 8, marginRight: 8 }}
          onClick={() => this.formReset()}
        > {this.props.translate('resetButton')}</Button>
        <Button variant="contained"
               disabled={
                !(this.state.form.newPassword && this.state.form.reapetNewPassword) || 
                !this.state.isValidPassword || 
                !this.state.isConfirmPassChk
              }
          onClick={() => this.formSubmit()}
        >{this.props.translate('changePassword')}</Button>
      </div>
    );
  }
};
const ChangedPasword = withStyles(styles)
export default ChangedPasword(changedPasword)






import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Layout from "../../wrapper";
import ContractModal from "../../Components/ContractManagment";
import { ContractSidebar } from "../../Components/ContractManagment/ContractSidebar";
import { logInUserInfo } from "../../Actions/Users";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkPrivileges } from "../../Helpers";
import Loader from "../../Layout/Loader";
import axios from "axios";
import TextField from "../../Components/common/TextField";
import { Grid } from "@mui/material";
import Button from "../../Components/common/Button";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import ExportImportMenu from "../../Components/common/ExportImportMenu";
import Table from "../../Components/common/tableWithBackEndPagination";
import instance from "../../axios";
import withRoutes from "../../HOC/HocRoutes";

let source;
class ContractManagment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisable: false,
      loader: false,
      activeOperation: "",
      selectedItem: "",
      itemPrivileges: [],
      selectedAccount: {},
      selectedServices: {},
      selectedSubTypesServices: [],
      selectedSubTypesAllServices: {},
      tableData: [],
      openTable: false,
      rowsPerPage: 10,
      openExpenseForm: false,
      expenseForm: {},
      isVisableSubmitBtn: true,
      mode: "",
      onDeleteConfirmation: false,
      selectedServiceTypeId: "",
      search:"",
      // new changes
      value: "maintenance",
      page: 1,
      pageSize: 20,
      itemSearch: "",
      contractData: {},
      showServices: false,
      searchContractText: "",
      selectedContract: {},
      loader1:false,
      searchSubType:"",
      mainPage: true,
      openCreateExpense: false,
      selectedExpenseType:{},

      serviceName:"",
      searchName:""
    };
  }
  componentWillMount() {
        // this.props.dispatch(toast.removeAll());

    this.getContractsData();
  }

  getContractsData = () => {
   
    let cc =
      this.state.value === "maintenance"
        ? 1
        : this.state.value === "leasing"
        ? 2
        : 0;
    let apiUrl = `/api/accounts/contracts?page=${this.state.page}&limit=${this.state.pageSize}&contractType=${cc}&search=${this.state.searchContractText}`;
    source = axios.CancelToken.source();
    this.setState({
      loader1:true,
       contractType:cc
    }, () => {
    axios
      .get(apiUrl, {
        cancelToken: source.token,
      })
      .then((response) => {
        this.setState({
      loader1:false,

          contractData: response?.data?.data || {},
        });
      })
      .catch((e) => {
        console.log("e ===", e);
      });
    })
  };

  addItem = () => {
    this.setState({
      isVisable: true,
      activeOperation: "add",
      selectedItem: "",
    });
  };
  removedItem = (item) => {
    this.setState({
      isVisable: true,
      activeOperation: "remove",
      selectedItem: item,
    });
  };
  editItem = (item) => {
    this.setState({
      isVisable: true,
      activeOperation: "edit",
      selectedItem: item,
    });
  };

  selecteItem = (item) => {
    this.setState(
      {
        selectedAccount: item,
        page: 1,
        pageSize: 50,
        itemSearch: "",
      },
      () => {
        this.fetchAccountServices(item.contractNumber);
      }
    );
  };

  fetchAccountServices = (id) => {
    let { page, pageSize, itemSearch } = this.state;
    let apiUrl = `/api/expensetypes/get?contractNumber=${id}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`;

    source = axios.CancelToken.source();
    axios
      .get(apiUrl, {
        cancelToken: source.token,
      })
      .then((response) => {
        console.log("RE=====", response);
        this.setState(
          {
            selectedServices:
              response.data && response.data.data && response.data.data.data,
            allServicesList: response.data.data,
            showExpense: true,
            mainPage: false,
            openExpenseForm: false,
            openTable: true,
            showServices: true,
            mode: "",
          },
          () => {
            if (this.state.selectedServiceTypeId)
              this.selectService(this.state.selectedServiceTypeId);
          }
        );
      })
      .catch((e) => {
        console.log("e ===", e);
      });
  };

  
  // searchSubTypeItem = (e) => {
  //   let text = e.target.value;
  //   this.setState({  
  //     searchSubType:text
  //     });
  // };

  searchSubTypeItem = (e, name) => {
    let value = e.target.value;
    this.setState(
      { 
        page:1,
        searchSubType:name === "itemSubType" ? value : '',
        searchName:name === "itemSubType" ? '' : value
      }
      );
  };
  handleChangeRowsPerPage1 = (rowsPerPage) => {
    this.setState({page:1, pageSize: rowsPerPage }, () => {
      this.getServices();
    });
  };

  handleChangePage1 = (value) => {
    this.setState({ page: value }, () => {
      this.getServices(); 
    });
  };

  selectService = (item, check) => {
    // console.log("item======", item);
    this.setState({ openExpenseForm: false , 
                    serviceName:item.name,
                    searchSubType : '',
                    searchName : '',
                    selectedServiceTypeId: item.id,
                    selectedExpenseType:item,

                   },
                    ()=> this.getServices()
                  )
  };
  getServices = () =>{
    // api/expensetypes/subtype/get?expenseTypeId=807&vehicleId=715273&subTypeNum=&page=1&limit=10&search=char
    // let item = this.state.selectedServiceTypeId
    const {selectedServiceTypeId,page, pageSize} = this.state
    let apiUrl = `/api/expensetypes/subtype/get?expenseTypeId=${selectedServiceTypeId}&page=${page}&limit=${pageSize}&subTypeNum=${this.state.searchSubType}&search=${this.state.searchName}`;
    source = axios.CancelToken.source();
    
      instance(apiUrl, {
        cancelToken: source.token,
      })
      .then((response) => {
    // console.log("response=====", response);

        this.setState({
          selectedSubTypesServices: response || {},
          selectedSubTypesAllServices: response || {},
          // tableData: response?.data || [],
          // openExpenseForm:false,
          // openTable:true,
          // showServices:true,
          // selectedServiceTypeId: item,
        });
      })
      .catch((e) => {
        console.log("e ===", e);
      });
  }

  setPagination1 = (items) => {
    let nItem = { ...items };
    let lastPage = nItem.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nItem.data;
    nItem.pageSize = this.state.pageSize;
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nItem;
  };

 
  goBack = () => {
    this.setState({
      openTable: false,
      showServices: false,
      mainPage: true,
      selectedSubTypesAllServices:{},
      selectedSubTypesServices:{},
      page:1, 
      pageSize: 10,
      searchSubType:"",
      search:''
    });
  };

  onCloseModal = () => {
    this.setState({
      isVisable: false,
      activeOperation: "",
      selectedItem: "",
    });
    this.props.navigate("/contractManagement");
  };

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };

  handleChangePage = (value) => {
    this.setState({
      //tableData: [],
      page: value,
      //defaultUserList: false
    });
  };
  SearchItem = (searchValue) => {
    source.cancel();
    this.setState({
      page: 1,
      itemSearch: searchValue,
      loader: true,
      defaultUserList: false,
    });
  };

  handleChange = (name, value) => {
    this.setState(
      {
        expenseForm: {
          ...this.state.expenseForm,
          [name]: value,
        },
      },
      () => {
        this.checkRequiredExpenses();
      }
    );
  };

  checkRequiredExpenses = () => {
    const { name, contractNumber } = this.state.expenseForm;
    if (name && contractNumber) {
      this.setState({ isVisableSubmitBtn: false });
    } else this.setState({ isVisableSubmitBtn: true });
  };

  closeExpenseForm = () => {
    this.setState({
      openExpenseForm: false,
      mode: "create",
      openTable: true,
      showServices: true,
    });
  };

  submitExpenseForm = () => {
    let url =
      this.state.mode === "create"
        ? `/api/expensetypes/`
        : `/api/expensetypes/${this.state.expenseForm.id}`;
    fetch(url, {
      method: this.state.mode === "create" ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...this.state.expenseForm,
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.setState(
            { createSubType: false, openExpenseForm: false },
            () => {
              this.fetchAccountServices(
                this.state.selectedAccount.contractNumber
              );
            }
          );
        
            toast.success(
              
                this.state.mode === "create"
                  ? this.props.translate("serviceCreated")
                  : this.props.translate("serviceUpdated"),     
          );
        }
      })
      .catch((error) => {
        console.log("error ==", error);
      });
  };

  editExpenseForm = (item) => {
    this.setState({
      expenseForm: item,
      openExpenseForm: true,
      mode: "update",
      openTable: true,
      showServices: true,
    });
  };

  deleteExpense = (item) => {
    this.setState({
      onDeleteConfirmation: true,
      expenseForm: item,
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  removeExpenseType = () => {
    if (this.state.expenseForm && this.state.expenseForm.id) {
      fetch(`/api/expensetypes/${this.state.expenseForm.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            this.setState({ onDeleteConfirmation: false }, () => {
              this.fetchAccountServices(
                this.state.selectedAccount.contractNumber
              );
            });
           
              toast.success(
                 this.props.translate("expenseDeleted")
            );
          }
        })
        .catch((er) => {
          console.log("err ===", er);
        });
    }
  };

  openCreateExpense = () => {
    this.setState({
      openExpenseForm: true,
      expenseForm: {
        contractNumber: this.state.selectedAccount?.contractNumber || "",
      },
      openTable: true,
      showServices: true,
      mode: "create",
    });
  };

  searchItem = (e) => {
    let text = e.target.value;
    const u =
      this.state.allServicesList &&
      this.state.allServicesList.data &&
      this.state.allServicesList.data.filter((u) =>
        (u.id + u.name).toLowerCase().includes(text.toLowerCase())
      );
    this.setState({ selectedServices: u });
  };


  setPagination = () => {
    if (
      this.state.contractData &&
      this.state.contractData.data &&
      this.state.contractData.data.length
    ) {
      let nAcc = { ...this.state.contractData };
      let lastPage = nAcc.total / this.state.pageSize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nAcc.data;
      nAcc.pageSize = this.state.pageSize;
      nAcc.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nAcc;
    }
    return {};
  };

  handleChangeRowsPerPage = (value) => {
    this.setState(
      {
        //tableData: [],
        page:1,
        pageSize: value,
        //defaultUserList: false
      },
      () => this.getContractsData()
    );
  };

  handleChangePage = (value) => {
    this.setState(
      {
        //tableData: [],
        page: value,
        //defaultUserList: false
      },
      () => this.getContractsData()
    );
  };

  tableSelection = (item, type) => {
    this.fetchAccountServices(item[0].contractNumber);
    this.setState({
      selectedServiceTypeId:
        type === "packages"
          ? item[0].packagesId
          : type === "services"
          ? item[0].servicesId
          : item[0].itemsId,
    });
    this.setState({
      selectedAccount: item[0],
      selectedContract: item[0],
      // mainPage:false
    });
    // this.selectService(item[0])
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

 
  handleChangeTabs = (e) => {
    this.setState({ value: e }, () => {
      this.getContractsData();
    });
  };

  searchContractText = (e) => {
    source.cancel();
    this.setState(
      {
        searchContractText: e.target.value,
      },
      () => {
        this.getContractsData();
      }
    );
  };

  onEdit = (item) => {
    // console.log("item=====", item);
    this.setState(
      {
        page: 1,
        selectedAccount: item,
        selectedContract: item,
      },
      () => {
        this.fetchAccountServices(item.contractNumber);
      }
    );
  };

  render() {
    const { value } = this.state;
    if (this.props.logInUser) {
      return (
        <Layout
          {...this.props}
          addItem={this.addItem}
          selecteItem={this.selecteItem}
          removedItem={this.removedItem}
          translate={this.props.translate}
          editItem={this.editItem}
          noSidebar
        >
          {this.state.mainPage ? (
            <>
              <Grid container columns={{ xs: 12, sm: 12 }} style={{ display: "flex" }}>
                <Grid item xs={4} sm={4} style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleChangeTabs("maintenance")}
                    style={{
                      width: "50%",
                      borderTopRightRadius: " 15px",
                      borderTopLeftRadius: "15px",
                      marginLeft: "5px",
                      backgroundColor:
                        value === "maintenance" ? "#00C179" : "#ffff",
                      color: value === "maintenance" ? "#ffff" : "#00C179",
                    }}
                  >
                    {this.props.translate("maintenance")}
                  </Button>

                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={() => this.handleChangeTabs("leasing")}
                    style={{
                      width: "50%",
                      borderTopRightRadius: " 15px",
                      borderTopLeftRadius: "15px",
                      marginLeft: "5px",
                      backgroundColor:
                        value === "leasing" ? "#00C179" : "#ffff",
                      color: value === "leasing" ? "#ffff" : "#00C179",
                    }}
                  >
                    {" "}
                    {this.props.translate("leasing")}
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div style={{ width: "25%", marginRight: 20 }}>
                    <TextField
                      fullWidth
                      label={this.props.translate("search")}
                      value={this.state.searchContractText}
                      onChange={(e) => this.searchContractText(e)}
                    />
                  </div>
                </Grid>
              </Grid>
        {this.state.loader1 ? <Loader/> :
              <Table
                rows={
                  (this.state.contractData && this.state.contractData.data) ||
                  []
                }
                rowsPerPage={this.state?.rowsPerPage}
                pagination={this.setPagination()}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleChangePage={this.handleChangePage}
                SearchItem={this.SearchItem}
                tableSelection={this.tableSelection}
                translate={this.props.translate}
                btnName1={"accounts"}
                isEditable
                onClick={this.onEdit}
                themecolors={this.props.themecolors}
                filterDropdown
                // setSerialNo
                isCursoPointer
                rowDefinition={[
                  {
                    id: "accountName",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("accountName"),
                  },
                  {
                    id: "contractName",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("contractName"),
                  },
                  {
                    id: "contractNumber",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("contractNumber"),
                  },
                  {
                    id: "creditLimit",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("creditLimit"),
                  },
                  {
                    id: "contractStartDate",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("contractStartDate"),
                  },
                  {
                    id: "contractEndDate",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("contractEndDate"),
                  },
                  {
                    id: "packagesId",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("products"),
                  },
                ]}
              />
              }
            </>
          ) : null}

          {this.state.openTable && this.state.showServices ? (
            <>
              <aside
                className="fms-sidebar"
                style={{ marginLeft: 20, marginTop: 20 }}
              >
                <div>
                  {
                    <Fragment>
                      <div
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          paddingLeft: 10,
                        }}
                      >
                        <h4>{this.state?.selectedAccount?.name}</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                        }}
                        className="headerDiv"
                      >
                        <div className="searchBar">
                          <TextField
                            label={this.props.translate("searchExpense")}
                            type="search"
                            placeholder="Search"
                            value={this.state.search}
                            onChange={this.searchItem}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                          />
                        </div>
                        <Button
                         size="small" 
                         onClick={this.openCreateExpense}
                         disabled={!checkPrivileges("expensetypeCreate")}
                         >
                          {" "}
                          {this.props.translate("create")}
                        </Button>
                        <span style={{ paddingRight: 10 }}>
                          <ExportImportMenu
                            fileName="category"
                            category
                            translate={this.props.translate}
                            checkPrivilege="serviceCreate"
                            data={this.state?.expense || []}
                            themecolors={this.props.themecolors}
                            expensetypeId={
                              this.state?.selectedAccount?.contractNumber || ""
                            }
                            selectedAccount={
                              this.state?.selectedAccount?.contractNumber || ""
                            }
                            fetchAccountServices={this.fetchAccountServices}
                          />
                        </span>
                      </div>
                    </Fragment>
                  }
                  <div className="sidebar-devices-list">
                    <ContractSidebar
                      selectedAccount={this.state.selectedAccount}
                      selectedAccServices={this.state.selectedServices}
                      selectedServiceTypeId={this.state.selectedServiceTypeId}
                      selectService={this.selectService}
                      translate={this.props.translate}
                      pathName={"contractManagement"}
                      editExpenseForm={this.editExpenseForm}
                      deleteExpense={this.deleteExpense}
                    />
                  </div>
                </div>
              </aside>
            </>
          ) : null}

          {this.state.openExpenseForm && (
            <>
              <Grid
                style={{
                  flex: 1,
                  flexDirection: "row",
                  background: "#fff",
                  marginLeft: 300,
                }}
              >
                <ExpenseForm
                  form={this.state.expenseForm}
                  handleChange={this.handleChange}
                  translate={this.props.translate}
                  submitForm={this.submitExpenseForm}
                  closeEditForm={this.closeExpenseForm}
                  mode={this.state.mode}
                  isVisableSubmitBtn={this.state.isVisableSubmitBtn}
                  checkPrivileges={checkPrivileges}
                />
              </Grid>
            </>
          )}
          {this.state.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeExpenseType}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.expenseForm?.name || ""}
            />
          )}
          {this.state.openTable &&
          this.state.showServices &&
          !this.state.openExpenseForm ? (
            <>
              <Grid
                style={{
                  flex: 1,
                  flexDirection: "row",
                  background: "#fff",
                  marginLeft: 300,
                }}
              >
                <ContractModal
                selectedExpenseType={this.state.selectedExpenseType}
                serviceName={this.state.serviceName}
                getServices={this.getServices}
                translate={this.props.translate}
                  selectedSubTypesServices={this.state.selectedSubTypesServices}
                  selectedSubTypesAllServices={
                    this.state.selectedSubTypesAllServices
                  }
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage1}
                  handleChangePage={this.handleChangePage1}
                  setPagination={this.setPagination1}
                  // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  // handleChangePage={this.handleChangePage}
                  rowsPerPage={this.state?.rowsPerPage}
                  searchSubTypeItem={this.searchSubTypeItem}
                  themecolors={this.props.themecolors}
                  selectedServiceTypeId={
                    this.state?.selectedServiceTypeId || ""
                  }
                  selectService={this.selectService}
                  goBack={this.goBack}
                  selectedContract={this.state?.selectedContract || {}}
                />
              </Grid>
            </>
          ) : null}
        </Layout>
      );
    } else {
      fetch("/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05", {
        headers: { Accept: "application/json; odata=verbose" },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){  
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
               
                  toast.error(
                    this.props.translate(err[1])                   
                )
                this.props.navigate('/login')
              }
            })
          } else {
            this.props.navigate('/login')
            throw response
          }
        })
        .catch((e) => {

            toast.error(
              "somethingWentWrong"
          );
        });
      return null;
    }
  }
}

const ExpenseForm = ({
  form,
  handleChange,
  translate,
  submitForm,
  closeEditForm,
  mode,
  isVisableSubmitBtn,
  checkPrivileges,
}) => {
  return (
    <div style={{ padding: 10 }}>
      <h4 className="page-title">{translate("services")}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="name"
            required
            margin="dense"
            label={translate("sharedName")}
            variant="outlined"
            fullWidth
            value={form?.name || ""}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="description"
            margin="dense"
            label={translate("description")}
            variant="outlined"
            fullWidth
            value={form?.description || ""}
            onChange={(e) => handleChange("description", e.target.value)}
          />
        </Grid>
      </Grid>
      <div style={{ display: "flex", marginTop: 10 }}>
        <div style={{ marginLeft: "auto" }} />
        <Button
          size="small"
          style={{ marginRight: 15 }}
          className="button-white"
          onClick={closeEditForm}
        >
          {translate("sharedCancel")}{" "}
        </Button>
        {/* {checkPrivileges("expensetypeCreate") && ( */}
          <Button
            size="small"
            onClick={submitForm}
            // disabled={isVisableSubmitBtn}
            disabled={(!checkPrivileges(mode === "update" ? "expensetypeUpdate" : "expensetypeCreate")) || isVisableSubmitBtn}
          >
            {translate(mode === "update" ? "update" : "sharedCreate")}
          </Button>
        {/* )} */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accounts: state.accounts,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themecolors,
});

export default connect(mapStateToProps)(withRoutes(ContractManagment));

import React, { Component } from "react";
import { Grid } from "@mui/material";
import instance from "../../axios";
import '@emran-alhaddad/saudi-riyal-font/index.css';

let selectedInvoice = false;
let serverTimeZoneName = "";
 
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      data1: {},
      name: "",
      laoder: true,
    };
  }
  componentWillMount() {
    var baseUrl = window.location.origin;
    const queryString = window.location.search;
    var res = queryString.split("?&token=");

    let invoiceId = res[1].split("&leasing=");
    let sz = invoiceId[0]?.split("&timezone=");

    // selectedInvoice = sz[0] === "true" ? "leaseinvoices" : "invoices";
    serverTimeZoneName = sz[1]

    // api/invoices/4121?contractNumber=PE150
    instance({
      method: "GET",
      url: `/api/payments/${sz[0]}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => { 

        this.setState({ data1: response }, () => {
             
          if (selectedInvoice == "leaseinvoices" && this.state.data1?.invoiceType === 2) {
            let Taxable = 0;
            let VAT = 0;
            let Amount = 0;

            // saleItemPrice , 15, saleVATAmount , saleTotalAmount
            response.items &&
              response.items.map((item, index) => {
                Taxable = Taxable + item.saleItemPrice;
                VAT = VAT + item.saleVATAmount;
              });

            this.setState({
              leasingInvoiceSummary: {
                noOfInvoices:
                  response.items && response.items.length
                    ? response.items.length
                    : 0,
                TaxableAMT: response?.saleInvoicePrice || 0,
                VAT: response?.saleVATAmount || 0,
                totalAmount: response?.saleNetAmount || 0,
              },
            });
          }
        });
      })
      .catch((error) => {
        console.log("error ====", error);
      });
  }

   replaceAmpersands(obj) {
    for (const prop in obj) {
      if (typeof obj[prop] === 'string') {
        obj[prop] = obj[prop].replace(/amp;/g, '');
      } else if (typeof obj[prop] === 'object') {
        this.replaceAmpersands(obj[prop]);
      }
    }
  }

  render() {
    const { data1, leasingInvoiceSummary } = this.state;
    let data = data1
    this.replaceAmpersands(data);
    
    
   
    if (data) {
      const Pack = data && data.packages;
      const Service = data && data.services;
      const Item = data && data.items;
      let packagesLength = 0;
      let servicesLength = 0;
      let ItemsLength = 0;
      Item &&
        Item.map((item) => {
          if (item.categoryName === "packages")
            packagesLength = packagesLength + 1;
          else if (item.categoryName === "services")
            servicesLength = servicesLength + 1;
          else if (item.categoryName === "items") 
          ItemsLength = ItemsLength + 1;
          else ItemsLength = ItemsLength + 1;
        });
      let indexLen = 1;
      let indexPackage = 1;
      let totalVATPrice = 0;
      let totalPackagePrice = 0;
      let totalPackageVAT = 0;

      let totalServicePrice = 0;
      let packageTotalPrice = 0;
      let packageVATAmount = 0;
      let packageNetPrice = 0;

      let serviceTotalPrice = 0;
      let serviceVATAmount = 0;
      let serviceNetPrice = 0;

      let itemsTotalPrice = 0;
      let itemsVATAmount = 0;
      let itemsNetPrice = 0;

      const formattedCompanyName = data&&data.attributes&&data.attributes.c_accountName&&data.attributes.c_accountName.replace(/:amp;/g, '&');
      const modifiedData = JSON.parse(JSON.stringify(data).replaceAll('&amp;', '&'));
      
      const decodedHtml = data&&data.attributes&&data.attributes.c_accountName&&data.attributes.c_accountName
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'");

      return (
        <Grid id="invoiceholder">
          <Grid id="invoice" class="effect2">
            <Grid container spacing={1} style={{ alignItems: "center" }}>
              <Grid item md={5}>
                <img style={{ width: "200px" }} src="/logo.png" />
              </Grid>
              <Grid item md={7} style={{ paddingRight: 20 }}>
                {/* <h5 class="head3">فاتورة ضريبة القيمة المضافة</h5> */}
                <h5 class="head3" style={{ marginLeft: 40 }}>
                  Receipt
                </h5>
              </Grid>
            </Grid>
            <Grid class="invoice-mid " style={{ border: "1px solid black" }}>
              <Grid container>
                <Grid item md={6} style={{ padding: "12px 0 0 12px" }}>
                  <p class="ptag">
                    <strong>National Transportation Solutions Company </strong>
                  </p>
                  <p class="ptag">Jeddah, 21431, Kingdom of Saudi Arabia</p>
                  <p class="ptag">P.O. BOX 1432, Al Mohammadiyyah</p>
                  {/* <p class="ptag">VAT number of Supplier: 300000603210003</p> */}
                  <p class="ptag">Phone No: +966 92000 8206</p>
                  <p class="ptag">Email Address: info@ntsc.sa</p>
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{ textAlign: "right", padding: "12px 12px 0 0" }}
                >
                  <p class="ptag">
                    <strong>شركة الوطنية لحلول النقل المحدودة </strong>
                  </p>
                  <p class="ptag">ص.ب.1432 المحمدية</p>
                  <p class="ptag">جدة ,21431 ,المملكة العربية السعودية</p>
                  <p class="ptag">الرقم الضريبي للمنشأة: 300000603210003 </p>
                  <p class="ptag"> +966 92000 8206 : رقم الهاتف</p>
                  <p class="ptag">info@ntsc.sa : البريد الإلكتروني</p>
                </Grid>
              </Grid>
            
                  <Grid class="row m-0 p-1" style={{ border: "1px solid black",}}>
                    <table style={{ fontSize: 20 }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <table>
                            <tr>
                              <td >Name :</td>
                              <td class="text-center" 
                              // dangerouslySetInnerHTML={{ __html: decodedHtml }}
                              >
                                {decodedHtml || []}
                                {/* {data?.attributes?.c_accountName || ""} */}
                                {/* {"\n"} */}
                                {data?.attributes?.c_nameArabic || ""}
                              </td>
                              <td class="text-right">: الاسم / اسم الشركة</td>
                            </tr>
                            <tr> 
                              <td>Email Address :</td>
                              <td class="text-center">
                                {data?.attributes?.c_email || ""}
                              </td>
                              <td class="text-right"> البريد الإلكتروني </td>
                            </tr>
                          </table>
                        </td>
                        <td style={{ width: "50%" }}>
                          <table>
                            <tr>
                              <td>Contact No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_city || ""}
                              </td>
                              <td class="text-right">: المدينة</td>
                            </tr>
                           
                          </table>
                        </td>
                      </tr>
                    </table>
                  </Grid>
            
             
              {selectedInvoice === "leaseinvoices"  &&  data?.invoiceType === 2 ? (
                <>
                  <Grid container style={{ marginBottom: 5 }}>
                    <table>
                      <tr
                        style={{
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <td class="tableData">
                          <strong>رقم التسلسلي </strong>
                          <br />
                          <strong> SL NO</strong>
                        </td>
                        <td class="tableData">
                          <strong>عدد الفواتير </strong>
                          <br />
                          <strong> Number of vehicles</strong>
                        </td>
                        <td class="tableData">
                          <strong> المبلغ الخاضع للضريبة </strong>
                          <br />
                          <strong>Taxable AMT </strong>
                        </td>
                        <td class="tableData">
                          <strong>معدل الضريبة </strong>
                          <br />
                          <strong>Tax Rate</strong>
                        </td>
                        <td class="tableData">
                          <strong>نسبة القيمه المضافه </strong>
                          <br />
                          <strong>VAT% </strong>
                        </td>
                        <td class="tableData">
                          <strong> (ريال سعودي) المبلغ </strong>
                          <br />
                          <strong>Amount 
                            {/* (SAR) */}
                            <span class="icon-saudi_riyal"></span>
                            </strong>
                        </td>
                      </tr>

                      <tr>
                        <td class="tableData" style={{ padding: 2 }}>
                          {1}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary &&
                          leasingInvoiceSummary.noOfInvoices
                            ? leasingInvoiceSummary.noOfInvoices.toFixed(2)
                            : 0}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary &&
                          leasingInvoiceSummary.TaxableAMT
                            ? leasingInvoiceSummary.TaxableAMT.toFixed(2)
                            : ""}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {15}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary && leasingInvoiceSummary.VAT
                            ? leasingInvoiceSummary.VAT.toFixed(2)
                            : ""}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary &&
                          leasingInvoiceSummary.totalAmount
                            ? leasingInvoiceSummary.totalAmount.toFixed(2)
                            : ""}
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </>
              ) : null}
   
              {selectedInvoice === "leaseinvoices" &&    data?.invoiceType === 2 &&
              data.items &&
              data.items.length ? (
                <>
                  <Grid container>
                    <table>
                      <tr
                        style={{
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <td class="tableData">
                          <strong>رقم التسلسلي </strong>
                          <br />
                          <strong>SL NO</strong>
                        </td>
                        <td class="tableData">
                          <strong> نوع المركبة/رقم</strong>
                          <br />
                          <strong>VEHICLE NUMBER</strong>
                        </td>
                        <td class="tableData">
                          <strong>الخدمة المقدمة </strong>
                          <br />
                          <strong>DESCRIPTION</strong>
                        </td>
                        <td class="tableData">
                          <strong>موديل </strong>
                          <br />
                          <strong>MODEL</strong>
                        </td>
                        <td class="tableData">
                          <strong>عدد</strong>
                          <br />
                          <strong># OF DAYS</strong>
                        </td>
                        <td class="tableData">
                          <strong>الإيجار الشهري </strong>
                          <br />
                          <strong>MONTHLY RENTAL</strong>
                        </td>
                        <td class="tableData">
                          <strong>لمبلغ الخاضع للضريبة </strong>
                          <br />
                          <strong>TAXABLE AMOUNT</strong>
                        </td>
                        <td class="tableData">
                          <strong>معدل الضريبة</strong>
                          <br />
                          <strong>TAX RATE</strong>
                        </td>
                        <td class="tableData">
                          <strong>ضريبه القيمه المضافه </strong>
                          <br />
                          <strong> VAT%</strong>
                        </td>
                        <td class="tableData">
                          <strong>مجموع </strong>
                          <br />
                          <strong> TOTAL </strong>
                        </td>
                      </tr>
                      {data.items &&
                        data.items.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {index + 1}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.plateNumber || ""}
                                </td>
                                <td class="tableData" style={{ width: 160 }}>
                                  {item?.itemDescription ||
                                    "Monthly fleet lease  الإيجار الشهري"}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.model || ""}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.noOfDays || 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleItemPrice
                                    ? item.saleItemPrice.toFixed(2)
                                    : 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleTotalAmount
                                    ? item.saleTotalAmount.toFixed(2)
                                    : 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {"15%"}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleVATAmount
                                    ? item.saleVATAmount.toFixed(2)
                                    : 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleTotalAmount
                                    ? item.saleNetAmount.toFixed(2)
                                    : 0}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </table>
                  </Grid>
                </>
              ) : null}
         
              ta

              <Grid
                container
                style={{ padding: 1, marginTop: 3, marginBottom: 3 }}
              >
                <Grid item md={6}>
                  <p class="ptag">
                    Remarks : Payment
                  </p>
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{ textAlign: "right", padding: "12px 12px 0 0" }}
                >
                  <p class="ptag">
                  National Transportation Solutions Company
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <footer class="text-center">
              <Grid class="legalcopy clearfix">
                <p class="text-center">
                  شكرا لزيارتك <br /> THANK YOU FOR YOUR VISIT{" "}
                </p>
              </Grid>
            </footer>
          </Grid>
        </Grid>
      );
    }
  }
}

export default Payment;

import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import isEqual from "react-fast-compare";
import AddAccountModal from "./addAccount";
import { getAccounts } from "../../Actions/Accounts";
import { parentUsersLimit } from "../../Actions/Users";
import { parentUnitsLimit } from "../../Actions/Devices";
import { errorHandler, validEmailPattern } from "../../Helpers";
import { checkPrivileges, PaginationConfig } from "../../Helpers";
import Table from "../common/tableWithBackEndPagination";
import ConfirmDialoag from "../common/ConfirmDialoag";
import TextField from "../common/TextField";
import { ViewStatistics } from "./../Users/statistics";
import { ViewAccountDetail } from "./accountDetail/viewAccountDetail";
import parse from "html-react-parser";
import ExportFile from "./../common/ExportFile";
import Button from "./../common/Button";
import ImportFile from "./../common/ImportExcel";
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { Grid } from "@mui/material";
import Loader from "../../Layout/Loader";
import instance from "../../axios";
import ExportImportMenu from "../common/ExportImportMenu";
import withRoutes from "../../HOC/HocRoutes";
// import MarkerMap from "../common/Maps";
// import CustomDialog from "../common/Dialog";

// const userDataFormat = (data, extAttriubtes, itemId) => {
//   if (data.roleId === "defaultRole") {
//     data.roleId = "";
//   }
//   return {
//     customerType:data.customerType ? data.customerType : '',
//     administrator: data.userType === 1 ? false : true,
//     attributes: extAttriubtes ? extAttriubtes : {},
//     coordinateFormat: data.coordinateFormat ? data.coordinateFormat : "",
//     deviceLimit: data.deviceLimit ? data.deviceLimit : "",
//     deviceReadonly:
//       data.deviceReadonly === "on" || data.deviceReadonly === true
//         ? true
//         : false,
//     disabled: data.disabled === "on" || data.disabled === true ? true : false,
//     email: data.email.trim(),
//     expirationTime: data.expirationTime ? data.expirationTime : null,
//     id: itemId ? itemId : 0,
//     latitude: data.latitude ? data.latitude : 0,
//     // status: data.status ? data.status :'',
//     limitCommands:
//       data.limitCommands === "on" || data.limitCommands === true ? true : false,
//     login: data.login,
//     longitude: data.longitude ? data.longitude : 0,
//     map: data.map,
//     name: data.name.trim(),
//     password: data.password,
//     phone: data.phone,
//     poiLayer: data.poiLayer,
//     readonly: data.readonly === "on" || data.readonly === true ? true : false,
//     token: data.token,
//     twelveHourFormat:
//       data.twelveHourFormat === "on" || data.twelveHourFormat === true
//         ? true
//         : false,
//     userLimit: data.userLimit ? data.userLimit : "",
//     // city: data.city ? data.city : "",
//     vehicleLimit: data.vehicleLimit ? data.vehicleLimit : "",
//     zoom: data.zoom ? data.zoom : 0,
//     roleId: data.roleId ? data.roleId : data.userType === 1 ? 2 : 3,
//     // roleId: data.roleId ? data.roleId : 2,
//     userType: data.userType,
//     accountId: data.accountId || 0,
//     parentId: data.parentId || 0,
//   };
// };
const userDataFormat = (data, extAttriubtes, itemId) => {
  if (data.roleId === "defaultRole") {
    data.roleId = "";
  }

  let formattedData = {
    administrator: data.userType === 1 ? false : true,
    attributes: extAttriubtes ? extAttriubtes : {},
    coordinateFormat: data.coordinateFormat ? data.coordinateFormat : "",
    deviceLimit: data.deviceLimit ? data.deviceLimit : "",
    deviceReadonly:
      data.deviceReadonly === "on" || data.deviceReadonly === true
        ? true
        : false,
    disabled: data.disabled === "on" || data.disabled === true ? true : false,
    email: data.email.trim(),
    expirationTime: data.expirationTime ? data.expirationTime : null,
    id: itemId ? itemId : 0,
    latitude: data.latitude ? data.latitude : 0,
    // status: data.status ? data.status :'',
    limitCommands:
      data.limitCommands === "on" || data.limitCommands === true ? true : false,
    login: data.login,
    longitude: data.longitude ? data.longitude : 0,
    map: data.map,
    name: data.name.trim(),
    password: data.password,
    phone: data.phone,
    poiLayer: data.poiLayer,
    readonly: data.readonly === "on" || data.readonly === true ? true : false,
    token: data.token,
    twelveHourFormat:
      data.twelveHourFormat === "on" || data.twelveHourFormat === true
        ? true
        : false,
    userLimit: data.userLimit ? data.userLimit : "",
    // city: data.city ? data.city : "",
    vehicleLimit: data.vehicleLimit ? data.vehicleLimit : "",
    zoom: data.zoom ? data.zoom : 0,
    roleId: data.roleId ? data.roleId : data.userType === 1 ? 2 : 3,
    // roleId: data.roleId ? data.roleId : 2,
    userType: data.userType,
    accountId: data.accountId || 0,
    parentId: data.parentId || 0,
  };

  // Conditionally add customerType
  if (data.customerType) {
    formattedData.customerType = parseInt(data.customerType);
  }

  return formattedData;
};

const attributesDefaultValue = {
  distanceUnit: "km",
  speedUnit: "kmh",
  volumeUnit: "ltr",
  measurementStandard: "metric",
};
const formDefaultState = {
  name: "",
  accountName: "",
  nameArabic: "",
  monthlyRevenueTarget: "",
  yearlyRevenueTarget: "",
  email: "",
  accountEmail: "",
  password: "",
  confirmPassword: "",
  attributes: { ...attributesDefaultValue },
  phone: "",
  map: "",
  latitude: "",
  longitude: "",
  zoom: "",
  twelveHourFormat: "",
  coordinateFormat: "",
  poiLayer: "",
  disabled: "",
  admin: "",
  readonly: "",
  deviceReadonly: "",
  limitCommands: "",
  deviceLimit: 0,
  userLimit: 0,
  vehicleLimit: 0,
  token: "",
  expirationTime: null,
  roleId: "",
  userType: "",
  administrator: false,
  // attributes: {},
  accountId: 0,
  parentId: 0,
  status: "",
  vatPercentage: 15,
  unlimitedLimit: false,
  garage: "",
  // customerType:
};
let source;
class accountModal extends Component {
  constructor() {
    super();
    this.state = {
      ...PaginationConfig,
      // all fields of form
      isVisableBtn: false,
      isAttChanged: false,
      itemAttributes: {},
      accountDefaultList: true,
      addAccount: false,
      selectedAccount: "",
      onDeleteConfirmation: false,
      accountDetailOption: false,
      isVisableUserBtn: false,
      emailPattern: false,
      spEmailPattern: false,
      loader: false,
      topRow: true,
      duplicateEntry: false,
      accountSelectedData: "",
      btnVisible: false,
      hasLengthValid:false,
      hasLowerCaseValid:false,
      hasUpperCaseValid:false,
      hasNumberValid:false,
      hasSpecialCharValid:false,
      isConfirmPassChk:false,
      isValidPassword:false,
      value: "maintenance",
      form: {
        name: "",
        email: "",
        contact: "",
        description: "",
        attributes: { currencyLabel: "SAR" },
        nameArabic: "",
        monthlyRevenueTarget: "",
        yearlyRevenueTarget: "",
        contractName: "",
        vatPercentage: 15,
      },
      accountsform: {
        c_taxNumber: "",
        c_idIssuer: "MC",
        c_productType: "IMV",
        c_productStatus: "A",
        c_defaultStatus: "",
        c_goodsServices: "ALOTH",
        c_schemeId: "TIN",
        c_country: "Saudi Arabia",
        c_countryArabic: "المملكة العربية السعودية",
      },
      spForm: {
        ...formDefaultState,
      },
      accountType: [
        { id: "1", label: "Acc Type1" },
        { id: "2", label: "Acc Type2" },
        { id: "3", label: "Acc Type3" },
        { id: "4", label: "Acc Type4" },
        { id: "5", label: "Acc Type5" },
      ],
      accountStatus: [
        { id: "1", label: "Onlline" },
        { id: "2", label: "offline" },
        { id: "3", label: "blocked" },
        { id: "4", label: "dormant" },
      ],
      accountTime: [
        { id: "1", label: "Created time" },
        { id: "2", label: "Deleted time" },
        { id: "3", label: "Active time" },
      ],
      accountActivate: false,
      accountStatusLabel: "",
      loader1: false,
      unlimitedLimit: false,
      emailInvalidError: "",
      accountEmailInvalidError: "",
      newField: "",
      // garage:[],
      defaultGarageOptions: [],
    };

    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.changeAttribute = this.changeAttribute.bind(this);
    this.checkForAttrubutesChanged = this.checkForAttrubutesChanged.bind(this);
    this.generateToken = this.generateToken.bind(this);
    this.timeDateOnchange = this.timeDateOnchange.bind(this);
    this.submit = this.submit.bind(this);
    this.addNewAccount = this.addNewAccount.bind(this);
    this.openDashboard = this.openDashboard.bind(this);
  }
  componentWillMount() {
    this.getMoreAccounts();
  }
  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
    //     // this.props.dispatch(toast.removeAll());
  }

  handleChangeTabs = (e) => {
    this.setState({ value: e }, () => {
      this.getMoreAccounts();
    });
  };

  getMoreAccounts = () => {
    let cc =
      this.state.value === "maintenance"
        ? 1
        : this.state.value === "leasing"
        ? 2
        : this.state.value === "cashCustomer"
        ? 3
        : "";
    let {
      page,
      pageSize,
      itemSearch,
      accountTypes,
      accountStatuses,
      accountTimes,
    } = this.state;
    let apiUrl = `/api/accounts/get?userId=${this.props.logInUser.id}&contractType=${cc}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`;
    if (this.props.logInUser.userType === -1) {
      apiUrl = `/api/accounts/get?userId=${
        this.props.logInUser.id
      }&contractType=${cc}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}${
        accountTypes || ""
      }${accountStatuses || ""}${accountTimes || ""}`;
    }
    source = axios.CancelToken.source();

    this.setState({ loader1: true }, () => {
      axios
        .get(apiUrl, {
          cancelToken: source.token,
        })
        .then((response) => {
          if (response.status === 200) {
            this.props.dispatch(getAccounts(response.data.data));
            this.setState({ loader1: false });
          }
        })
        .catch((e) => {
          // if (axios.isCancel(e)) {
          //   console.log('Request canceled = ', e);
          // }
        });
    });
  };

  generateToken() {
    let symbols =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let i,
      newToken = "";
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          token: newToken,
        },
      },
      () => this.checkRequiredFields()
    );
  }
  timeDateOnchange(date) {
    this.setState((preState) => ({
      ...preState,
      spForm: {
        ...preState.spForm,
        timeDate: date,
      },
    }));
  }
  setNoToken = (e) => {
    e.stopPropagation();
    this.setState(
      {
        form: {
          ...this.state.form,
          token: null,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  setExpirationTime = (e) => {
    e.stopPropagation();
    if (this.state.spForm.expirationTime) {
      this.setState(
        {
          spForm: {
            ...this.state.spForm,
            expirationTime: null,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  changeAttribute(name, value, selectedOption, updateAtt) {
    if (selectedOption === "updateAtt" || selectedOption === "deleteAtt") {
      this.setState(
        {
          g_attriubtes: { ...updateAtt },
        },
        () => this.checkForAttrubutesChanged(selectedOption)
      );
    } else {
      this.setState(
        {
          g_attriubtes: {
            ...this.state.selectedAccount.attributes,
            [name]: value,
          },
        },
        () => this.checkForAttrubutesChanged()
      );
    }
  }
  checkForAttrubutesChanged(selectedOption) {
    let changed = false;
    if (this.state.selectedAccount) {
      changed = !isEqual(
        this.state.g_attriubtes,
        this.state.selectedAccount.attributes
      );
    } else {
      changed = !this.isEmpty(this.state.g_attriubtes);
    }
    let { name, email, contact } = this.state.form;
    let selectedAccount = {};
    if ((name && email && contact) || (name && email && contact && changed)) {
      selectedAccount = this.state.g_attriubtes;
      if (selectedOption === "deleteAtt" || selectedOption === "updateAtt") {
        this.setState({
          isVisableBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedAccount },
        });
      } else {
        this.setState({
          isVisableBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...this.state.itemAttributes, ...selectedAccount },
        });
      }
    } else {
      selectedAccount = this.state.g_attriubtes;
      if (selectedOption === "deleteAtt" || selectedOption === "updateAtt") {
        this.setState(
          {
            isVisableBtn: false,
            isAttChanged: false,
            itemAttributes: { ...selectedAccount },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            isVisableBtn: false,
            isAttChanged: false,
            itemAttributes: {
              ...this.state.itemAttributes,
              ...selectedAccount,
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
    if (changed) {
      this.setState({
        attributeChangesMessage: this.props.translate(
          "changeInAttributesIsPending"
        ),
      });
    } else {
      this.setState(
        {
          attributeChangesMessage: "",
        },
        () => this.checkRequiredFields()
      );
    }
  }
  isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (!value) {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name];
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: event.target.type === "number" ? parseInt(value) : value,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  changeLimit = (name, event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    // this.setState({
    //   [name]: value,
    // });
    this.setState(
      {
        [name]: value,
        form: {
          ...this.state.form,
          deviceLimit: value ? -1 : 0,
          vehicleLimit: value ? -1 : 0,
          userLimit: value ? -1 : 0,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  changeAttribute2ForSP = (name, event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (!value) {
      if (this.state.spForm.attributes[name]) {
        delete this.state.spForm.attributes[name];
      }
      this.setState(
        {
          spForm: {
            ...this.state.spForm,
            attributes: {
              ...this.state.spForm.attributes,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          spForm: {
            ...this.state.spForm,
            attributes: {
              ...this.state.spForm.attributes,
              [name]: event.target.type === "number" ? parseInt(value) : value,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  verifyPassword = () => {
    const { password, confirmPassword } = this.state.form;
    const minLength = 8;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasLengthValid = password?.length >= minLength;
    const hasLowerCaseValid = hasLowerCase.test(password);
    const hasUpperCaseValid = hasUpperCase.test(password);
    const hasNumberValid = hasNumber.test(password);
    const hasSpecialCharValid = hasSpecialChar.test(password);
    const isPasswordMatch = password === confirmPassword;
 
    const isValidPassword =
    hasLengthValid &&
    hasLowerCaseValid &&
    hasUpperCaseValid &&
    hasNumberValid &&
    hasSpecialCharValid;

  this.setState(
    {
      hasLengthValid,
      hasLowerCaseValid,
      hasUpperCaseValid,
      hasNumberValid,
      hasSpecialCharValid,
      isConfirmPassChk: isPasswordMatch,
      isValidPassword,
    },
    () => {
      this.checkRequiredFields();
    }
  );
  };

  checkRequiredFields() {
    let valid = true;
    let {
      name,
      email,
      password,
      confirmPassword,
      userType,
      contractNumber,
      vatPercentage,
      nameArabic,
      contractName,
      creditPeriod,
      accountEmail,
      contractStartDate,
      contractEndDate,
      contractType,
    } = this.state.form;

    let {
      c_taxNumber,
      c_streetName,
      c_schemeId,
      c_buildingNo,
      c_city,
      c_postalCode,
      c_country,
      c_district,
    } = this.state.accountsform;

    // if(email || accountEmail ){
    //   if(email){
    if (email && !validEmailPattern.test(email)) {
      this.setState({
        emailInvalidError: "Please Enter a Valid Email Address.",
      });
    } else {
      this.setState({
        emailInvalidError: "",
      });
    }

    // if ((name && !validNamePattern.test(name) ) || (contractName && !validNamePattern.test(contractName) )  ) {
    //   this.setState({
    //     nameInvalidError: "Please Enter a Valid Data.",
    //   });
    // } else {
    //   this.setState({
    //     nameInvalidError: ""
    //   });
    // }

    // }else{
    if (accountEmail && !validEmailPattern.test(accountEmail)) {
      this.setState({
        accountEmailInvalidError: "Please Enter a Valid Email Address.",
      });
    } else {
      this.setState({
        accountEmailInvalidError: "",
      });
    }

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // // Check if email is not valid
    // if (email && !emailRegex.test(email)) {
    //   this.setState({
    //     emailInvalidError: "Please Enter a Valid Email Address."
    //   });
    // } else {
    //   this.setState({
    //     emailInvalidError: ""
    //   });
    // }

    // }
    //  }
    if (userType > 2) {
      if (userType === 4) {
        if (
          name &&
          validEmailPattern.test(email) &&
          validEmailPattern.test(accountEmail) &&
          password &&
          confirmPassword &&
          // contractNumber &&
          // vatPercentage &&
          vatPercentage >= 0 &&
          vatPercentage <= 100 &&
          nameArabic &&
          contractName &&
          contractType &&
          password === confirmPassword&&
          this.state.isValidPassword
        ) {
          this.setState({
            isVisableUserBtn: true,
            duplicateEntry: false,
          });
        } else {
          this.setState({
            isVisableUserBtn: false,
            duplicateEntry: false,
          });
        }
      } else if (userType === 3) {
        if (
          name &&
          validEmailPattern.test(email) &&
          validEmailPattern.test(accountEmail) &&
          password &&
          confirmPassword &&
          contractNumber &&
          c_postalCode &&
          // vatPercentage &&
          vatPercentage >= 0 &&
          vatPercentage <= 100 &&
          nameArabic &&
          c_schemeId &&
          contractName &&
          contractType &&
          password === confirmPassword&&
          this.state.isValidPassword
        ) {
          this.setState({
            isVisableUserBtn: true,
            duplicateEntry: false,
          });
        } else {
          this.setState({
            isVisableUserBtn: false,
            duplicateEntry: false,
          });
        }
      } else {
        if (
          name &&
          validEmailPattern.test(email) &&
          validEmailPattern.test(accountEmail) &&
          creditPeriod &&
          password &&
          confirmPassword &&
          // contractNumber &&
          // vatPercentage &&
          vatPercentage >= 0 &&
          vatPercentage <= 100 &&
          nameArabic &&
          contractName &&
          c_taxNumber &&
          contractType &&
          c_streetName &&
          c_buildingNo &&
          c_city &&
          c_postalCode &&
          c_country &&
          c_district &&
          password === confirmPassword&&
          this.state.isValidPassword
        ) {
          this.setState({
            isVisableUserBtn: true,
            duplicateEntry: false,
          });
        } else {
          this.setState({
            isVisableUserBtn: false,
            duplicateEntry: false,
          });
        }
      }
    } else {
      if (
        name &&
        validEmailPattern.test(email) &&
        password &&
        confirmPassword &&
        // contractType &&
        password === confirmPassword&&
        this.state.isValidPassword
      ) {
        this.setState({
          isVisableUserBtn: true,
          duplicateEntry: false,
        });
      } else {
        this.setState({
          isVisableUserBtn: false,
          duplicateEntry: false,
        });
      }
    }

    // if(vatPercentage){
    //   if(vatPercentage >= 0 && vatPercentage <= 100){
    //     this.setState({
    //       isVisableUserBtn: true,
    //         vatPercentageError: "",
    //     });

    //   } else {
    //     this.setState({
    //       isVisableUserBtn: false,
    //       vatPercentageError:"Enter Value between 0 and 100"
    //     });
    //   }
    // }
    if (contractStartDate && contractEndDate) {
      const startDate = new Date(contractStartDate);
      const endDate = new Date(contractEndDate);

      if (startDate > endDate) {
        this.setState({
          isVisableUserBtn: false,
          contractEndDateError:
            "Contract end date must be after contract start date",
        });
      } else {
        this.setState({
          isVisableUserBtn: true,
          contractEndDateError: "",
        });
      }
    }
  }

  getUsersLimits = () => {
    if (this.props.logInUser.id) {
      //units limits
      if (checkPrivileges("device")) {
        fetch(`/api/devices/count?userId=${this.props.logInUser.id}`)
          .then((response) => {
            response.json().then((res) => {
              if (res.status === "success") {
                let unitsLimits = res.data;
                this.props.dispatch(parentUnitsLimit(unitsLimits));
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
                toast.error(this.props.translate(err[1]));
              } else {
                throw response;
              }
            });
          })
          .catch((e) => {
            toast.error("some thing went wrong");
          });
      }
      //users limits
      if (checkPrivileges("user")) {
        fetch(`/api/users/count?userId=${this.props.logInUser.id}`)
          .then((response) => {
            response.json().then((res) => {
              if (res.status === "success") {
                let usersLimits = res.data;
                this.props.dispatch(parentUsersLimit(usersLimits));
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
                toast.error(this.props.translate(err[1]));
              } else {
                throw response;
              }
            });
          })
          .catch((e) => {
            toast.error(this.props.translate("somethingWentWrong"));
          });
      }
    }
  };
  deleteAccount = () => {
    let { selectedAccount } = this.state;
    if (selectedAccount.id) {
      fetch(`/api/accounts/${selectedAccount.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...selectedAccount,
        }),
      })
        .then((response) => {
          response.json().then((res) => {
            if (res.status == "success") {
              this.getMoreAccounts();
              toast.success(this.props.translate("accountDeleted"));
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              toast.error(this.props.translate(err[1]));
            } else {
              throw response;
            }
          });
        })
        .catch((e) => {
          toast.error(this.props.translate("somethingWentWrong"));
          // if(e && e.text){
          //   e.text().then(err => {
          //     var array = err.split("-")
          //     if(array.length > 1){
          //           this.props.dispatch(
          //             toast.error({
          //               message: this.props.translate(array[0]),
          //               autoDismiss: 5,
          //               location: this.props.location
          //             })
          //           )

          //     }
          //     else {
          //         this.props.dispatch(
          //           toast.error({
          //             message: this.props.translate('somthingWentWrongMessage'),
          //             autoDismiss: 5,
          //             location: this.props.location
          //           })
          //         )
          //     }

          // })
          // }
        });
      this.setState({
        selectedAccount: "",
        attributeChangesMessage: "",
        onDeleteConfirmation: false,
      });
    }
  };

  checkAttributes = (data) => {
    let form = {
      coordinateFormat: data.coordinateFormat,
      poiLayer: data.poiLayer,
      latitude: data.latitude,
      longitude: data.longitude,
      zoom: data.zoom,
      map: data.map,
      attributes: {
        ["web.liveRouteLength"]:
          data.attributes && data.attributes["web.liveRouteLength"],
        ["web.maxZoom"]: data.attributes && data.attributes["web.maxZoom"],
        ["web.selectZoom"]:
          data.attributes && data.attributes["web.selectZoom"],
        ["timezone"]: data.attributes && data.attributes["timezone"],
        ["distanceUnit"]: data.attributes && data.attributes["distanceUnit"],
        ["speedUnit"]: data.attributes && data.attributes["speedUnit"],
        ["volumeUnit"]: data.attributes && data.attributes["volumeUnit"],
        ["measurementStandard"]:
          data.attributes && data.attributes["measurementStandard"],
        ["currencyLabel"]: "SAR",
      },
    };
    return form;
  };
  modalControle = () => {
    this.setState({
      addAccount: false,
      accountDefaultList: true,
      duplicateEntry: false,
      topRow: true,
      userDetailOption: false,
      passwordOption: false,
      isSelectedUser: false,
      selectedUser: "",
      form: "",
      isVisableUserBtn: false,
      isVisableRoleAssiging: false,
    });
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
    if (this.state.noRecord) {
      this.setState({
        defaultUserList: false,
        topRow: true,
      });
    } else {
      this.setState({
        defaultUserList: true,
        topRow: true,
      });
    }
  };
  timezone_handleChange = (name, value) => {
    if (name === "timezone") {
      if (!value || value.value === "None") {
        delete this.state.form.attributes[name];
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]: value.value,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    } else {
      if (name === "c_am") {
        this.setState(
          {
            accountsform: {
              ...this.state.accountsform,
              [name]: value.value,
            },
            managerAccountSelect: value,
          },
          () => {
            this.checkRequiredFields();
          }
        );
      }
      else if (name === "accountId") {
        this.setState(
          {
            loadingUser: true,
            form: {
              ...this.state.form,
              [name]: value.value,
              userId: "",
              deviceLimit: 0,
              userLimit: 0,
            },
            accountSelectedData: value,
            userSelectedData: "",
            isUserRecivedList: false,
            deviceLimitError: false,
            userLimitError: false,
            vehicleLimitError: false,
            usersLimitsMeaasge: "",
            vehicleLimitMeaasge: "",
          },
          () => {
            this.fetchUsersList(value.value);
            this.checkRequiredFields();
          }
        );
      } else if (name === "parentId") {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value.value,
              deviceLimit: 0,
              userLimit: 0,
            },
            userSelectedData: value,
            deviceLimitError: false,
            userLimitError: false,
            vehicleLimitError: false,
            usersLimitsMeaasge: "",
            vehicleLimitMeaasge: "",
          },
          () => {
            if (value.value) {
              this.checkLimitsForCreatingUser(value.value);
            }
            this.checkRequiredFields();
          }
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value.value,
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  };
  fetchUsersList = (id) => {
    let fetchApi = `/api/users/list?accountId=${id}&limit=1000`;

    fetch(fetchApi, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              let userlist = res.data;
              let result = [];
              userlist.data &&
                userlist.data.map((item) => {
                  if (item.userType !== 1) {
                    result.push({
                      label: item.label || item.name,
                      name: item.label || item.name,
                      value: item.id,
                      id: item.id,
                    });
                  }
                });
              if (parseInt(id) === parseInt(this.props.ServerSetting.id)) {
                result.unshift({
                  id: this.props.logInUser.id,
                  label: this.props.logInUser.name,
                  name: this.props.logInUser.name,
                  value: this.props.logInUser.id,
                });
              }
              if (result.length === 0) {
                toast.error(
                  this.props.translate(
                    "selectedAccountHaveNoUserWhichIsAbleToCreateNewUser"
                  )
                );
              }
              this.setState({
                defaultUsersList: result,
                isUserRecivedList: true,
                loadingUser: false,
              });
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              toast.error(this.props.translate(err[1]));
            }
          });
        }
      })
      .catch((e) => {
        console.log("e =", e);
        // this.props.dispatch(toast.error({
        // message: 'somethingWentWrong',
        // autoDismiss: 5
        // }))
      });
  };

  checkLimitsForCreatingUser = (id) => {
    this.setState(
      {
        isUserRecivedList: false,
      },
      () => {
        fetch(`/api/devices/count?userId=${id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((unitsLimits) => {
                if (unitsLimits.status === "success") {
                  this.setState({
                    parentUnitsLimit: unitsLimits.data,
                  });
                } else if (unitsLimits?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (unitsLimits.statusCode) {
                  var err = unitsLimits?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  toast.error(this.props.translate(err[1]));
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {
            toast.error("somethingWentWrong");
          });
        //users limits
        fetch(`/api/users/count?userId=${id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  let usersLimits = res.data;
                  this.setState({
                    parentUsersLimit: usersLimits,
                    isUserRecivedList: true,
                  });
                  if (usersLimits.remaningLimit === 0) {
                    this.setState({
                      userLimitError: true,
                      usersLimitsMeaasge: "userLimitErrorParent1",
                    });
                    toast.error(this.props.translate("userLimitErrorParent2"));
                  }
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  toast.error(this.props.translate(err[1]));
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {
            toast.error("somethingWentWrong");
          });
        //vehicles limits
        fetch(`/api/vehicles/count?userId=${id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  let vehicleLimit = res.data;
                  this.setState({
                    vehicleLimit: vehicleLimit,
                    isUserRecivedList: true,
                  });
                  if (vehicleLimit.remaningLimit === 0) {
                    this.setState({
                      vehicleLimitError: true,
                      vehicleLimitMeaasge: "userLimitErrorParent1",
                    });

                    toast.error(this.props.translate("userLimitErrorParent2"));
                  }
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  toast.error(this.props.translate(err[1]));
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {
            toast.error("somethingWentWrong");
          });
      }
    );
  };

  removeAccount = (item) => {
    this.setState({
      selectedAccount: item[0],
      onDeleteConfirmation: true,
    });
  };
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };
  onCloseC_Modal = () => {
    this.setState({
      accountDetailOption: false,
      showStatisticsModel: false,
      loader: false,
      selectedAccount: "",
      topRow: true,
      accountDefaultList: true,
    });
  };
  showStatistics = (data) => {
    if (data[0]) {
      let minId = [];
      let apiCall = `/api/users/get?accountId=${data[0].id}&all=true&limit=-1&search=`;
      this.setState({
        loader: true,
        accountDefaultList: false,
        topRow: false,
      });
      axios.get(apiCall).then((res) => {
        let response = res.data;
        response &&
          response.data &&
          response.data.data &&
          response.data.data.map((userId) => {
            userId && userId.entity && minId.push(userId.entity.id);
          });
        minId.sort();
        response &&
          response.data &&
          response.data.data &&
          response.data.data.map((user) => {
            if (minId && minId[0] === user.entity.id && user && user.entity) {
              minId = [];
              minId.push(user.entity);
            }
          });
        if (minId && minId[0]) {
          this.setState({
            accountDefaultList: false,
            selectedAccount: minId[0],
            loader: false,
            topRow: false,
            showStatisticsModel: true,
          });
        } else {
          this.setState(
            {
              selectedAccount: "",
              loader: false,
              showStatisticsModel: false,
              topRow: true,
              accountDefaultList: true,
            },
            () => {
              toast.error(this.props.translate("statisticsNotFound"));
            }
          );
        }
      });
    }
  };
  handleChangeRowsPerPage = (value) => {
    this.setState(
      {
        //tableData: [],
        page: 1,
        pageSize: value,
        //defaultUserList: false
      },
      () => this.getMoreAccounts()
    );
  };
  handleChangePage = (value) => {
    this.setState(
      {
        //tableData: [],
        page: value,
        //defaultUserList: false
      },
      () => this.getMoreAccounts()
    );
  };
  SearchItem = (searchValue) => {
    source.cancel();
    this.setState(
      {
        page: 1,
        itemSearch: searchValue,
      },
      () => this.getMoreAccounts()
    );
  };
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  setPagination = () => {
    if (
      this.props.accounts &&
      this.props.accounts.data &&
      this.props.accounts.data.length
    ) {
      let nAcc = { ...this.props.accounts };
      let lastPage = nAcc.total / this.state.pageSize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nAcc.data;
      nAcc.pageSize = this.state.pageSize;
      nAcc.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nAcc;
    }
    return {};
  };

  // removeImage = (index) => {
  //   const updatedImages = [...(this.props?.form?.attributes?.images || [])];
  //   if (index >= 0 && index < updatedImages.length) {
  //     updatedImages.splice(index, 1);

  //     this.setState(prevState => ({
  //       form: {
  //         ...prevState.form,
  //         attributes: {
  //           ...prevState.form.attributes,
  //           images: updatedImages,
  //         }
  //       }
  //     }), () => this.checkRequiredFields());
  //   } else {
  //     console.error("Invalid index or images array is not defined.");
  //   }
  // };

  handleChangeAttributes = (name) => (event) => {
    const c_schemeIdList = [
      { key: 1, name: "TIN" },
      { key: 2, name: "CRN" },
      { key: 3, name: "MOM" },
      { key: 4, name: "MLS" },
      { key: 5, name: "700" },
      { key: 6, name: "SAG" },
      { key: 7, name: "NAT" },
      { key: 8, name: "GCC" },
      { key: 9, name: "IQA" },
      { key: 10, name: "OTH" },
    ];

    const value =
      name === "agreedAdvancePayment"
        ? parseFloat(event.target.value)
        : event.target.value;

    // Check if the field being changed is c_schemeId
    if (name === "c_schemeId") {
      // Find the corresponding scheme name based on the selected value
      const selectedScheme = c_schemeIdList.find(
        (scheme) => scheme.name === value
      );
      if (selectedScheme) {
        // Dynamically set the value of the second field based on the selected scheme ID
        const newFieldName = `c_${selectedScheme.name.toLowerCase()}Number`;

        this.setState({
          accountsform: {
            ...this.state.accountsform,
            [name]: value,
            [newFieldName]: "", // Initialize the value of the new field
          },
        });
      }
    } else if (name === "number") {
      this.setState({
        newField: value,

        accountsform: {
          ...this.state.accountsform,
          [`c_${this.state?.accountsform?.c_schemeId?.toLowerCase()}Number`]:
            value,
        },
      });
    } else {
      // For other fields, update the state as usual
      this.setState(
        {
          accountsform: {
            ...this.state.accountsform,
            [name]: value,
          },
        },
        () => {
          this.checkRequiredFields();
        }
      );
    }
  };

  handleAccountsChange = (name, e) => {
    if (name === "type") {
      let accountsType = "";
      e.map((i) => {
        accountsType += "&accountType=" + i.value;
      });
      this.setState(
        {
          page: 1,
          accountTypes: accountsType,
        },
        () => this.getMoreAccounts()
      );
    } else if (name === "status") {
      let accountsStatus = "";
      e.map((i) => {
        accountsStatus += "&accountStatus=" + i.value;
      });
      this.setState(
        {
          page: 1,
          accountStatuses: accountsStatus,
        },
        () => this.getMoreAccounts()
      );
    } else if (name === "time") {
      let accountsTime = "";
      e.map((i) => {
        accountsTime += "&accountTime=" + i.value;
      });
      this.setState(
        {
          page: 1,
          accountTimes: accountsTime,
        },
        () => this.getMoreAccounts()
      );
    }
  };

  fetchAccountsList = (id) => {
    let fetchApi = `/api/accounts/list?accountId=${id}&limit=1000`;
    if (this.props.logInUser.userType === -1) {
      fetchApi = `/api/accounts/all?accountId=${id}&all=true&limit=1000`;
    }
    fetch(fetchApi, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              let userlist = res.data;
              let result =
                userlist.data &&
                userlist.data.map((item) => ({
                  ...item,
                  label: item.label || item.name,
                  name: item.label || item.name,
                  value: item.id,
                }));
              result.unshift({
                id: this.props.ServerSetting.id,
                label: this.props.ServerSetting.name,
                value: this.props.ServerSetting.id,
              });
              this.setState({
                defaultAccountsList: result,
              });
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              toast.error(this.props.translate(err[1]));
            }
          });
        }
      })
      .catch((e) => {
        console.log("e =", e);
        //   this.props.dispatch(toast.error({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      });
  };
  fetchAccountManagersList = (id) => {
  
    let fetchApi = `/api/users/get?page=1&limit=-1&contractType=${id}&search=&userTypes=4`;
    if (this.props.logInUser.userType === -1) {
      fetchApi = `/api/users/get?page=1&limit=-1&contractType=${id}&search=&userTypes=4`;
    }
    fetch(fetchApi, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              let userlist = res.data;
              let result =
                userlist.data &&
                userlist.data.map((item) => ({
                  id:item.entity.id,
                  label: item.entity.label || item.entity.name,
                  name: item.entity.label || item.entity.name,
                  value:item.entity.id,
                }));
              // result.unshift({
              //   id: this.props.ServerSetting.id,
              //   label: this.props.ServerSetting.name,
              //   value: this.props.ServerSetting.id,
              // });
              this.setState({
                defaultAccountManagerList: result,
              });
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              toast.error(this.props.translate(err[1]));
            }
          });
        }
      })
      .catch((e) => {
        console.log("e =", e);
        //   this.props.dispatch(toast.error({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      });
  };
  openAccountStausDialog = (event, item) => {
    let user = item[0];
    this.setState({
      accountStatusLabel: user.active
        ? "Are You Sure, You Want to DeActivate"
        : "Are You Sure, You Want to Activate",
      selectedAccount: item[0],
      accountActivate: true,
    });
    event.stopPropagation();
  };
  closeAccountStausDialog = () => {
    this.setState({
      accountActivate: false,
    });
  };

  handleAccountStatus = (event, item) => {
    const active = this.state.selectedAccount.active ? true : false;

    let fetchApi;
    if (active) {
      fetchApi = `/api/accounts/inactive/${this.state.selectedAccount.id}`;
    } else {
      fetchApi = `/api/accounts/active/${this.state.selectedAccount.id}`;
    }

    this.setState(
      {
        loader1: true,
      },
      () => {
        fetch(fetchApi, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  this.setState(
                    {
                      accountActivate: false,
                      loader1: false,
                    },
                    () => this.getMoreAccounts()
                  );
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  toast.error(this.props.translate(err[1]));
                }
              });
            }
          })
          .catch((e) => {
            console.log("e =", e);
          });
      }
    );
  };

  handleChange = (name) => (event) => {
    if (!event) {
      // Handle the case where event is null (e.g., when clearing the input)
      this.setState({
        [name]: null,
      });
      return;
    }
    if(name==="contractType"){
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.target.value,
          },
        },
        () => {
       this.fetchAccountManagersList(this.state.form.contractType)
        }
      );
    }
 
    if(name==="password"||name==="confirmPassword"){
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.target.value,
          },
        },
        () => {
          if (name === "password" || name === "confirmPassword") {
            this.verifyPassword();
          }
        }
      );
    }
    const { target } = event;

    if (target && target.type === "checkbox") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked,
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (
      name === "expirationTime" ||
      name === "contractStartDate" ||
      name === "defaultSatisfactionDate" ||
      name === "settleDate" ||
      name === "contractEndDate"
    ) {
      const dateValue = event ? event.toISOString() : null;

      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: dateValue,
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      let value = target.value;
      if (value) {
        if (target.type === "number") {
          value = parseFloat(value);
        }
        if (name === "deviceLimit") {
          const { parentUnitsLimit } = this.state;
          if (
            parseInt(value) === -1 &&
            parseInt(parentUnitsLimit && parentUnitsLimit.remaningLimit) === -1
          ) {
            this.setState(
              {
                deviceLimitError: false,
                unitsLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (
                parseInt(parentUnitsLimit && parentUnitsLimit.remaningLimit) ===
                -1
              ) {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              } else if (
                parseInt(value) >
                parseInt(
                  parentUnitsLimit &&
                    parentUnitsLimit.remaningLimit - parentUnitsLimit &&
                    parentUnitsLimit.totalCount
                )
              ) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: "deviceLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: "deviceLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        if (name === "userLimit") {
          const { parentUsersLimit } = this.state;
          if (
            parseInt(value) === -1 &&
            parseInt(parentUsersLimit && parentUsersLimit.remaningLimit) === -1
          ) {
            this.setState(
              {
                userLimitError: false,
                usersLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (
                parseInt(parentUsersLimit && parentUsersLimit.remaningLimit) ===
                -1
              ) {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              } else if (
                parseInt(value + 1) >
                parseInt(
                  parentUsersLimit &&
                    parentUsersLimit.remaningLimit - parentUsersLimit &&
                    parentUsersLimit.totalCount
                )
              ) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: "userLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: "userLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        if (name === "vehicleLimit") {
          const { vehicleLimit } = this.state;
          if (
            parseInt(value) === -1 &&
            parseInt(vehicleLimit && vehicleLimit.remaningLimit) === -1
          ) {
            this.setState(
              {
                vehicleLimitError: false,
                vehicleLimitMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (parseInt(vehicleLimit && vehicleLimit.remaningLimit) === -1) {
                this.setState(
                  {
                    vehicleLimitError: false,
                    vehicleLimitMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              } else if (
                parseInt(value + 1) >
                parseInt(
                  vehicleLimit &&
                    vehicleLimit.remaningLimit - vehicleLimit &&
                    vehicleLimit.totalCount
                )
              ) {
                this.setState(
                  {
                    vehicleLimitError: true,
                    vehicleLimitMeaasge: "vehicleLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    vehicleLimitError: false,
                    vehicleLimitMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    vehicleLimitError: true,
                    vehicleLimitMeaasge: "userLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    vehicleLimitError: false,
                    vehicleLimitMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }

        if (
          name === "nameArabic" ||
          name === "customerType" ||
          name === "pacContractId"
        ) {
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: value,
              },
            },
            () => this.checkRequiredFields()
          );
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => this.checkRequiredFields()
        );
      } else if (name === "accountEmail") {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => this.checkRequiredFields()
        );
      } else if (name === "garage") {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event,
            },
          },
          () => this.checkRequiredFields()
        );
      } else if (this.state.form[name]) {
        if (target.type === "number") {
          value = parseFloat(value);
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => {
              this.checkRequiredFields();
            }
        );
      }
    }
  };

  handleChangeExpense = (name, value) => {
    // console.log("name======", name, value);

    if (name === "garage") {
      this.setState(
        {
          form: {
            ...this.state.form,
            garage: value,
          },
        },
        () => {
          this.checkRequiredFields();
        }
      );
    }
  };
  searchGarageList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    if (source) {
      source.cancel("Operation canceled due to new request=======.");
    }
    source = axios.CancelToken.source();

    return await instance({
      method: "GET",
      url: `/api/garages/get?userId=${
        this.props.logInUser && this.props.logInUser.id
      }&page=1&limit=50&all=true&search=${inputValue}`,
      cancelToken: source.token,

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data) {
          return response.data.map((item) => {
            return {
              id: item.id,
              key: item.id,
              name: item.name + " - " + item.garageNumber,
              label: item.name + " - " + item.garageNumber,
              uniqueId: item.id,
              valueType: "string",
              garageNumber: item.garageNumber,
            };
          });
        }
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };
  getGarageList = async (inputValue) => {
    await instance({
      method: "GET",
      url: `/api/garages/get?userId=${
        this.props.logInUser && this.props.logInUser.id
      }&page=1&limit=50&all=true`,
      cancelToken: source.token,

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data) {
          const garages = response.data.map((item) => {
            return {
              id: item.id,
              key: item.id,
              name: item.name + " - " + item.garageNumber,
              label: item.name + " - " + item.garageNumber,
              uniqueId: item.id,
              valueType: "string",
              garageNumber: item.garageNumber,
            };
          });
          this.setState({
            defaultGarageOptions: garages || [],
          });
        }
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };
  accountSelection = (item) => {
    // let item = item1[0]
    // console.log("item====", item  );
    // const garage =  await this.fetchSelectedGarage(item.ga)
    const keys = Object.keys(item);
    const final = {};
    keys.map((key) => {
      if (typeof item[key] === "string") {
        final[key] = parse(item[key]) || "";
      } else final[key] = item[key];
    });
    // console.log("fina====", final  );

    this.setState(
      {
        accountDefaultList: false,
        selectedAccount: item,
        addAccount: false,
        accountDetailOption: true,
        topRow: false,
      },
      () => {
        this.getGarageList();
      }
    );
  };

  fetchSelectedGarage(id) {
    return instance({
      method: "GET",
      url: `/api/garages/garagenumber?garagenumber=${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  submit() {
    let obj = {};
    let url = `/api/accounts`;
    let { attributes } = this.state.form;
    let {
      name,
      email,
      password,
      parentId,
      accountEmail,
      accountName,
      nameArabic,
      monthlyRevenueTarget,
      yearlyRevenueTarget,
      accountDescription,
      accountContact,
      customerNumber,
      contractName,
      contractNumber,
      creditLimit,
      creditPeriod,
      contractStartDate,
      contractEndDate,
      contractType,
      status,
      settleDate,
      defaultSatisfactionDate,
      vatPercentage,
      customerType,
      garage,
      pacContractId,
    } = this.state.form;
    if (
      this.state.form.userType === 3 ||
      this.state.form.userType === 5 ||
      this.state.form.userType === 6
    ) {
      if (
        name.trim().length > 0 &&
        email.trim().length > 0 &&
        password.trim().length > 0 &&
        contractName.trim().length > 0 &&
        nameArabic.trim().length > 0 &&
        creditPeriod !== ""
      ) {
        this.setState({
          selectedUser: "",
          btnVisible: true,
        });
        if (parentId) {
          attributes = { ...attributes, parentId: parentId };
        }

        const obj1 = userDataFormat(this.state.form, attributes);

        if (this.state.form.userType > 2) {
          obj = {
            account: {
              name: accountName,
              email: accountEmail,
              nameArabic: nameArabic || "",
              monthlyRevenueTarget: monthlyRevenueTarget || "",
              yearlyRevenueTarget: yearlyRevenueTarget || "",
              description: accountDescription ? accountDescription : "",
              contact: accountContact ? accountContact : "",
              customerNumber: customerNumber ? customerNumber : "",
              contractName: contractName ? contractName : "",
              contractNumber: contractNumber ? contractNumber : "",
              vatPercentage: vatPercentage ? vatPercentage : 0,
              creditLimit: creditLimit ? creditLimit : "",
              creditPeriod: creditPeriod ? creditPeriod : "",
              contractStartDate: contractStartDate ? contractStartDate : "",
              contractEndDate: contractEndDate ? contractEndDate : "",
              contractType: contractType ? contractType : 1,
              status: status ? status : 1,
              settleDate: settleDate ? settleDate : "",
              defaultSatisfactionDate: defaultSatisfactionDate
                ? defaultSatisfactionDate
                : "",
              attributes: { ...this.state.accountsform },
              // customerType:customerType
            },
            user: {
              ...obj1,
            },
          };
        } else {
          obj = { ...obj1 };
          url = `/api/users/`;
        }
        // console.log("s1=====",obj);

        fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...obj,
          }),
        })
          .then((response) => {
            // if (response.ok) {
            response.json().then((user) => {
              if (user.status === "success") {
                this.getMoreAccounts();
                if (this.props.onCloseModal) {
                  this.props.onCloseModal();
                }
                // this.getUsersLimits()
                toast.success(this.props.translate("userIsCreated"));
                this.setState({
                  form: "",
                  spForm: "",
                  addOption: false,
                  addAccount: false,
                  accountDefaultList: true,
                  selectedUser: "",
                  isVisableUserBtn: false,
                  defaultUserList: true,
                  topRow: true,
                });
              } else if (user?.statusCode === "440") {
                window.location.replace("/login");
              } else if (user.statusCode) {
                var err = user?.message.split(":");
                var err2 = err[1].split("&#");
                toast.error(this.props.translate(err2[0]));
              } else {
                throw response;
              }
            });
            // }
            // else {
            //   throw response
            // }
          })
          .catch((e) => {
            toast.error(this.props.translate("somthingWentWrongMessage"));
          });
      } else {
        this.setState({
          isVisableUserBtn: false,
        });
        toast.error(this.props.translate("emptyFields"));
      }
    } else {
      if (
        name.trim().length > 0 &&
        email.trim().length > 0 &&
        password.trim().length > 0
      ) {
        this.setState({
          selectedUser: "",
          btnVisible: true,
        });
        if (parentId) {
          attributes = { ...attributes, parentId: parentId };
        }
        // console.log("this.state.form=====",this.state.form);

        const obj1 = userDataFormat(this.state.form, attributes);
        // console.log("obj1=====",obj1);

        if (this.state.form.userType > 2) {
          obj = {
            account: {
              name: accountName,
              email: accountEmail,
              nameArabic: nameArabic || "",
              monthlyRevenueTarget: monthlyRevenueTarget || "",
              yearlyRevenueTarget: yearlyRevenueTarget || "",
              description: accountDescription ? accountDescription : "",
              contact: accountContact ? accountContact : "",
              customerNumber: customerNumber ? customerNumber : "",
              contractName: contractName ? contractName : "",
              contractNumber: contractNumber ? contractNumber : "",
              vatPercentage: vatPercentage ? vatPercentage : 0,
              creditLimit: creditLimit ? creditLimit : "",
              creditPeriod: creditPeriod ? creditPeriod : "",
              contractStartDate: contractStartDate ? contractStartDate : "",
              contractEndDate: contractEndDate ? contractEndDate : "",
              contractType: contractType ? contractType : 1,
              settleDate: settleDate ? settleDate : "",
              defaultSatisfactionDate: defaultSatisfactionDate
                ? defaultSatisfactionDate
                : "",
              attributes: { ...this.state.accountsform },
              customerType: customerType,
              garageNumber: garage?.garageNumber ? garage.garageNumber : "",
              pacContractId: pacContractId ? pacContractId : "",
            },
            user: {
              ...obj1,
            },
          };
        } else {
          obj = { ...obj1 };
          url = `/api/users/`;
        }

        // console.log("s2=====",obj);

        fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...obj,
          }),
        })
          .then((response) => {
            // if (response.ok) {
            response.json().then((user) => {
              if (user.status === "success") {
                this.getMoreAccounts();
                if (this.props.onCloseModal) {
                  this.props.onCloseModal();
                }
                // this.getUsersLimits()
                // this.props.dispatch(
                toast.success(this.props.translate("userIsCreated"));
                // );
                this.setState({
                  form: "",
                  spForm: "",
                  addOption: false,
                  addAccount: false,
                  accountDefaultList: true,
                  selectedUser: "",
                  isVisableUserBtn: false,
                  defaultUserList: true,
                  topRow: true,
                });
              } else if (user?.statusCode === "440") {
                window.location.replace("/login");
              } else if (user.statusCode) {
                var err = user?.message.split(":");
                var err2 = err[1].split("&#");
                // this.props.dispatch(
                toast.error(this.props.translate(err2[0]));
                // );
              } else {
                throw response;
              }
            });
            // }
            // else {
            //   throw response
            // }
          })
          .catch((e) => {
            // this.props.dispatch(
            toast.error(this.props.translate("somthingWentWrongMessage"));
            // );
          });
      } else {
        this.setState({
          isVisableUserBtn: false,
        });
        // this.props.dispatch(
        toast.error(this.props.translate("emptyFields"));
        // );
      }
    }
  }

  // handleClickShowPostion = () => {
  //   this.setState({
  //     isMapModal: true,
  //     isVisableAddlocBtn: false,
  //   });
  // };
  addNewAccount() {
    this.fetchAccountsList(this.props.logInUser.accountId);
    // this.fetchAccountManagersList(this.props.logInUser.accountId);

    let limitsOptionData = formDefaultState;
    if (this.props.logInUser.userType === -1) {
      this.fetchUsersList(this.props.logInUser.accountId);
    }

    let defaulfFeildsSetting = this.checkAttributes(this.props.logInUser);
    this.setState(
      {
        addAccount: true,
        topRow: false,
        accountDefaultList: false,
        form: { ...this.state.form, attributes: { ...attributesDefaultValue } },
        spForm: { ...limitsOptionData, ...defaulfFeildsSetting },
      },
      () => {
        this.getGarageList();
      }
    );
  }

  openDashboard = () => {
    this.props.navigate("/ex_dashboard", { 
      state: { openNTMCDashboard: true } 
    });
  };
  render() {
    const { value, loader1 } = this.state;
    return (
      <Fragment>
        {this.state.addAccount && (
          <AddAccountModal
            {...this.state}
            function={"addNew"}
            roleTypes={
              this.props.roles && this.props.roles.filter((item) => item.id > 7)
            }
            handleChangeExpense={this.handleChangeExpense}
            searchGarageList={this.searchGarageList}
            formSubmit={this.submit}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            changeLimit={this.changeLimit}
            // handleChangeForSP={this.handleChangeForSP}
            modalControle={this.modalControle}
            userType={this.props.logInUser.userType}
            logInUser={this.props.logInUser}
            timeDateOnchange={this.timeDateOnchange}
            generateToken={this.generateToken}
            setNoToken={this.setNoToken}
            setExpirationTime={this.setExpirationTime}
            changeAttribute2={this.changeAttribute2}
            changeAttribute2ForSP={this.changeAttribute2ForSP}
            uploadImage={this.uploadImage}
            buttonText={this.props.translate("sharedCreate")}
            hasAccessOfUpdate={checkPrivileges("accountUpdate")}
            hasAccessOfDelete={checkPrivileges("accountDelete")}
            hasAccessOfCreate={checkPrivileges("accountCreate")}
            handleChangeAttributes={this.handleChangeAttributes}
            timezone_handleChange={this.timezone_handleChange}
            translate={this.props.translate}
          />
        )}

        {/* {this.state.isMapModal && (
          <CustomDialog
            title={this.props.translate("setLocation")}
            themeColors={this.props.themecolors}
            visable={true}
            onClose={this.onCloseModal}
            isButtonVisable={true}
            draggable={true}
            CancelText={this.props.translate("sharedBack")}
            fullWidth
            translate={this.props.translate}

            headerActions={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                disabled={!this.state.isVisableAddlocBtn}
                onClick={() => this.setSelectedLocation()}
              >
                {" "}
                {this.props.translate("sharedAdd")}
              </Button>
            }
            bodyPadding={0}
          >
            <MarkerMap
              getPostion={this.getPostion}
              minHeight={500}
              zoom={this.state.selectedUser.zoom || 0}
              lat={this.state.selectedUser.latitude || 0}
              lng={this.state.selectedUser.longitude || 0}
              height={"100%"}
            />
          </CustomDialog>
        )} */}
        {this.state.topRow && (
          <>
            <Grid container style={{ display: "flex" }}>
              <Grid item xs={4} sm={4} style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.handleChangeTabs("maintenance")}
                  style={{
                    width: "50%",
                    borderTopRightRadius: " 15px",
                    borderTopLeftRadius: "15px",
                    marginLeft: "5px",
                    backgroundColor:
                      value === "maintenance" ? "#00C179" : "#ffff",
                    color: value === "maintenance" ? "#ffff" : "#00C179",
                  }}
                >
                  {this.props.translate("maintenance")}
                </Button>

                <Button
                  variant="contained"
                  disabled={false}
                  onClick={() => this.handleChangeTabs("leasing")}
                  style={{
                    width: "50%",
                    borderTopRightRadius: " 15px",
                    borderTopLeftRadius: "15px",
                    marginLeft: "5px",
                    backgroundColor: value === "leasing" ? "#00C179" : "#ffff",
                    color: value === "leasing" ? "#ffff" : "#00C179",
                  }}
                >
                  {" "}
                  {this.props.translate("leasing")}
                </Button>
                <Button
                  variant="contained"
                  disabled={false}
                  onClick={() => this.handleChangeTabs("cashCustomer")}
                  style={{
                    width: "75%",
                    borderTopRightRadius: " 15px",
                    borderTopLeftRadius: "15px",
                    marginLeft: "5px",
                    backgroundColor:
                      value === "cashCustomer" ? "#00C179" : "#ffff",
                    color: value === "cashCustomer" ? "#ffff" : "#00C179",
                  }}
                >
                  {" "}
                  {this.props.translate("cashCustomer")}
                </Button>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
              <h3 style={{margin: 0}}>{this.props.translate('accounts')}</h3>
            </Grid> */}
              <Grid
                item
                xs={8}
                sm={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    value={this.state.itemSearch}
                    onChange={(e) => this.SearchItem(e.target.value)}
                  />
                </div>
                <div style={{ width: "10%", marginRight: 5, marginTop: 6 }}>
                  <Button
                    disabled={
                      !(checkPrivileges("accountCreate") &&
                      checkPrivileges("userCreate") &&
                      (this.props.parentUserLimits.remaningLimit > 0 ||
                        this.props.parentUserLimits.remaningLimit === -1)
                        ? true
                        : false)
                    }
                    aria-label="Create"
                    size="small"
                    color="inherit"
                    onClick={this.addNewAccount}
                    id="createButton"
                  >
                    {this.props.translate("create")}
                  </Button>
                </div>
                {/* <div style={{ width: "10%", marginRight:4, marginTop: 6 }}>
                  <Button
                    aria-label="Dashboard"
                    size="small"
                    color="inherit"
                    onClick={this.openDashboard}
                    id="dashboardButton"
                  >
                    {this.props.translate("NTMC")}
                  </Button>
                </div> */}
                <div style={{ width: "10%", marginRight: 5, marginTop: 6 }}>
                            <ExportImportMenu
                              fileName="uploadAccount"
                              isUploadAccount
                              isServices
                              themecolors={this.props.themecolors}
                              translate={this.props.translate}
                              title={this.props.translate("reportExport")}
                              downloadType="accounts"
                              Data={reduceData(
                                this.props.accounts && this.props.accounts.data
                              )}
                              DataLength={
                                this.props.accounts &&
                                this.props.accounts.data &&
                                this.props.accounts.data.length
                                  ? true
                                  : false
                              }
                              closedModel={this.onCloseC_Modal}
                              dispatch={this.props.dispatch}
                              noPdf
                            />
                          </div>
              </Grid>
            </Grid>
          </>
        )}

        {this.state.accountDefaultList && (
          <>
            {loader1 ? (
              <Loader />
            ) : (
              <Table
                translate={this.props.translate}
                rows={(this.props.accounts && this.props.accounts.data) || []}
                pagination={this.setPagination()}
                logInUser={this.props.logInUser}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleChangePage={this.handleChangePage}
                SearchItem={this.SearchItem}
                onClick={
                  checkPrivileges("accountUpdate") ? this.accountSelection : ""
                }
                onDelete={
                  checkPrivileges("accountDelete") ? this.removeAccount : ""
                }
                // onDelete={this.removeAccount}
                handleAccountStatus={this.handleAccountStatus}
                // accountDataArray1={this.state.accountType}
                // accountDataArray2={this.state.accountStatus}
                // accountDataArray3={this.state.accountTime}
                openAccountStausDialog={this.openAccountStausDialog}
                accountDataArrayChange1={(e) =>
                  this.handleAccountsChange("type", e)
                }
                accountDataArrayChange2={(e) =>
                  this.handleAccountsChange("status", e)
                }
                accountDataArrayChange3={(e) =>
                  this.handleAccountsChange("time", e)
                }
                // showStatistics={this.showStatistics}
                // isRowIcons
                btnName={this.props.translate("create")}
                onCreate={() => this.addNewAccount()}
                btnName1={"accounts"}
                rowsPerPage={15}
                // isEditable={true}/
                themecolors={this.props.themecolors}
                hasAccessOfUpdate
                // hasAccessOfUpdate={checkPrivileges('userUpdate')}
                // hasAccessOfDelete={checkPrivileges('accountDelete')}
                // title={this.props.translate('accounts')}
                filterDropdown
                setSerialNo
                isCursoPointer
                rowDefinition={[
                  {
                    id: "name",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("sharedName"),
                  },
                  {
                    id: "email",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("accountEmail"),
                  },
                  {
                    id: "contractNumber",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("contractNumber"),
                  },
                  {
                    id: "contractName",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("contractName"),
                  },

                  {
                    id: "creditLimit",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("creditLimit"),
                  },
                  {
                    id: "creditPeriod",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("creditPeriod"),
                  },
                  {
                    id: "contractStartDate",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("contractStartDate"),
                  },
                  {
                    id: "contractEndDate",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("contractEndDate"),
                  },
                  ...(checkPrivileges("accountStatusViewOnly")
                    ? [
                        {
                          id: "active",
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate("accountStatus"),
                        },
                      ]
                    : []),
                  // {
                  //   id: "active",
                  //   numeric: false,
                  //   disablePadding: false,
                  //   label: this.props.translate("active"),
                  // },
                ]}
              />
            )}
          </>
        )}
        {(this.state.selectedAccount && this.state.showStatisticsModel) ||
        this.state.loader ? (
          <ViewStatistics
            selectItem={this.state.selectedAccount}
            onClose={this.onCloseC_Modal}
            title="Accounts"
          />
        ) : null}

        {this.state.accountDetailOption && this.state.selectedAccount && (
          <>
            <ViewAccountDetail
              {...this.state}
              selectItemName={this.state.selectedAccount.name}
              selectItemId={this.state.selectedAccount.userId}
              selectAccountId={this.state.selectedAccount.id}
              selectItem={this.state.selectedAccount}
              getMoreAccounts={this.getMoreAccounts}
              onClose={this.onCloseC_Modal}
              translate={this.props.translate}
            />
          </>
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteAccount}
            title={this.props.translate("areYouWantToDelete")}
            children={this.state.selectedAccount.name}
            translate={this.props.translate}
          />
        )}

        {this.state.accountActivate && (
          <ConfirmDialoag
            translate={this.props.translate}
            onCancel={this.closeAccountStausDialog}
            onOk={this.handleAccountStatus}
            title={this.state.accountStatusLabel}
            children={
              <span>
                <span style={{ fontWeight: "bold" }}>Account Name : </span>
                {this.state.selectedAccount.name}
              </span>
            }
          />
        )}
      </Fragment>
    );
  }
}

const mapState = (state) => {
  return {
    accounts: state.accounts,
    users: state.users,
    logInUser: state.logInUsers,
    roles: state.roles,
    ServerSetting: state.ServerSetting,
    themecolors: state.themecolors,
    parentUserLimits: state.parentUserLimits,
    parentUnitLimits: state.parentUnitLimits,
  };
};
const mapStateToProps = connect(mapState);
export const AccountModal = mapStateToProps(withRoutes(accountModal));
const reduceData = (d) => {
  let data = [],
    index = 1;

  if (d && d.length) {
    d.forEach((item) => {
      // Skip adding item if userType is -1
      if (item.userType === -1) return;

      // Format dates to show only day, month, and year
      const formattedContractStart = item.contractStartDate
        ? new Date(item.contractStartDate).toLocaleDateString("en-GB")
        : null;
      const formattedContractEnd = item.contractEndDate
        ? new Date(item.contractEndDate).toLocaleDateString("en-GB")
        : null;

      data.push({
        Srn: index++,
        Account_Name: item.name,
        Email: item.email,
        Account_Type:
          item.userType === 1
            ? "User"
            : item.userType === 2
            ? "Admin"
            : item.userType === 3
            ? "Account Admin"
            : item.userType === 4
            ? "Service Provider"
            : item.userType === 5
            ? "Reseller"
            : item.userType === 6
            ? "Distributor"
            : "",
        Contract_Name: item.contractName,
        Contract_Number: item.contractNumber,
        PAC_Contract_Number: item.pacContractId,
        Credit_Limit: item.creditLimit,
        Credit_Period: item.creditPeriod,
        Account_Status: item.active ? "Active" : "InActive",
        Tax_Number: item.attributes.c_taxNumber,
        Contract_Start: formattedContractStart,
        Contract_End: formattedContractEnd,
      });
    });
  }

  return data;
};

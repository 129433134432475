import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { MenuItem,Tooltip   } from '@mui/material';
import { themecolors } from './../../Layout/theme'


const mapStateToProps = (state, ownProps) => {
  return {
    // themecolors: state.themecolors
  }
};

const CustomTextFieldRoot = styled(TextField)(({ multiline, rows }) => ({
  '& .MuiInputLabel-root': {
    marginTop: '-8px',
    fontSize: '12px',
    '&.Mui-focused': {
      color: themecolors['500'],
      marginTop: '0',
    },
    '&.Mui-error': {
      color: themecolors['error'],
    },
    '&.MuiInputLabel-shrink': {
      marginTop: '0',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: themecolors['500'],
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: themecolors['error'] + ' !important',
    },
  },
  '& .MuiOutlinedInput-input': {
    fontSize: multiline && rows ? '14px' : '12px' ,
    padding: '5px 14px',
    minHeight: '18px',
    height: 'auto',
    fontWeight: multiline && rows ? 400 : 'normal',
    color:multiline && rows ? 'black' : '#999'
  },
  '& .MuiOutlinedInput-multiline': {
    padding: '0',
    fontSize: '14px',
  },
  '& .MuiSelect-icon': {
    color: '#999',
  },
  '& .MuiPaper-root': {
    backgroundColor: themecolors['500'],
  },
}));


class CustomTextField extends React.Component {

  getSelectedNames = () => {
    const { selectedValues } = this.props;
    const { options } = this.props;

    const selectedNames = options
      .filter(option => selectedValues?.includes(option.key))
      .map(option => option.name);

    return selectedNames.join(', ');
  };

  render() {
    const { classes, dispatch,options,multiline, ...others } = this.props;

    return (
      <Tooltip
      title={multiline ? this.getSelectedNames() : ""}
      arrow
      disableHoverListener={!multiline}
      placement='right'
    >
      <div>
      <CustomTextFieldRoot
        {...others}
        select={multiline}
        variant="outlined"
        margin="dense"
        value={this.props.value} 
        onChange={this.props.onChange} 
        InputProps={{
          ...this.props.InputProps,
        }}
        SelectProps={{
          multiple: multiline,
          MenuProps: {
            classes: {
              paper: 'custom-menu-paper',
            },
          },
        }}
        InputLabelProps={{
          ...this.props.InputLabelProps,
        }}
        >
   {multiline &&
          options && options.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.name}
            </MenuItem>
          ))}
      </CustomTextFieldRoot>
      </div>
      </Tooltip>
    );
  }
}

CustomTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  multiline: PropTypes.bool,
};
CustomTextField.defaultProps = {
  multiline: false,
};

export default connect(mapStateToProps)(CustomTextField);

import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkPrivileges , replaceSpecialCh} from "../../Helpers";
import Loader from "../../Layout/Loader";
import { Grid, InputAdornment } from "@mui/material";
import Table from "../common/tableWithBackEndPagination";
import Button from "../common/Button";
import TextField from "../common/TextField";
import ConfirmDialoag from "../common/ConfirmDialoag";
import axios from "axios";
import instance from "../../axios";
import withTranslationWrapper from "../../HOC/HocTranslate";
let source;
class RejectedDeliveryNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisable: false,
      loader: false,
      createSubType: false,
      createLeasing: false,
      mode: "create",
      isVisableSubmitBtn: false,
      onDeleteConfirmation: false,
      selectedCategory: {},
      allSubTypes: [],
      garages: [],
      invoiceForm: {},
      garageList: [],
      vehicleList: [],
      leasingItems: [],
      leaseForm: {},
      cashForm:{
        id:"", attributes: {},  invoiceDate:"" , saleNetAmount:"" , saleVatAmount:"" , saleTotalAmount :"" ,
        // createdFor: "", 
        refNum: "",parentId:this.props.logInUser.id, contractNumber:" "
      },
      value: "tab1",
      serviceExpenseCollection: [
        {
          itemQty: "1",
          itemPrice: "1",
          itemName: "",
          categoryName: "",
          selectedSubTypes: [],
        },
      ],
      cashAccount:"",
      to:"",
      page:1,
      pageSize:20,
      deliveryNoteRefNumber:"",
      deliveryNoteNumber:"",
    };
  }

  componentWillMount() {
    this.setState({
      serviceExpenseCollection: [
        {
          itemQty: "1",
          itemPrice: "1",
          itemName: "",
          categoryName: "",
          selectedSubTypes: [],
        },
      ],
    });
    this.getVehicleList();
  }

  getVehicleList = () => {
    let id =
      this.props.selectedAccount && this.props.selectedAccount.userId
        ? this.props.selectedAccount.userId
        : this.props.logInUser && this.props.logInUser.id;
    if (this.props.logInUser && this.props.logInUser.id) {
      // console.log("id====",id);
      instance({
        url: `/api/vehicles/get?userId=${id}&all=true&limit=50`,
        // url: `/api/vehicles/get?userId=${id}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({ vehicleList: res.data });
        })
        .catch((error) => {
          // console.log("error ==", error)
        });
    }
  };


 




  deleteRow = (row, index, e) => {
    let rows = [...this.state.serviceExpenseCollection];
    rows.splice(index, 1);
    this.setState(
      {
        serviceExpenseCollection: rows,
        isVisible:
          rows[0].itemName && rows[0].itemPrice && rows[0].itemQty
            ? false
            : true,
      },
      () => {
        this.state.serviceExpenseCollection.map((row, index) => {
          this.handleChart2("itemQty", row["itemQty"], index);
          this.handleChart2("itemPrice", row["itemPrice"], index);
          this.handleChart2("itemName", row["itemName"], index);
          this.handleChart2("selectedSubTypes", row["selectedSubTypes"], index);
          return null;
        });
      }
    );
  };

  handleChart2 = (name, value, index) => {
    let rows = [...this.state.serviceExpenseCollection];
    let keys = [];
    rows[index][name] = value;
    rows.map((i) => {
      keys.push(i.x);
      return null;
    });
    let sorted_arr = keys.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    let a = [];
    keys.map((item, index) => {
      results.map((w) => {
        if (item === w) {
          a.push(index);
        }
        return null;
      });
      return null;
    });
    rows.map((sal) => {
      sal.e = false;
      return null;
    });
    a.map((val) => {
      rows[val] = { ...rows[val], e: true };
      return null;
    });
    this.setState({
      serviceExpenseCollection: rows,
      error: a.length > 0 ? true : false,
    });
  };

  addMore = () => {
    let rows = [];
    rows = [...this.state.serviceExpenseCollection];

    if (rows.length) {
      let last = { ...rows[rows.length - 1] };
      rows.push({
        itemName: "",
        itemQty: "1",
        itemPrice: "1",
        categoryName: "",
        valid: true,
        validItem: true,
        selectedSubTypes: [],
      });
    } else {
      let last = { ...rows[rows.length - 1] };
      rows.push(last);
    }
    this.setState({ serviceExpenseCollection: rows });
  };

  addMoreLeaseItems = () => {
    let rows = [...this.state.leasingItems];
    if (rows.length) {
      let last = { ...rows[rows.length - 1] };
      rows.push({
        vehicleId: "",
        saleItemPrice: "",
        noOfDays: "1",
        brandingCost: "",
        telematicsCost: "",
        insuranceCost: "",
        bankInterest: "",
        zakatInterest: "",
        depreciationCost: "",
        maintenanceCost: "",
      });
    } else {
      let last = { ...rows[rows.length - 1] };
      rows.push(last);
    }
    this.setState({ leasingItems: rows });
  };



  checkRequiredFields = () => {
    const { serviceDate } = this.state.invoiceForm;
    if (serviceDate && this.state.serviceExpenseCollection.length) {
      this.setState({ isVisableSubmitBtn: true });
    }
  };
  checkRequiredFields1 = () => {
    const {vehicleId, brandingCost, noOfDays,saleItemPrice,telematicsCost,insuranceCost,bankInterest,zakatInterest,depreciationCost,maintenanceCost } = this.state.leaseForm;

    // const { serviceDate } = this.state.invoiceForm;
    if (vehicleId && brandingCost || noOfDays||saleItemPrice||telematicsCost||insuranceCost||bankInterest||zakatInterest||depreciationCost||maintenanceCost) {
      this.setState({ isVisableSubmitBtn: true });
    }
  };


  submitForm = () => {
    let items = [];
    this.state.serviceExpenseCollection.map((item) => {
      let makePackage = {
        categoryName: item?.categoryName?.label || "",
        itemCode: item?.itemName?.label || "",
        itemName: item?.itemName?.label || "",
        itemPrice: item.itemPrice,
        itemQty: item.itemQty,
      };
      items.push(makePackage);
    });

    let obj = {
      ...this.state.invoiceForm,
      id: this.state.invoiceForm?.id || 0,
      // contractNumber: this.props?.selectedAccount?.contractNumber || "",
      contractNumber:  this.state.invoiceForm?.contractNumber || this.props?.selectedAccount?.contractNumber,
      garageId: this.state.invoiceForm?.garageId?.id || "",
      vehicleId: this.state.invoiceForm?.vehicleId?.id || "",
      serviceId: this.state.invoiceForm?.serviceId?.id || "",
      items: items,
    };
    // console.log("obj====", this.state.invoiceForm);
    // console.log("obj====",obj);
    // console.log("logInUser====", this.props.logInUser);
    // let url = this.state.mode ==='create' ? `/api/expensetypes/subtype/`: `/api/expensetypes/subtype/${this.state.serviceForm.id}`
    let url =
      this.state.mode !== "update"
        ? `/api/invoices`
        : `/api/invoices/${this.state.invoiceForm.id}`;
    fetch(url, {
      method: this.state.mode === "update" ? "PUT" : "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...obj,
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.onInvoiceCreate();

            toast.success(
                this.state.mode === "update"
                  ? this.props.translate("invoiceUpdated")
                  : this.props.translate("invoiceCreated")
          );
        }
      })
      .catch((error) => {
        console.log("error ==", error);
      });
  };

  submitLeaseForm = () => {
    let allItems = [];
    this.state && this.state.leasingItems.map((item) => {
// console.log("item====", item);
      let makeItems = {
        ...item,
        vehicleId: item.vehicleId.id,
      };
      allItems.push(makeItems);
    });
    let obj = {
      ...this.state.leaseForm,
      items: allItems,
    };
    let url =
      this.state.mode !== "update"
        ? `api/leaseinvoices`
        : `api/leaseinvoices/${this.state.leaseForm.id}`;
    fetch(url, {
      method: this.state.mode === "update" ? "PUT" : "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...obj,
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.onInvoiceCreate();
         
            toast.success(
                this.state.mode === "update"
                  ? this.props.translate("invoiceUpdated")
                  : this.props.translate("invoiceCreated")
          );
        }
      })
      .catch((err) => {
     
          toast.error( this.props.translate(err)
        );
        console.log("err====", err);
      });
  };

  closeEditForm = () => {
    this.setState({
      isVisableSubmitBtn:false,
      invoiceForm: {},
      createSubType: false,
      createLeasing: false,
      //  cashCustomer:false
      
    });
  };

  openCreateSubTypeFrom = () => {
    this.setState({
      mode: "create",
      createSubType: true,
      invoiceForm: {},
      cashForm:"",
      serviceExpenseCollection: [
        {
          itemQty: "1",
          itemPrice: "1",
          itemName: "",
          categoryName: "",
          selectedSubTypes: [],
        },
      ],
    });
  };

  async fetchGarageEdit(id) {
    return await instance({
      method: "GET",
      url: `/api/garages/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let defaultOptions = {
          id: response.id,
          value: response.id,
          label: response.name,
          name: response.name,
          uniqueId: response.id,
        };

        return defaultOptions;
      })
      .catch((error) => {
        console.log("error garage ===", error);
      });
  }

  async fetchServiceEdit(id) {
    return await instance({
      method: "GET",
      url: `/api/services/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let defaultOptions = {
          id: response.id,
          value: response.id,
          label: response.name,
          name: response.name,
          uniqueId: response.id,
        };
   

        return defaultOptions;
      })
      .catch((error) => {
        console.log("error service ==", error);
      });
  }

  async fetchVehicleEdit(id) {
    return await instance({
      method: "GET",
      url: `/api/vehicles/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let defaultOptions = {
          id: response.id,
          value: response.id,
          label: response.label,
          name: response.label,
          uniqueId: response.id,
        };
        return defaultOptions;
      })
      .catch((error) => {
        console.log("error vehicles==", error);
      });
  }



  handleChangeLease = (name, value, index) => {
  };

  handleChangeLeasingItems = (name, value, index) => {
    let rows = [...this.state.leasingItems];
    rows[index][name] = value;
    this.setState({ leasingItems: rows }, () => {
      this.checkRequiredFields1();
    });
  };

  onDelete = (item) => {
    this.setState({
      onDeleteConfirmation: true,
      invoiceForm: item[0],
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  removeSubType = () => {
  
    let url ;
    if(this.props.value === "cashCustomer"){
      url= `/api/cashinvoices/${this.state.invoiceForm.id}`
    }
    else{
      url= `/api/invoices/${this.state.invoiceForm.id}`
    }
    if (this.state.invoiceForm && this.state.invoiceForm.id) {
      fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            this.setState({ onDeleteConfirmation: false },  () => {
              this.props.fetchInvoices(this.props.selectedAccount.contractNumber);
            });
            
              toast.success( this.props.translate("invoiceDeleted")
            );
          }
        })
        .catch((er) => {
          console.log("err ===", er);
       
            toast.error( this.props.translate("invoiceDeleted")
          );
        });
    }
  };

  onInvoiceCreate = () => {

    this.setState(
      {
        createSubType: false,
        invoiceForm: {},
        leaseForm: {},
        cashForm:{},
        leasingItems: [],
        createLeasing: false,
      },
      () => {
        this.props.fetchInvoices(this.props.selectedAccount.contractNumber);
      }
    );
  };

  downloadInvoice = (item, type) => {
    let Accept = type
      ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      : "application/pdf";
    let api = this.props.leasing
      ? `/api/leaseinvoices/export/${item[0].id}`
      : `/api/deliverynotes/export/${item[0].id}`;
    axios({
      method: "GET",
      url: api,
      headers: {
        Accept: Accept,
      },
      responseType: "blob",
    })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        if (!type) {
          a.setAttribute("download", `deliverynotes.pdf`);
        } else {
          a.setAttribute("download", `deliverynotes.xlsx`);
        }
        document.body.appendChild(a);
        a.href = url;
        a.click();
      })
      .catch((error) => {
        console.log("er =", error);
       
          toast.error( this.props.translate("somethingWentWrong")
        );
      });
  };

  regenerateInvoice = (item, type) => {
    
    let api;
    let invoiceType ;
    // if(this.props.leasing){
    //   api = `api/leaseinvoices/${item[0].id}/recompute`
    // }else{
      api = `/api/deliverynotes/${item[0].id}/recompute`
    // }
    // if(item[0].)
    // let api = ;
    axios({
      method: "GET",
      url: api,
    })
      .then((response) => {
        if (response?.data.status === "success"  ) {
   
            toast.success(this.props.translate("deliveryNoteRegeneatedSuccessFul")
          );
          this.props.getInvoices();
        }
        else{
        // console.log("res111111111111===", response);

  
            toast.error( this.props.translate(response?.data?.message)
          );
        }
      })
      .catch((error) => {
        console.log("er =", error);
      
          toast.error( this.props.translate("somethingWentWrong")
        );
      });
  };

  downloadInvoiceExcel = (item) => {
    let Accept =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    let api = this.props.leasing
      ? `/api/leaseinvoices/export/${item[0].id}`
      : `/api/invoices/export/${item[0].id}`;
    axios({
      method: "GET",
      url: api,
      headers: {
        Accept: Accept,
      },
      responseType: "blob",
    })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        a.setAttribute("download", `invoice.pdf`);
        document.body.appendChild(a);
        a.href = url;
        a.click();
      })
      .catch((error) => {
        console.log("er =", error);
     
          toast.error( this.props.translate("somethingWentWrong")
        );
      });
  };

  handleChangeTabs = (value) => {
    this.setState({ value });
  };





  checkRequiredFields2 = () => {
    const {refNum, contractNumber, invoiceDate, saleNetAmount,saleVatAmount, saleTotalAmount } = this.state.cashForm;
    if (refNum && contractNumber && invoiceDate&& saleNetAmount&&saleVatAmount&& saleTotalAmount ) {
      this.setState({ isVisableSubmitBtn: true });
    }else{
      this.setState({ isVisableSubmitBtn: false });
    }
  };
handleChangeForAccount = async (name, event, link) => {
  this.setState({
    [name]: event,
    cashForm:{
      ...this.state.cashForm,
      contractNumber:event,
      // createdFor:event
    }, 
  }, ()=>this.checkRequiredFields2() 
  );
};

render() {
    return (
      <Grid>
          <>
            <Grid
              container
              className="breadcrumb-row"
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid item xs={12} sm={3}>
                <h3 style={{ margin: 0 }}>
                  {this.props.translate("rejectedDeliveryNotes")}
                </h3>
              </Grid>
              <Grid item xs={12} sm={9} style={{ justifyContent: "flex-end" }}>
              <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("rejectedReason")}
                    value={this.props.rejectedReason}
                    onChange={(e) => this.props.searchInvoices(e, "rejectedReason")}
                  />
                </div>
            
                <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("plateNumber")}
                    value={this.props.plateNumber}
                    onChange={(e) => this.props.searchInvoices(e, "plateNumber")}
                  />
                </div>
                <div style={{ width: "25%", marginRight: 20 }}>
                <TextField
                    fullWidth
                    label={this.props.translate("searchdeliveryNoteRefNumber")}
                    value={this.props.deliveryNoteRefNumber}
                    onChange={(e) => this.props.searchInvoices(e, "deliveryNoteRefNumber")}
                  />
                </div>
                <Button
                    aria-label="search"
                    size="small"
                    color="inherit"
                    onClick={this.props.getInvoices}
                    id="search"
                  >
                    {this.props.translate("search")}
                  </Button> 

                <Button
                  aria-label="Back"
                  size="small"
                  color="inherit"
                  onClick={this.props.goBack}
                  id="back"
                  style={{ margin: 10 }}
                >
                  {this.props.translate("sharedBack")}
                </Button>
              </Grid>
            </Grid>

            <Grid>
      {this.props.loader   ?  <Loader/> :
              <Table
                rows={(this.props.rejectedDeliveryNoteList &&this.props.rejectedDeliveryNoteList?.data) || []}
                pagination={this.props.setRejectedPagination()}
                handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                // rowsPerPage={15}
                handleChangePage={this.props.handleChangePage}
                isEditable
                canUpdate
                canDelete
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                themecolors={this.props.themecolors}
                serverTimezone={this.props.logInUser?.attributes?.timezone || "Asia/Riyadh" }
                timezone ={ this.props?.logInUser?.attributes?.timezone || 'Asia/Riyadh'}
                hasAccessOfUpdate={
                    this.props.value === "cashCustomer"
                    ?
                    checkPrivileges("cashinvoiceUpdate"):""
                }
                contractNumber={
                  this.props?.selectedAccount?.contractNumber || ""
                }
                invoiceType= {this.props.value}
                downloadInvoice={this.downloadInvoice}
                regenerateInvoice={this.regenerateInvoice}
                leasing={this.props.leasing}
                invoiceRow
                isSelectedDubious={this.props.isSelectedDubious}
                rowDefinition={[
                        {
                          id: "deliveryNoteRefNumber",
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate("deliveryNoteRefNumber"),
                        },
                        {
                          id: "contractNumber",
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate("contractNumber"),
                        },
                        {
                          id: "plateNumber",
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate("vehicleLicensePlate"),
                        },
                        {
                          id: "serviceRefDate",
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate("serviceDate"),
                        },
                        {
                          id: "creationMode",
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate("creationMode"),
                          hide: checkPrivileges("invoiceInvalidView")
                            ? false
                            : true,
                        },
                        {
                          id: "reason",
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate("rejectedReason"),
                        },
                      ]
                }
              />
              }
            </Grid>
          </>
     
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeSubType}
            title={this.props.translate("areYouWantToDelete")}
            children={this.props.value === "cashCustomer" ? this.state.invoiceForm?.contractNumber : this.state.invoiceForm?.name || ""}
          />
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themecolors,
});

export default connect(mapStateToProps)((withTranslationWrapper(RejectedDeliveryNote)))

import React, { useEffect, useState } from 'react';
import TextField from '../../../common/TextField';
import { Grid, IconButton, Box } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import Button from '../../../../Components/common/Button';
import { updateAccount } from '../../../../Actions/Accounts';
import { updateUser } from '../../../../Actions/Users';
import { toast } from 'react-toastify';
import { ServerInfo } from '../../../../Actions/serverSetting';

const AdditionalEmails = (props) => {
    const [accountEmails, setAccountEmails] = useState([]);
    const [userEmails, setUserEmails] = useState([]);

    useEffect(() => {
        if (props.isUser && props.selectedUser) {
            const updatedUserEmails = props.selectedUser?.additionalEmail
                ? props.selectedUser.additionalEmail.split(',')
                : [];
            setUserEmails(updatedUserEmails);
        }

        if (props.selectedAccount) {
            const updatedAccountEmails = props.selectedAccount?.additionalEmail
                ? props.selectedAccount.additionalEmail.split(',')
                : [];
            setAccountEmails(updatedAccountEmails);
        }
    }, [props.isUser, props.selectedUser, props.selectedAccount]);

    const handleSubmitForm = async () => {
        const userPayload = props.isUser
            ? {
                ...props.selectedUser,
                additionalEmail: userEmails.join(','),
            }
            : null;

        const accountPayload = {
            ...props.selectedAccount,
            additionalEmail: accountEmails.join(','),
        };

        const userApiUrl = `api/users/${props.selectedUser?.id}`;
        const accountApiUrl = `api/accounts/${props.selectedAccount?.id}`;

        try {
            if (props.isUser && userPayload) {
                const userResponse = await fetch(userApiUrl, {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userPayload),
                });

                if (!userResponse.ok) throw new Error('User API call failed');

                const userResult = await userResponse.json();
                if (userResult.status === 'success') {
                    toast.success(props.translate('User email successfully updated'));
                    props.UpdateSelectedItem(userResult.data);
                    props.dispatch(updateUser(userResult.data));
                }
            }

            // Make the account API call
            const accountResponse = await fetch(accountApiUrl, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(accountPayload),
            });

            if (!accountResponse.ok) throw new Error('Account API call failed');

            const accountResult = await accountResponse.json();
            if (accountResult.status === 'success') {
                toast.success(props.translate('Account email successfully updated'));
                props.updateSelectedAccount(accountResult.data);
                props.dispatch(updateAccount(accountResult.data));

                if (props.selectedAccount.id === 1) {
                    props.dispatch(ServerInfo(accountResult.data));
                }
            }
        } catch (error) {
            toast.error(props.translate('somethingWentWrong'));
        }
    };

    const handleUserEmailChange = (index) => (event) => {
        const newEmails = [...userEmails];
        newEmails[index] = event.target.value;
        setUserEmails(newEmails);
    };

    const handleAccountEmailChange = (index) => (event) => {
        const newEmails = [...accountEmails];
        newEmails[index] = event.target.value;
        setAccountEmails(newEmails);
    };

    const addUserEmailField = () => {
        setUserEmails([...userEmails, '']);
    };

    const addAccountEmailField = () => {
        setAccountEmails([...accountEmails, '']);
    };

    const removeUserEmailField = (index) => () => {
        const newEmails = userEmails.filter((_, i) => i !== index);
        setUserEmails(newEmails);
    };

    const removeAccountEmailField = (index) => () => {
        const newEmails = accountEmails.filter((_, i) => i !== index);
        setAccountEmails(newEmails);
    };

    return (
        <div>
            <h4>
                {props.translate(
                    `${props.isUser ? 'Manage User and Account Additional Emails' : 'Manage Account Additional Emails'}`
                )}
            </h4>
            <h5 style={{margin:"1rem 0rem"}}>{props.translate('Account Additional Emails')}</h5>

            <Grid container spacing={2}>
                {accountEmails?.map((email, index) => (
                    <Grid item md={4} key={`account-${index}`}>
                        <Box display="flex">
                            <TextField
                                id={`account-emails-${index}`}
                                label={`${props.translate('Enter Account Email')} #${index + 1}`}
                                type="text"
                                value={email || ''}
                                onChange={handleAccountEmailChange(index)}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            />
                            <IconButton
                                aria-label="delete"
                                onClick={removeAccountEmailField(index)}
                                size="small"
                                color="error"
                            // sx={{ ml: 1 }}
                            >
                                <Delete />
                            </IconButton>
                        </Box>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        onClick={addAccountEmailField}
                        color="primary"
                    >
                        {props.translate('Add Account Email')}
                    </Button>
                </Grid>

            </Grid>

            {props.isUser && (
                <>
                    <h5 style={{margin:"1rem 0rem"}}>{props.translate('User Additional Emails')}</h5>
                    <Grid container spacing={2}>
                        {userEmails.map((email, index) => (
                            <Grid item md={4} sm={6} xs={12} key={`user-${index}`}>
                                <Box display="flex" alignItems="center">
                                    <TextField
                                        id={`user-emails-${index}`}
                                        label={`${props.translate('Enter User Email')} #${index + 1}`}
                                        type="text"
                                        value={email || ''}
                                        onChange={handleUserEmailChange(index)}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                    <IconButton
                                        aria-label="delete"
                                        onClick={removeUserEmailField(index)}
                                        size="small"
                                        color="error"
                                        sx={{ ml: 1 }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Box>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={addUserEmailField}
                                color="primary"
                            >
                                {props.translate('Add User Email')}
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}

            <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitForm}
                >
                    {props.translate('Submit')}
                </Button>
            </Box>

        </div>
    );
};

export default AdditionalEmails;

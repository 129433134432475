import React, { Component } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '../../../common/TextField'
import { withStyles } from '@mui/styles'
import Button from '../../../common/Button'
import Grid from '@mui/material/Grid'
import { FormHelperText, InputAdornment } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
const ValidationIcon = ({ isValid, message }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {isValid ? (
      <CheckCircleIcon style={{ color: 'green', fontSize: 12 }} />
    ) : (
      <CancelIcon style={{ color: 'red', fontSize: 12 }} />
    )}
    <span style={{ marginLeft: '5px',color:"gray" }}>{message}</span>
  </div>
);
const PasswordRequirements = ({
  hasLengthValid,
  hasNumberValid,
  hasUpperCaseValid,
  hasLowerCaseValid,
  hasSpecialCharValid,
  translate,
}) => (
  <FormHelperText style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
    <ValidationIcon
      isValid={hasLengthValid}
      message={translate('Length must be at least 8 characters')}
    />
    <ValidationIcon
      isValid={hasNumberValid}
      message={translate('Must contain at least one Number')}
    />
    <ValidationIcon
      isValid={hasUpperCaseValid}
      message={translate('Must contain at least one Uppercase letter')}
    />
    <ValidationIcon
      isValid={hasLowerCaseValid}
      message={translate('Must contain at least one Lowercase letter')}
    />
    <ValidationIcon
      isValid={hasSpecialCharValid}
      message={translate('Must contain at least one Special character (!@#$%^&*(),.?:{}|<></>)')}
    />
  </FormHelperText>
);
class changedPasword extends Component {
  constructor () {
    super()
    this.state = {
      nPawsErrorMgs: false,
      hasLengthValid:false,
      isConfirmPassChk:false,
      hasNumberValid:false,
      hasUpperCaseValid:false,
      hasLowerCaseValid:false,
      hasSpecialCharValid:false,
      form: {
        newPassword: '',
        reapetNewPassword: ''
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  formReset () {
    this.setState({
      nPawsErrorMgs: false,
      hasLengthValid:false,
      hasLowerCaseValid:false,
      hasUpperCaseValid:false,
      hasNumberValid:false,
      hasSpecialCharValid:false,
      isConfirmPassChk:false,
      isValidPassword:false,
      form: {
        newPassword: '',
        reapetNewPassword: ''
      }
    })
  }
  formSubmit () {
    const { selectItemId } = this.props
    let { newPassword, reapetNewPassword } = this.state.form
    if (newPassword.trim().length > 0 && reapetNewPassword.trim().length > 0) {
      if (newPassword === reapetNewPassword) {
        this.setState({
          nPawsErrorMgs: false
        })
      } else {
        this.setState({
          nPawsErrorMgs: true
        })
      }
    } else {
      this.setState({
        nPawsErrorMgs: true
      })
    }
    if (
      newPassword.trim().length > 0 &&
      reapetNewPassword.trim().length > 0 &&
      newPassword === reapetNewPassword
    ) {
      fetch(`api/users/${this.props.logInUser.id}/resetpassword`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': ' application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(
          `entityId=${selectItemId.id}&npass=${this.state.form.newPassword}`
        )
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
            if(res.status ==='success'){  
              
                  toast.success(
                     this.props.translate('passwordIsChanged')
                )
                this.setState({
                  nPawsErrorMgs: false,
                  hasLengthValid:false,
                  hasLowerCaseValid:false,
                  hasUpperCaseValid:false,
                  hasNumberValid:false,
                  hasSpecialCharValid:false,
                  isConfirmPassChk:false,
                  isValidPassword:false,
                  form: {
                    newPassword: '',
                    reapetNewPassword: ''
                  }
                })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
              
                  toast.error(
                this.props.translate(err[1])
                )
              }
            })
          } 
          else {
            throw response
          }
        })
        .catch(e => {
         
            toast.error(
              this.props.translate('somethingWentWrong')
          )
        })
    } else {
      
        toast.error(
          this.props.translate('emptyField')
      )
    }
  }

  handleChange = name => event => {
    const { target } = event

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: target.value
        }
      },
      () => this.checkRequiredFields()
    )
  }

  checkRequiredFields () {
    const { newPassword, reapetNewPassword } = this.state.form;
    const minLength = 8;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasLengthValid = newPassword?.length >= minLength;
    const hasLowerCaseValid = hasLowerCase.test(newPassword);
    const hasUpperCaseValid = hasUpperCase.test(newPassword);
    const hasNumberValid = hasNumber.test(newPassword);
    const hasSpecialCharValid = hasSpecialChar.test(newPassword);
    const isPasswordMatch = newPassword === reapetNewPassword;
 
    const isValidPassword =
    hasLengthValid &&
    hasLowerCaseValid &&
    hasUpperCaseValid &&
    hasNumberValid &&
    hasSpecialCharValid;

  this.setState(
    {
      hasLengthValid,
      hasLowerCaseValid,
      hasUpperCaseValid,
      hasNumberValid,
      hasSpecialCharValid,
      isConfirmPassChk: isPasswordMatch,
      isValidPassword,
    })
  }
  handleTogglePassword = () => {
    this.setState({
      showPassword:!this.state.showPassword
    })
};
handleToggleConfirmPassword = () => {
  this.setState({
    showConfirmPassword:!this.state.showConfirmPassword
  })
};

  render () {
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='newPassword'
              label={this.props.translate('newPassword')}
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder=''
              error={this.state.nPawsErrorMgs}
              value={this.state.form.newPassword || ''}
              onChange={this.handleChange('newPassword')}
              variant='outlined'
              margin='dense'
              fullWidth
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <div
                            style={{ cursor: 'pointer',marginTop:"3px" }}
                            onClick={this.handleTogglePassword}
                        >
                            {this.state.showPassword ? (
                                <VisibilityOffOutlinedIcon style={{ color:this.props.themecolors?.menuActiveBackground }} />
                            ) : (
                                <VisibilityOutlinedIcon style={{ color:this.props.themecolors?.menuActiveBackground}} />
                            )}
                        </div>
                    </InputAdornment>
                ),
            }}
            />
                                              <PasswordRequirements
          hasLengthValid={this.state.hasLengthValid}
          hasNumberValid={this.state.hasNumberValid}
          hasUpperCaseValid={this.state.hasUpperCaseValid}
          hasLowerCaseValid={this.state.hasLowerCaseValid}
          hasSpecialCharValid={this.state.hasSpecialCharValid}
          translate={this.props.translate}
        />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='reapetNewPassword'
              error={!this.state.isConfirmPassChk}
              label={this.props.translate('reNewPassword')}
              type={this.state.showConfirmPassword ? 'text' : 'password'}
              placeholder=''
              value={this.state.form.reapetNewPassword || ''}
              onChange={this.handleChange('reapetNewPassword')}
              variant='outlined'
              margin='dense'
              fullWidth
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <div
                            style={{ cursor: 'pointer',marginTop:"3px" }}
                            onClick={this.handleToggleConfirmPassword}
                        >
                            {this.state.showConfirmPassword ? (
                                <VisibilityOffOutlinedIcon style={{ color:this.props.themecolors?.menuActiveBackground }} />
                            ) : (
                                <VisibilityOutlinedIcon style={{ color:this.props.themecolors?.menuActiveBackground}} />
                            )}
                        </div>
                    </InputAdornment>
                ),
            }}
            />
                                   <FormHelperText style={{ color: this.props.themecolors.textColor }}>
           <ValidationIcon
             isValid={this.state.isConfirmPassChk}
             message={this.props.translate('Both Passwords must be Equal')}
           />
         </FormHelperText>
          </Grid>
        </Grid>

        <br />
        <Button variant='contained' onClick={() => this.formReset()}>
          {' '}
          {this.props.translate('resetButton')}
        </Button>
        <Button
          variant='contained'
          style={{ marginLeft: 8 }}
          disabled={
            !(this.state.form.newPassword && this.state.form.reapetNewPassword) || 
            !this.state.isValidPassword || 
            !this.state.isConfirmPassChk
          }
          onClick={() => this.formSubmit()}
        >
          {this.props.translate('changePassword')}
        </Button>
      </div>
    )
  }
}
const ChangedPasword = withStyles(styles)
export default ChangedPasword(changedPasword)

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../common/Button'
import SingleSelect from '../../common/SingleSelect'
import instance from '../../../axios'
import { errorHandler } from '../../../Helpers'


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class ChangesOwnerShipe extends Component {
  constructor () {
    super()
    this.state = {
      isVisableAddBtn: false,
      resultMessage: '',
      defaultOptions: [],
      value: []
    }
    this.formSubmit = this.formSubmit.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  componentWillMount () {
    // this.getAllAccounts()
    this.fetchSelectedUser()
   
   
  }
  // getParent = async ()=>{
  //   const user =  await this.fetchSelectedUser(this.props.selectedVehicle.parentId)

  //   this.setState({ value: user })
  // }
  async fetchSelectedUser  (id) {
    return await instance({
       method: 'GET',
       url: `/api/users/${this.props.selectedVehicle.parentId}`,
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json'
       }
     })
       .then(response => {
        this.setState({ value: response })
       })
       .catch(error => {
         errorHandler(error, this.props.dispatch)
       })
   }
  getAllAccounts = () =>{
    fetch(`/api/accounts/get?userId=${this.props.logInUser.id}&all=true&&limit=-1`, {

      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
        response.json().then(res => {
          if(res.status ==='success'){
            let data = res.data
            let result = data.data.map(item => ({
              ...item,
              label: item.label || item.name,
              value: item.id
            }))
            if (result.length === 0) {
              this.setState({
                resultMessage: 'No option found',
                defaultOptions: ''
              })
            } else {
              this.setState({
                resultMessage: '',
                defaultOptions: result
              })
            }
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
           
              toast.error(
               this.props.translate(err[1])
            )
          }
          else {
            throw response
          }
        })
     
    }).catch(e => {
      console.log('e =', e)
      //   this.props.dispatch(toast.error({
      //   message: 'somethingWentWrong',
      //   autoDismiss: 5
      // }))
    })
  }

  formSubmit () {
    this.setState({
      isVisableAddBtn: false
    })
    let { selectedDevice } = this.props
    let { value } = this.state
    let obj = {
      newParentId: value.value,
      vehicleId: [this.props.selectedVehicle.id],
      // oldParentId: selectedDevice.parentId
    }
   
    let apiCall = '/api/vehicles/migrate/'
    fetch(`${apiCall}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
          response.json().then(data => {
            if (data.status === 'success') {
             
                toast.success(
                 this.props.translate('unitOwnerShipUpdated')
              )
              this.setState({
                isVisableAddBtn: false
              })
               this.props.getVehivlesList()
            } 
            else if(data?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(data?.statusCode){
              var err = data?.message.split(':')
              err[1] =err[1].replace(')', "")
            
                toast.error(
                this.props.translate(err[1])
              )
            }
            else {
              throw response
            }
          })
          this.setState({
            isVisableAddBtn: false
          })
       
      })
      .catch(e => {
        this.setState({
          isVisableAddBtn: true
        })
          toast.error(
            this.props.translate('somthingWentWrongMessage')
        )
      })
  }

  checkRequiredFields () {
    if (this.state.value) {
      this.setState({
        isVisableAddBtn: true
      })
    } else {
      this.setState({
        isVisableAddBtn: false
      })
    }
  }

  onChange = (name, value) => {
   
    this.setState({ value: value }, () => this.checkRequiredFields())
  }
  searchList = async inputValue => {
    if (!inputValue) {
      return []
    }

    let response = await fetch(
      `/api/users/list?userId=${this.props.logInUser.id}&search=${inputValue}&limit=100&all=true`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    let json = await response.json()
    let result =
      (await json?.data?.data) &&
      json?.data?.data.map(item => ({
      
        ...item,
        label: item.label || item.name,
        value: item.id
      }))
    if (result.length === 0) {
      this.setState({
        resultMessage: 'No option found'
      })
    } else {
      this.setState({
        resultMessage: ''
      })
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: 'Search for more.'
      })
    }
    return result
  }
  render () {
   
    let { value, defaultOptions } = this.state
    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} sm={6}>
              <SingleSelect
                    array={this.props.defaultAccountsList || []}
                    async
                    selectName='users'
                    isClearable={false}
                    // defaultOptions={defaultOptions}
                    // loadOptions={this.searchList}
                    label={this.props.translate('users')}
                    value={
                      value && value
                        ? {
                            key: value.key || '',
                            id: value.id || '',
                            label: value.label ||value.name 
                          }
                        : ""
                    }
                    handleChange={this.onChange}
                    canAssign
                  />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Button
              variant='contained'
              disabled={!this.state.isVisableAddBtn}
              onClick={() => this.formSubmit()}
            >
              {' '}
              {this.props.translate('sharedSave')}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }
}
const enhance = compose(withStyles(styles), connect())

export default enhance(ChangesOwnerShipe)

import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RestoreIcon from "@mui/icons-material/Restore";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LinkIcon from "@mui/icons-material/Link";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReplayIcon from "@mui/icons-material/Replay";
import SimCardDownload from "@mui/icons-material/GetApp";
import EditIcon from "@mui/icons-material/Edit";
import ScheduleIcon from '@mui/icons-material/Schedule';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { alpha } from "@mui/system";
import LaunchIcon from '@mui/icons-material/Launch';

// import { alpha } from '@mui/styles';
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
//import isEqual from 'react-fast-compare';
import SearchDevices from "../Devices/searchItems";
import MenuItem from "./MenuItem";
import styled from "styled-components";
import TextField from "./TextField";
import Select from "./../common/Select";
import Avatar from "@mui/material/Avatar";
import NextPageIcon from "@mui/icons-material/NavigateNext";
import PrevPageIcon from "@mui/icons-material/NavigateBefore";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import Grid from "@mui/material/Grid";
import { userLogInTypes } from "./userConstant";
import "react-toastify/dist/ReactToastify.css";
import Menu from "./Menu";
import Checkbox from "@mui/material/Checkbox";
// import htmlParser from "react-html-parser";
import parse from "html-react-parser";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import ContactsIcon from "@mui/icons-material/Contacts";
import moment from "moment";
import ForwardIcon from "@mui/icons-material/Forward";
import { checkPrivileges } from "../../Helpers";
import withTranslationWrapper from "../../HOC/HocTranslate";

let serverTimeZoneName = "";
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, classes, isEditable, isRowIcons, translate } =
      this.props;
    serverTimeZoneName = this.props?.timezone || "Asia/Riyadh";

    return (
      <TableHead>
        <TableRow className={classes.tableRow}>
          {this.props.setSerialNo && <TableCell> #</TableCell>}
          {this.props.setMultiSelect && (
            <TableCell style={{ padding: "5px" }}>
              <Checkbox
                name="checkboxes"
                color="primary"
                checked={this.props.hasToCheckAll}
                onChange={this.props.handleAllCheckboxes}
              />
            </TableCell>
          )}

          {this.props.rowDefinition.map(
            (row) =>
              row && (
                <TableCell
                  key={row.id}
                  align={row.numeric ? "right" : "left"}
                  padding={row.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === row.id ? order : false}
                  style={{
                    padding: "0 10px",
                    whiteSpace: "nowrap",
                    maxWidth: 300,
                    color: "inherit",
                  }}
                >
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              ),
            this
          )}
          {isRowIcons && (
            <TableCell
              key={"statistics"}
              width={60}
              style={{ padding: "0 10px" }}
            >
              {" "}
              {this.props.translate("statistics")}
            </TableCell>
          )}
          {isEditable && (
            <TableCell
              key={"editable"}
              width={60}
              style={{ padding: "0 10px" }}
            />
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    minHeight: "44px",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          backgroundColor: alpha(theme.palette.secondary.light, 0.85),
        }
      : {
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 auto",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    fontFamily: "inherit",
    flex: "0 0 auto",
    fontSize: 18,
    fontWeight: 500,
  },
});

let EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    className,
    themecolors,
    column,
    selected,
    actionInHead,
    style,
  } = props;
  const ThemeIconButton = styled(IconButton)`
    background-color: ${(props) => themecolors["500"]};
    border-radius: 0;
    color: ${(props) => themecolors["themeInverse"]};
    &:hover {
      cursor: pointer;
      background-color: ${(props) => themecolors["600"]};
    }
  `;
  return (
    <Toolbar
      className={
        classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        }) +
        " " +
        className
      }
      style={style}
    >
      <div>
        {
          <Typography className={classes.title} id="tableTitle" color="inherit">
            {props.title}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      {props.btnView &&
        (props.viewButton ? (
          <Tooltip title="View">
            <Button
              aria-label="View"
              size="small"
              variant="outlined"
              color="inherit"
              onClick={props.onBinaryView}
              style={{ marginRight: "20px" }}
            >
              {props.btnView}
            </Button>
          </Tooltip>
        ) : (
          <Button
            aria-label="View"
            size="small"
            variant="outlined"
            color="inherit"
            onClick={props.onBinaryView}
            style={{ marginRight: "20px" }}
          >
            {props.btnView}
          </Button>
        ))}
      {/* {props.btnName1 && (
        <Tooltip title='Export'>
          <Button
            disabled={!props.exportButton}
            aria-label='Export'
            size='small'
            variant='outlined'
            color='inherit'
            onClick={props.onExport}
            style={{ marginRight: '20px' }}
          >
            {props.btnName1}
          </Button>
        </Tooltip>
      )} */}
      {props.btnName1 &&
        !props.restore &&
        (props.btnName1 === "accounts" ? (
          <Tooltip title="Export">
            <Button
              disabled={!props.exportButton}
              aria-label="Export"
              size="small"
              variant="outlined"
              color="inherit"
              onClick={props.onExport}
              style={{ marginRight: "20px" }}
            >
              Export
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Export">
            <Menu title={props.btnName1} />
          </Tooltip>
        ))}
      {props.importButton && (
        <Tooltip title="Import">
          <Button
            disabled={!props.importButton}
            aria-label="import"
            size="small"
            variant="outlined"
            color="inherit"
            onClick={props.onImport}
            style={{ marginRight: "20px" }}
          >
            import
          </Button>
        </Tooltip>
      )}

      {props.btnName && (
        <Tooltip title="Create">
          <Button
            disabled={!props.createButton}
            aria-label="Create"
            size="small"
            variant="outlined"
            color="inherit"
            onClick={props.onCreate}
            id="createButton"
          >
            {props.btnName}
          </Button>
        </Tooltip>
      )}
      <div className={classes.actions}>
        {actionInHead && numSelected > 0 ? (
          <Tooltip title="Delete">
            <ThemeIconButton
              aria-label="Delete"
              color="inherit"
              onClick={props.onDelete}
            >
              <DeleteIcon fontSize="small" />
            </ThemeIconButton>
          </Tooltip>
        ) : (
          ""
        )}
        {actionInHead && numSelected === 1 ? (
          <Tooltip title="Edit">
            <ThemeIconButton
              aria-label="edit"
              onClick={props.onEdit}
              color="inherit"
            >
              <EditIcon fontSize="small" />
            </ThemeIconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    paddingTop: 44,
    boxShadow: "none",
    position: "relative",
    border: `1px solid rgba(0,0,0,0.12)`,
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflow: "auto",
  },
  cell: {
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tableRow: {
    height: 36,
    "&:hover .actionButton": {
      display: "inline-block",
    },
    "& .actionButton": {
      cursor: "pointer",
      marginLeft: 5,
      display: "none",
    },
  },
  toolbar: {
    root: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "44px",
      minHeight: "inherit",
    },
    toolbar: {
      height: "44px",
      minHeight: "inherit",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
  },
});

class EnhancedTable extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.viewInvoice = this.viewInvoice.bind(this);
    this.viewPayment = this.viewPayment.bind(this);
    this.viewDeliveryNote = this.viewDeliveryNote.bind(this);
    this.downloadInvoice = this.downloadInvoice.bind(this);
    this.showStatistics = this.showStatistics.bind(this);
    this.loginAsUser = this.loginAsUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onRestore = this.onRestore.bind(this);
    this.onVehicleSendData = this.onVehicleSendData.bind(this);
    this.handleOpenShowVehicleHistory=this.handleOpenShowVehicleHistory.bind(this)
    this.onCreate = this.onCreate.bind(this);
    this.onImport = this.onImport.bind(this);
    this.drawValue = this.drawValue.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
    this.state = {
      order: "asc",
      orderBy: "calories",
      selected: [],
      data: this.props.rows,
      page: this.props.page || 0,
      rowsPerPage: this.props.pageSize || 5,
      total: this.props.total || 0,
      lastPage: this.props.lastPage || 0,
      searchValue: "",
      coupon: this.props.coupon,
      viewInvoice: false,
      viewDeliveryNote: false,
      viewPayment: false,
    };
  }

  makeSearch(event) {
    this.props.SearchItem(event.target.value);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({ selected: state.data.map((n) => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, item) => {
    if (item.hasAccess !== undefined) {
      if (item.hasAccess) {
        if (this.props.onClick) {
          let newSelected = [];
          newSelected.push(item);
          this.props.onClick(item);
          this.setState({ selected: newSelected });
        }
      }
    } else {
      if (this.props.onClick) {
        let newSelected = [];
        newSelected.push(item);
        this.props.onClick(item);
        this.setState({ selected: newSelected });
      }
    }
  };
  handleChangeContract = (event, item) => {
    this.props.changeContract(item, event);
  };
  openAccountStausDialog = (event, item) => {
    this.props.openAccountStausDialog(event, item);
  };
  openUserStatusDialog = (event, item) => {
    this.props.openUserStatusDialog(event, item);
  };
  handleChangePage = (action) => {
    let { pagination } = this.props;
    if (pagination) {
      if (action === "first") {
        if (pagination.page > 1) {
          this.props.handleChangePage(1);
        }
      } else if (action === "prev") {
        if (pagination.page && pagination.page > 1) {
          this.props.handleChangePage(pagination.page - 1);
        }
      } else if (action === "next") {
        if (
          pagination.hasNext &&
          pagination.page &&
          pagination.lastPage &&
          pagination.lastPage > pagination.page
        ) {
          this.props.handleChangePage(pagination.page + 1);
        }
      } else if (action === "last") {
        if (
          pagination.hasNext &&
          pagination.lastPage &&
          pagination.lastPage > pagination.page
        ) {
          this.props.handleChangePage(pagination.lastPage);
        }
      }
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    this.props.handleChangeRowsPerPage(event.target.value);
  };

  isSelected = (id) =>
    (this.state.selected[0] && this.state.selected[0].id === id) || false;

  onEdit(event, selected) {
    event.stopPropagation();
    this.props.onEdit(selected);
  }

  //   viewInvoice(event, selected) {
  //     let timezone = this.props?.timezone
  //     event.stopPropagation();
  //     window.open(
  //       `/invoiceDetail?&token=${selected[0].id}&leasing=${this.props.leasing}&timezone=${timezone}`,
  //       "_blank"
  //     ); //invoiceDetail
  //   }
  viewInvoice(event, selected) {
    let timezone = this.props?.timezone;
    event.stopPropagation();
    let invoiceType = this.props.leasing
      ? "invoiceDetail"
      : "maintenance-invoice-detail";

    window.open(
      `/${invoiceType}?&token=${selected[0].id}&leasing=${this.props.leasing}&timezone=${timezone}`,
      "_blank"
    );
  }
  viewDeliveryNote(event, selected) {
    let timezone = this.props?.timezone;
    event.stopPropagation();
    window.open(
      `/delivery-detail?&token=${selected[0].id}&leasing=false&timezone=${timezone}`,
      "_blank"
    ); //invoiceDetail
  }

  viewPayment(event, selected) {
    let timezone = this.props?.timezone;
    event.stopPropagation();
    window.open(
      `/payment-detail?&token=${selected[0].id}&timezone=${timezone}`,
      "_blank"
    ); //invoiceDetail
  }

  tableSelection = (event, selected, type) => {
    event.stopPropagation();
    this.props.tableSelection(selected, type);
  };

  downloadInvoice(event, selected, item, summary) {
    event.stopPropagation();
    if (item) this.props.downloadInvoice(selected, true);
    else this.props.downloadInvoice(selected, false, summary);
  }
  regenerateInvoice(event, selected, item) {
    event.stopPropagation();
    if (item) this.props.regenerateInvoice(selected, true);
    else this.props.regenerateInvoice(selected, false);
  }

  forwardToZatca = (event, selected, item) => {
    event.stopPropagation();
    if (item) this.props.forwardToZatca(selected, true);
    else this.props.forwardToZatca(selected, false);
  };

  loginAsUser(event, selected) {
    event.stopPropagation();
    this.props.loginAsUser(selected);
  }
  handleChange = (event, name) => {
    const { target } = event;
    if (target) {
      this.props.loginAsUserhandleChange(name, event.target.value);
    }
  };
  stopthandleClick = (e) => {
    e.stopPropagation();
  };
  showStatistics(event, selected) {
    event.stopPropagation();
    this.props.showStatistics(selected);
  }

  onDelete(event, selected) {
    event.stopPropagation();
    this.props.onDelete(selected);
    this.setState({ selected: [] });
  }
  onVehicleSendData(event, selected) {
    event.stopPropagation();
    this.props.onVehicleSendData(selected);
    // this.setState({ selected: [] })
  }
  handleOpenShowVehicleHistory(event,selected){
    event.stopPropagation()
    console.log("evvv",event,selected)
    this.props.handleOpenShowVehicleHistory(selected)
  }
  onRestore(event, selected) {
    event.stopPropagation();
    this.props.onRestore(selected);
    // this.setState({ selected: [] })
  }
  onChangeStatus(event, selected) {
    event.stopPropagation();
    this.props.onChangeStatus(selected);
  }

  onClose() {
    this.props.onClose();
  }

  onCreate() {
    this.props.onCreate();
  }
  onImport() {
    this.props.onImport();
  }
  drawValue(value, type, valueType, id) {
    let serverTimeZoneName = this.props?.timezone || "Asia/Riyadh";

    if (type === "any") {
      if (valueType === "boolean") {
        return value === true ? "Yes" : "No";
      } else {
        return parse(String(value))[0];
      }
    } else if (type === "boolean") {
      return value === true ? "Yes" : "No";
    } else if (type === "userType") {
      switch (value) {
        case 1:
          return "User";
        case -1:
          return "SuperAdmin";
        case 2:
          return "Admin";
        case 3:
          return "ReSeller";
        default:
          return "";
      }
    } else if (type === "symbol") {
      let ty = "";
      switch (value) {
        case -1:
          ty = "O";
          break;
        case 1:
          ty = "U";
          break;
        case 2:
          ty = "A";
          break;
        case 3:
          ty = "AA";
          break;
        case 4:
          ty = "SP";
          break;
        case 5:
          ty = "R";
          break;
        case 6:
          ty = "D";
          break;
        default:
          ty = "";
      }
      return (
        <Avatar
          className={"avatar-" + ty}
          style={{
            width: 18,
            height: 18,
            fontSize: 12,
            display: "inline-flex",
            marginLeft: "2px",
          }}
        >
          {ty}
        </Avatar>
      );
    } else {
      if (this.state.coupon) {
        return parse(String(value ? value : "0"));
      }
      if (id === "created" || id === "timeStamp") {
        let time = moment(value)
          .tz(serverTimeZoneName)
          .format("DD-MM-YYYY hh:mm");
        return time;
      } else {
        return (
          <Tooltip title={parse(String(value))}>
            {/* HTML parser replaced with parse */}
            <span>{value ? parse(String(value)) : ""}</span>
          </Tooltip>
        );
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const { rows: data = [], isCursoPointer, pagination } = this.props;
    const emptyRows = data.length < 5 ? 5 - data.length : 0;
    const today = new Date();
    const date = today.toLocaleDateString();
    const todayDate = moment(date, "MM/DD/YYYY");
    // console.log("===",this.props)
    const rowsPerPageOptionsList=this.props.vehicleHistoryTable?rowsPerPageOptionsVehicleHistory:rowsPerPageOptions
        let serverTimeZoneName = this.props?.timezone || "Asia/Riyadh";
    //     if (
    //       this.props.ServerSetting &&
    //       this.props.ServerSetting.attributes &&
    //       this.props.ServerSetting.attributes.timezone
    //     ) {
    //       serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    //     }
    //     if (
    //       this.props.logInUser &&
    //       this.props.logInUser.attributes &&
    //       this.props.logInUser.attributes.timezone
    //     ) {
    //       serverTimeZoneName = this.props.logInUser.attributes.timezone;
    //     }

    return (
      <Paper
        className={classes.root}
        style={{ paddingTop: this.props.title ? "44px" : 0 }}
      >
        {this.props.title && (
          <EnhancedTableToolbar
            actionInHead={this.props.actionInHead}
            column={this.props.showInHead}
            selected={this.state.selected}
            insideDialog={this.props.insideDialog}
            themecolors={this.props.themecolors}
            numSelected={selected.length}
            title={this.props.title}
            onEdit={this.onEdit}
            viewInvoice={this.viewInvoice}
            viewPayment={this.viewPayment}
            viewDeliveryNote={this.viewDeliveryNote}
            tableSelection={this.tableSelection}
            downloadInvoice={this.downloadInvoice}
            showStatistics={this.showStatistics}
            loginAsUser={this.loginAsUser}
            handleChange={this.handleChange}
            onDelete={this.onDelete}
            onRestore={this.onRestore}
            onVehicleSendData={this.onVehicleSendData}
            handleOpenShowVehicleHistory={this.handleOpenShowVehicleHistory}
            onClose={this.onClose}
            onCreate={this.onCreate}
            createButton={this.props.createButton}
            exportButton={this.props.exportButton}
            importButton={this.props.importButton}
            onImport={this.onImport}
            btnName={this.props.btnName}
            btnName1={this.props.btnName1}
            onExport={this.props.onExport}
            btnView={this.props.btnView}
            onBinaryView={this.props.onBinaryView}
            viewButton={this.props.viewButton}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              background: this.props.themecolors["500"],
              color: this.props.themecolors["themeInverse"],
              paddingRight: this.props.insideDialog ? "44px" : "def",
              paddingLeft: this.props.insideDialog ? "44px" : "def",
            }}
          />
        )}

        <div
          className={classes.tableWrapper}
          style={this.props.tableHeight ? { marginTop: 80 } : { marginTop: 0 }}
        >
          {this.props.searchable && (
            <div style={{ padding: "2px" }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  // xs={this.props.filterDropdown ? 8 : 12}
                  xs={
                    this.props.userDataArray1 && this.props.userDataArray2
                      ? 6
                      : this.props.searchUnits
                      ? 9
                      : this.props.searchModule
                      ? 9
                      : this.props.accountDataArray1
                      ? 3
                      : this.props.userDataArray1
                      ? 9
                      : 12
                  }
                >
                  <TextField
                    fullWidth
                    // label={'Search...'}
                    label={this.props.translate("search")}
                    onChange={this.makeSearch}
                  />
                </Grid>
                {this.props.searchUnits && (
                  <Grid item xs={3}>
                    <SearchDevices
                      isMulti={false}
                      api="devices"
                      isClearable
                      onChange={this.props.searchUnitsChange}
                      placeholder={this.props.translate("searchUsers")}
                    />
                  </Grid>
                )}
                {/* {this.props.userDataArray1 && (
                  <Grid item xs={3}>
                    <Select
                      isMulti
                      key='Select User Type'
                      label={this.props.restore ? this.props.translate('Type') : this.props.translate('userType')}
                      array={this.props.userDataArray1}
                      onChange={this.props.userDataArrayChange1}
                      canAssign={true}
                      canRemove={true}
                    />
                  </Grid>
                )} */}
                {this.props.searchModule && (
                  <Grid item xs={3}>
                    {/* <Select
                      isMulti
                      key='Select Search Type'
                      label={this.props.translate('searchBy')}
                      array={this.props.userDataArray2}
                      onChange={this.props.userDataArrayChange2}
                      canAssign={true}
                      canRemove={true}
                    /> */}

                    <TextField
                      id="searchBy"
                      select
                      label={this.props.translate("search Module")}
                      value={
                        (this.props.selectedModuleId &&
                          this.props.selectedModuleId) ||
                        "none"
                      }
                      onChange={(e) =>
                        this.props.searchModuleChange("module", e)
                      }
                      margin="dense"
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>{this.props.translate("none")}</em>
                      </MenuItem>
                      {this.props.searchModule.map((option) => (
                        <MenuItem value={option.id}>{option.name}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {this.props.userDataArray2 && (
                  <Grid item xs={3}>
                    {/* <Select
                      isMulti
                      key='Select Search Type'
                      label={this.props.translate('searchBy')}
                      array={this.props.userDataArray2}
                      onChange={this.props.userDataArrayChange2}
                      canAssign={true}
                      canRemove={true}
                    /> */}
                    <TextField
                      id="searchBy"
                      select
                      label={this.props.translate("searchBy")}
                      value={
                        (this.props.searchSelectedId &&
                          this.props.searchSelectedId) ||
                        "none"
                      }
                      onChange={(e) =>
                        this.props.userDataArrayChange2("search", e)
                      }
                      margin="dense"
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>{this.props.translate("none")}</em>
                      </MenuItem>
                      {this.props.userDataArray2.map((option) => (
                        <MenuItem value={option.id}>{option.name}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                {this.props.userDataArray3 && (
                  <Grid item xs={3}>
                    <Select
                      isMulti
                      key="Select User Time"
                      label={this.props.translate("userTime")}
                      array={this.props.userDataArray3}
                      onChange={this.props.userDataArrayChange3}
                      canAssign={true}
                      canRemove={true}
                    />
                  </Grid>
                )}
                {this.props.accountDataArray1 && (
                  <Grid item xs={3}>
                    <Select
                      isMulti
                      key="Select Account Type"
                      label={this.props.translate("accountType")}
                      array={this.props.accountDataArray1}
                      onChange={this.props.accountDataArrayChange1}
                      canAssign={true}
                      canRemove={true}
                    />
                  </Grid>
                )}
                {this.props.accountDataArray2 && (
                  <Grid item xs={3}>
                    <Select
                      isMulti
                      key="Select Account Status"
                      label={this.props.translate("accountStatus")}
                      array={this.props.accountDataArray2}
                      onChange={this.props.accountDataArrayChange2}
                      canAssign={true}
                      canRemove={true}
                    />
                  </Grid>
                )}
                {this.props.accountDataArray3 && (
                  <Grid item xs={3}>
                    <Select
                      isMulti
                      key="Select Account Time"
                      label={this.props.translate("accountTime")}
                      array={this.props.accountDataArray3}
                      onChange={this.props.accountDataArrayChange3}
                      canAssign={true}
                      canRemove={true}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          )}

          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              timezone={this.props.timezone}
              translate={this.props.translate}
              numSelected={selected.length}
              order={order}
              onImport={this.props.onImport}
              orderBy={orderBy}
              isEditable={this.props.isEditable}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              // multihandleChange= {this.props.multihandleChange}
              // singlehandleChange = {this.props.singlehandleChange}
              handleAllCheckboxes={this.props.handleAllCheckboxes}
              handleCheckboxClick={this.props.handleCheckboxClick}
              setSerialNo={this.props.setSerialNo}
              setMultiSelect={this.props.multiSelect}
              rowDefinition={
                this.props.rowDefinition || [
                  {
                    id: "name",
                    numeric: false,
                    disablePadding: true,
                    label: this.props.translate("name"),
                  },
                ]
              }
              classes={classes}
              isRowIcons={this.props.isRowIcons}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy)).map((n, index) => {
                // console.log("n=====",n);

                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={(event) => this.handleClick(event, n)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id || n.name}
                    selected={isSelected}
                    className={
                      classes.tableRow + " " + (isCursoPointer && "pointer")
                    }
                  >
                    {this.props.setSerialNo && (
                      <TableCell>
                        <div>
                          {(this.props.pagination.page - 1) *
                            this.props.pagination.pageSize +
                            (index + 1)}
                        </div>
                      </TableCell>
                    )}

                    {this.props.multiSelect && (
                      <TableCell style={{ padding: "5px" }}>
                        <div key={n.id}>
                          <Checkbox
                            name="DocCheckbox"
                            color="primary"
                            checked={n.checked}
                            value={JSON.stringify({ ...n.id, ...n.itemId })}
                            onChange={() => this.props.handleCheckboxClick(n)}
                          />
                        </div>
                      </TableCell>
                    )}

                    {this.props.rowDefinition.map((r) => {
                      if (r) {
                        if (r.id === "loginAsUser") {
                          return (
                            <TableCell
                              key={r.id}
                              component="td"
                              scope="row"
                              style={{
                                padding: "2px",
                                whiteSpace: "nowrap",
                                color: "inherit",
                              }}
                            >
                              {localStorage.getItem("adminToken") ? (
                                ""
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    alignItems: "center",
                                  }}
                                >
                                  <TextField
                                    key={n.id + n.name}
                                    select
                                    label={""}
                                    value={n.logInAsUserState || ""}
                                    onChange={(e) => this.handleChange(e, n.id)}
                                    onClick={(e) => this.stopthandleClick(e)}
                                    SelectProps={{
                                      MenuProps: {
                                        className: classes.menu,
                                      },
                                    }}
                                    // style={{ fontWeight: 300, maxWidth: 45 }}
                                    style={{
                                      fontWeight: 300,
                                      width: n.logInAsUserState ? 85 : 44, // Conditional width based on value
                                      maxWidth: 85,
                                    }}
                                    margin="dense"
                                    fullWidth
                                  >
                                    {userLogInTypes(
                                      this.props.ServerSetting.attributes,
                                      n.extraInfo,
                                      n.userType
                                    ).map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <a
                                    href={null}
                                    onClick={(e) => this.loginAsUser(e, [n])}
                                    style={{
                                      marginLeft: 10,
                                      marginTop: 3,
                                      display: "inline-block",
                                    }}
                                  >
                                    <ExitToAppIcon
                                      className=""
                                      fontSize="small"
                                    />
                                  </a>
                                </div>
                              )}
                            </TableCell>
                          );
                        }
                        return (
                          <>
                            {r.id === "actionDetails" && n.action ? (
                              <TableCell
                                key={r.id}
                                className={classes.cell}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {n.action.key === 271 ||
                                n.action.key === 272 ? (
                                  <>
                                    <span> {n.action.name}.</span>
                                  </>
                                ) : n.itemType === "ItemGroup" &&
                                  (n.action.key === 141 ||
                                    n.action.key === 142 ||
                                    n.action.key === 143) ? (
                                  <>
                                    <span>
                                      {n?.attributes?.itemType} {n.itemType}{" "}
                                      <strong>{n.itemLabel}</strong>{" "}
                                      {n.action.name}.
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span>
                                      {n.itemType}{" "}
                                      <strong>{n.itemLabel} </strong>{" "}
                                      {n.action.name}.
                                    </span>
                                  </>
                                )}
                              </TableCell>
                            ) : r.id === "saleNetAmount" ||
                              r.id === "saleVATAmount" ||
                              r.id === "totalBalance" ||
                              r.id === "totalPaid" ||
                              r.id === "totalRevenue" ||
                              r.id === "netAmount" ||
                              r.id === "vatAmount" ||
                              r.id === "totalAmount" ||
                              r.id === "itemTotalAmount" ||
                              r.id === "saleTotalAmount" ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {r.id === "saleNetAmount"
                                  ? n.saleNetAmount?.toFixed(2)
                                  : r.id === "saleVATAmount"
                                  ? n.saleVATAmount?.toFixed(2)
                                  : r.id === "netAmount"
                                  ? n.netAmount?.toFixed(2)
                                  : r.id === "vatAmount"
                                  ? n.vatAmount?.toFixed(2)
                                  : r.id === "totalAmount"
                                  ? n.totalAmount?.toFixed(2)
                                  : r.id === "totalRevenue"
                                  ? n.totalRevenue?.toFixed(2)
                                  : r.id === "totalPaid"
                                  ? n.totalPaid?.toFixed(2)
                                  : r.id === "itemTotalAmount"
                                  ? n.itemTotalAmount?.toFixed(2)
                                  : r.id === "totalBalance"
                                  ? n.totalBalance?.toFixed(2)
                                  : n.saleTotalAmount?.toFixed(2)}
                              </TableCell>
                            ) : r.id === "packagesId" ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {n.packagesId !== 0 ? (
                                  <Tooltip title="Packages">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) =>
                                        this.tableSelection(e, [n], "packages")
                                      }
                                      style={{
                                        marginLeft: 5,
                                        marginTop: 3,
                                        display: "inline-block",
                                      }}
                                    >
                                      {"Packages"}
                                    </a>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                                {n.servicesId !== 0 ? (
                                  <Tooltip title="Services">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) =>
                                        this.tableSelection(e, [n], "services")
                                      }
                                      style={{
                                        marginLeft: 5,
                                        marginTop: 3,
                                        display: "inline-block",
                                      }}
                                    >
                                      {"Services"}
                                    </a>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                                {n.itemsId !== 0 ? (
                                  <Tooltip title="items">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) =>
                                        this.tableSelection(e, [n], "items")
                                      }
                                      style={{
                                        marginLeft: 5,
                                        marginTop: 3,
                                        display: "inline-block",
                                      }}
                                    >
                                      {"Items"}
                                    </a>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            ) : r.id === "serviceDate" ||
                              r.id === "recipientDate" ||
                              r.id === "created" ||
                              r.id === "invoiceDate" ||
                              r.id === "creditDebitDate" ||
                              r.id === "contractStartDate" ||
                              r.id === "purchaseDate" ||
                              r.id === "deliveryDate" ||
                              r.id === "contractEndDate" ||
                              r.id === "lastServiceDate" ||
                              r.id === "nextExecutionDate" ||
                              // r.id === "created" ||
                              r.id === "paymentDate" ? (
                              <TableCell
                                key={r.id}
                                className={classes.cell}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  // color: "inherit",

                                  color:
                                    r.id === "nextExecutionDate"
                                      ? moment
                                          .utc(moment().subtract(10, "days"))
                                          .tz(serverTimeZoneName)
                                          .diff(n.nextExecutionDate, "days") >=
                                        0
                                        ? "red" // Apply red color if the nextExecutionDate is within the last 10 days
                                        : "inherit"
                                      : "inherit",

                                  // color:  r.id ===  "nextExecutionDate" ?
                                  // moment.utc(n.nextExecutionDate).tz(serverTimeZoneName).diff(moment(), 'days') > 10
                                  //   ? "red" // Apply red color if more than 10 days difference
                                  //   : "inherit"
                                  // : "inherit",
                                }}
                              >
                                {r.id === "serviceDate" &&
                                n.serviceDate !== null
                                  ? moment
                                      .utc(n.serviceDate)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : r.id === "recipientDate" &&
                                    n.recipientDate !== null
                                  ? moment
                                      .utc(n.recipientDate)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : r.id === "created" && n.created !== null
                                  ? <>
                                  {
                                    this.props.isVehicleTable?
                                    <Tooltip
                                    title={n.attributes?.autoRegister?"Creation mode: Invoice":"Creation mode: Manual"}
                                    arrow
                                  >
                                    <Typography variant="body2" className="cursor-pointer">
                                      {moment.utc(n.created).tz(serverTimeZoneName).format("DD-MM-YYYY")}
                                    </Typography>
                                  </Tooltip>:
                                   moment
                                   .utc(n.created)
                                   .tz(serverTimeZoneName)
                                   .format("DD-MM-YYYY")
                               } 
                        
                                  </>
                                  : r.id === "invoiceDate" &&
                                    n.invoiceDate !== null
                                  ? moment
                                      .utc(n.invoiceDate)
                                      // .tz(this.props?.serverTimezone||"Asia/Riyadh")
                                      .format("DD-MM-YYYY")
                                  : r.id === "creditDebitDate" &&
                                    n.creditDebitDate !== null
                                  ? moment
                                      .utc(n.creditDebitDate)
                                      .tz(
                                        this.props?.serverTimezone ||
                                          "Asia/Riyadh"
                                      )
                                      .format("DD-MM-YYYY")
                                  : r.id === "contractStartDate" &&
                                    n.contractStartDate !== null
                                  ? moment
                                      .utc(n.contractStartDate)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : r.id === "purchaseDate" &&
                                    n.purchaseDate !== null
                                  ? moment
                                      .utc(n.purchaseDate)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : r.id === "deliveryDate" &&
                                    n.deliveryDate !== null
                                  ? moment
                                      .utc(n.deliveryDate)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : r.id === "contractEndDate" &&
                                    n.contractEndDate !== null
                                  ? moment
                                      .utc(n.contractEndDate)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : r.id === "nextExecutionDate" &&
                                    n.nextExecutionDate !== null
                                  ? moment
                                      .utc(n.nextExecutionDate)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : r.id === "lastServiceDate" &&
                                    n.lastServiceDate !== null
                                  ? moment
                                      .utc(n.lastServiceDate)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : r.id === "paymentDate" &&
                                    n.paymentDate !== null
                                  ? moment
                                      .utc(n.paymentDate)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : " "}
                              </TableCell>
                            ) : r.id === "attributes.parentName" ||
                               r.id === "attributes.userName"||
                               r.id === "attributes.contractNumber" ||
                              r.id === "attributes.depreciationCost" ||
                              r.id === "customerName" ||
                              r.id === "make" ||
                              r.id === "year" ||
                              r.id === "model" ||
                              r.id === "c_taxNumber" ? (
                              <TableCell
                                key={r.id}
                                className={classes.cell}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {r.id === "attributes.contractNumber"
                                  ? n?.attributes?.contractNumber || "":
                                  r.id === "attributes.userName"
                                  ? n?.attributes?.userName || "":
                                  r.id === "attributes.parentName"
                                  ? n?.attributes?.parentName || ""
                                  : r.id === "attributes.depreciationCost"
                                  ? n?.attributes?.depreciationCost !== 0
                                    ? n?.attributes?.depreciationCost?.toFixed(
                                        2
                                      ) || 0
                                    : 0
                                  : r.id === "customerName"
                                  ? n?.attributes?.customerName || ""
                                  : r.id === "make"
                                  ? n?.attributes?.make || ""
                                  : r.id === "year"
                                  ? n?.attributes?.year || ""
                                  : r.id === "c_taxNumber"
                                  ? n?.attributes?.c_taxNumber || ""
                                  : " "}
                              </TableCell>
                            ) : (r.id === "valid" &&
                                this.props.invoiceRow &&
                                checkPrivileges("invoiceInvalidView")) ||
                              (r.id === "valid" && this.props.invoiceRow) ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {n.valid ? "Valid" : "Invalid"}
                              </TableCell>
                            ) : r.id === "creationMode" ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {n.creationMode === "excelImport"
                                  ? "Manual"
                                  : n.creationMode === "garageUpload"
                                  ? "Normal"
                                  : ""}
                              </TableCell>
                            ) : r.id === "zatcaStatus" ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color:
                                    n.zatcaStatus === 0
                                      ? "inherit"
                                      : n.zatcaStatus === 1
                                      ? "green"
                                      : n.zatcaStatus === 2
                                      ? "red"
                                      : n.zatcaStatus === 3
                                      ? "orange"
                                      : "inherit",
                                }}
                              >
                                {n.zatcaStatus === 0
                                  ? "Not Sent"
                                  : n.zatcaStatus === 1
                                  ? "Pass"
                                  : n.zatcaStatus === 2
                                  ? "Error"
                                  : n.zatcaStatus === 3
                                  ? "Warning"
                                  : ""}
                              </TableCell>
                            ) : r.id === "creationMode" ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {n.creationMode === "excelImport"
                                  ? "Manual"
                                  : n.creationMode === "garageUpload"
                                  ? "Normal"
                                  : ""}
                              </TableCell>
                            ) : r.id === "zatcaStatus" ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color:
                                    n.zatcaStatus === 0
                                      ? "inherit"
                                      : n.zatcaStatus === 1
                                      ? "green"
                                      : n.zatcaStatus === 2
                                      ? "red"
                                      : n.zatcaStatus === 3
                                      ? "orange"
                                      : "inherit",
                                }}
                              >
                                {n.zatcaStatus === 0
                                  ? "Not Sent"
                                  : n.zatcaStatus === 1
                                  ? "Pass"
                                  : n.zatcaStatus === 2
                                  ? "Error"
                                  : n.zatcaStatus === 3
                                  ? "Warning"
                                  : ""}
                              </TableCell>
                            ) : r.id === "deliveryNoteNumber" ||
                              r.id === "invoiceNumber" ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {r.id === "deliveryNoteNumber"
                                  ? n.deliveryNoteNumber
                                    ? n.deliveryNoteNumber
                                    : ""
                                  : r.id === "invoiceNumber"
                                  ? n.invoiceNumber
                                    ? n.invoiceNumber
                                    : ""
                                  : ""}
                              </TableCell>
                            ) : r.id === "changeContract" ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "green",
                                  textAlign: "center",
                                }}
                              >
                                {checkPrivileges("userMigrate") &&
                                  (n.userType === 1 || n.userType === 2) && (
                                    <>
                                      <a
                                        href="javascript:void(0)"
                                        onClick={(event) =>
                                          this.handleChangeContract(event, n)
                                        }
                                        style={{
                                          marginTop: 3,
                                          display: "block",
                                          zIndex: 22,
                                        }}
                                      >
                                        <Tooltip
                                          title={this.props.translate(
                                            "changeContract"
                                          )}
                                        >
                                          <ContactsIcon fontSize="medium" />
                                        </Tooltip>
                                      </a>
                                    </>
                                  )}
                              </TableCell>
                            ) : r.id === "userStatus" ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "rgb(0, 193, 121)",
                                }}
                              >
                                {/* accountInactive */}
                                {/* {  checkPrivileges("userInactive")&& ( */}
                                <>
                                  {/* <p
                              onClick={(e) => {
                                // Check if user has privileges before opening dialog
                                const shouldOpenDialog = checkPrivileges("userInactive");
                                if (shouldOpenDialog) {
                                  this.openAccountStausDialog(e, [n]);
                                }
                              }}
                              style={{ color: this.props.userStatus[index] === false ? "rgb(0, 193, 121)" : "red", textDecoration: 'underline' }}

                            >
                                {this.props.userStatus[index] === false ? "Active" : "InActive"}
                            </p> */}

                                  <a
                                    onClick={(e) =>
                                      checkPrivileges("userInactive")
                                        ? this.openUserStatusDialog(e, [n])
                                        : null
                                    }
                                    style={{
                                      color:
                                        this.props.userStatus[index] === false
                                          ? "rgb(0, 193, 121)"
                                          : "red",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        <>
                                          {n.activeDate && (
                                            <div>
                                              <span>
                                              {this.props.translate(
                                                "Active Date"
                                              )}
                                              :{" "}
                                              {moment
                                                .utc(n.activeDate)
                                                .format("YYYY-MM-DD")}
                                              </span>{" "}
                                              |{" "}
                                              <span>
                                              {n?.extraInfo?.activeBy || "N/A"}
                                              </span>
                                            </div>
                                          )}
                                          {n.inactiveDate && (
                                            <div>
                                              <span>
                                              {this.props.translate(
                                                "Inactive Date"
                                              )}
                                              :{" "}
                                              {moment
                                                .utc(n.inactiveDate)
                                                .format("YYYY-MM-DD")}
                                              </span>{" "}
                                              |{" "}
                                              <span>
                                              {n?.extraInfo?.inactiveBy || "N/A"}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      }
                                    >
                                      <span>
                                        {this.props.userStatus[index] === false
                                          ? "Active"
                                          : "InActive"}
                                      </span>
                                    </Tooltip>
                                  </a>
                                </>
                                {/* )} */}
                              </TableCell>
                            ) : r.id === "active" ? (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "rgb(0, 193, 121)",
                                }}
                              >
                                {/* accountInactive */}
                                {/* {  checkPrivileges("accountInactive")&& (
                                <> */}
                                <p
                                  // href="#"
                                  // role="button"
                                  onClick={(e) => {
                                    // Check if user has privileges before opening dialog
                                    const shouldOpenDialog =
                                      n.contractNumber !== "1" &&
                                      checkPrivileges("accountInactive");
                                    if (shouldOpenDialog) {
                                      this.openAccountStausDialog(e, [n]);
                                    }
                                  }}
                                  style={{
                                    color:
                                      n.contractNumber === "1"
                                        ? "grey"
                                        : n.active
                                        ? "rgb(0, 193, 121)"
                                        : "red",
                                    textDecoration: "underline",
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      <>
                                        {n.activeDate && (
                                          <div>
                                            <span>
                                            {this.props.translate(
                                              "Active Date"
                                            )}
                                            :{" "}
                                            {moment
                                              .utc(n.activeDate)
                                              .format("YYYY-MM-DD")}
                                            </span>{" "}
                                            |{" "}
                                            <span>
                                            {n?.attributes?.activeBy || "N/A"}
                                            </span>
                                          </div>
                                        )}
                                        {n.inactiveDate && (
                                          <div>
                                            <span>
                                            {this.props.translate(
                                              "Inactive Date"
                                            )}
                                            :{" "}
                                            {moment
                                              .utc(n.inactiveDate)
                                              .format("YYYY-MM-DD")}
                                            </span>{" "}
                                            |{" "}
                                            <span>
                                            {n?.attributes?.inactiveBy || "N/A"}
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    }
                                  >
                                    <span>
                                      {n.contractNumber === "1"
                                        ? ""
                                        : n.active
                                        ? "Active"
                                        : "Inactive"}
                                    </span>
                                  </Tooltip>
                                </p>

                                {/* </>)} */}
                              </TableCell>
                            ) : r.id === "viewInvoice" ? (
                              <TableCell
                                key={r.id}
                                // className={classes.cell}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  // whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {
                                  <Tooltip title="HTML View" enterDelay={300}>
                                    <a
                                      onClick={
                                        n.invoiceId !== 0
                                          ? (e) => this.viewInvoice(e, [n])
                                          : null
                                      }
                                      style={{ color: "inherit" }}
                                    >
                                      <LinkIcon className="actionButton" />
                                    </a>
                                  </Tooltip>
                                }
                                {n.zatcaStatus===0||n.zatcaStatus===2?null:
                                  <Tooltip
                                    title="Download Pdf"
                                    enterDelay={300}
                                  >
                                    <a
                                      onClick={(e) =>
                                        this.downloadInvoice(e, [n])
                                      }
                                      style={{ color: "inherit" }}
                                    >
                                      <PictureAsPdf className="actionButton" />
                                    </a>
                                  </Tooltip>
                                }
                                {
                                  <Tooltip
                                    title="invoiceRespView"
                                    enterDelay={300}
                                  >
                                    <a
                                      onClick={(e) =>
                                        this.props.openinvoiceRespView(e, [n])
                                      }
                                      style={{ color: "inherit" }}
                                    >
                                      <ReceiptIcon className="actionButton" />
                                    </a>
                                  </Tooltip>
                                }
                                {n.zatcaStatus !== 1 && (
                                  <Tooltip
                                    title={this.props.translate(
                                      "forwardtoZatca"
                                    )}
                                    enterDelay={300}
                                  >
                                    <a
                                      onClick={(e) =>
                                        this.props.forwardToZatca([n])
                                      }
                                      style={{ color: "inherit" }}
                                    >
                                      <ForwardIcon
                                        color="success"
                                        className="actionButton"
                                        style={{ color: "green" }}
                                      />
                                    </a>
                                  </Tooltip>
                                )}

                                {/* {<Tooltip  title={this.props.translate("sendToClient")}  enterDelay={300} >
                                    <a
                                     onClick={(e) =>  this.props.sendToClient([n])} 
                                     style={{ color: "inherit" }}>
                                      <SendIcon  className="actionButton"/>
                                    </a>
                                  </Tooltip>} */}

                                {this.props.leasing ? (
                                  <>
                                    {(n.paymentStatus === 1 ||
                                      n.paymentStatus === 2) &&
                                    n.zatcaStatus === 0 &&
                                    checkPrivileges(
                                      "invoiceBulkInvoiceRegenerate"
                                    ) &&
                                    todayDate.diff(n.invoiceDate, "days") <=
                                      30 ? (
                                      <Tooltip
                                        title="Regenerate"
                                        enterDelay={300}
                                      >
                                        <a
                                          onClick={(e) =>
                                            this.regenerateInvoice(e, [n])
                                          }
                                          style={{ color: "inherit" }}
                                        >
                                          <ReplayIcon className="actionButton" />
                                        </a>
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {((n.contractNumber === "pfmc1960" &&
                                      checkPrivileges(
                                        "invoiceBulkInvoiceRegenerate"
                                      )) ||
                                      (n.paymentStatus === 1 &&
                                        n.deliveryNote === false &&
                                        n.creditNote === false &&
                                        n.debitNote === false &&
                                        n.zatcaStatus === 0 &&
                                        todayDate.diff(n.serviceDate, "days") <=
                                          30 &&
                                        checkPrivileges(
                                          "invoiceBulkInvoiceRegenerate"
                                        ))) && (
                                      <Tooltip
                                        title="Regenerate"
                                        enterDelay={300}
                                      >
                                        <a
                                          onClick={(e) =>
                                            this.regenerateInvoice(e, [n])
                                          }
                                          style={{ color: "inherit" }}
                                        >
                                          <ReplayIcon className="actionButton" />
                                        </a>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                                {this.props.leasing ? (
                                  <>
                                    <a
                                      onClick={(e) =>
                                        this.downloadInvoice(e, [n], true)
                                      }
                                      style={{ color: "inherit" }}
                                    >
                                      <SimCardDownload className="actionButton" />
                                    </a>
                                  </>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            ) : r.id === "viewDeliveryNote" ? (
                              <TableCell
                                key={r.id}
                                className={classes.cell}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {
                                  <Tooltip title="HTML View" enterDelay={300}>
                                    <a
                                      onClick={
                                        n.invoiceId !== 0
                                          ? (e) => this.viewDeliveryNote(e, [n])
                                          : ""
                                      }
                                      style={{ color: "inherit" }}
                                    >
                                      <LinkIcon className="actionButton" />
                                    </a>
                                  </Tooltip>
                                }
                                {
                                  <Tooltip
                                    title="Download Pdf"
                                    enterDelay={300}
                                  >
                                    <a
                                      onClick={(e) =>
                                        this.downloadInvoice(e, [n])
                                      }
                                      style={{ color: "inherit" }}
                                    >
                                      <PictureAsPdf className="actionButton" />
                                    </a>
                                  </Tooltip>
                                }
                                {checkPrivileges("deliverynoteUpdate") &&
                                  todayDate.diff(n.serviceDate, "days") <=
                                    30 && (
                                    <Tooltip
                                      title="Regenerate"
                                      enterDelay={300}
                                    >
                                      <a
                                        onClick={(e) =>
                                          this.regenerateInvoice(e, [n])
                                        }
                                        style={{ color: "inherit" }}
                                      >
                                        <ReplayIcon className="actionButton" />
                                      </a>
                                    </Tooltip>
                                  )}
                              </TableCell>
                            ) : r.id === "viewCredit" ||
                              r.id === "viewDebit" ||
                              r.id === "paymentPdf" ? (
                              <TableCell
                                key={r.id}
                                // className={classes.cell}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {
                                  <Tooltip
                                    title={this.props.translate(
                                      r.id === "viewCredit"
                                        ? "Download CN"
                                        : r.id === "paymentPdf"
                                        ? "Download Paymnet"
                                        : r.id === "viewDebit"
                                        ? "Download DN"
                                        : ""
                                    )}
                                    enterDelay={300}
                                  >
                                    <a
                                      onClick={(e) =>
                                        this.downloadInvoice(e, [n])
                                      }
                                      style={{ color: "inherit" }}
                                    >
                                      <PictureAsPdf className="actionButton" />
                                    </a>
                                  </Tooltip>
                                }

                                {r.id === "viewCredit" ||
                                r.id === "viewDebit" ? (
                                  <>
                                    <Tooltip
                                      title={this.props.translate(
                                        "Download Summary"
                                      )}
                                      enterDelay={300}
                                    >
                                      <a
                                        onClick={(e) =>
                                          this.downloadInvoice(
                                            e,
                                            [n],
                                            false,
                                            "summary"
                                          )
                                        }
                                        style={{ color: "inherit" }}
                                      >
                                        <PictureAsPdf className="actionButton" />
                                      </a>
                                    </Tooltip>
                                    {this.props.type === "credit" && (
                                      <Tooltip
                                        title="cdRespView"
                                        enterDelay={300}
                                      >
                                        <a
                                          onClick={(e) =>
                                            this.props.openinvoiceRespView(e, [
                                              n,
                                            ])
                                          }
                                          style={{ color: "inherit" }}
                                        >
                                          <ReceiptIcon className="actionButton" />
                                        </a>
                                      </Tooltip>
                                    )}

                                    {n.zatcaStatus !== 1 &&
                                      this.props.type === "credit" && (
                                        <Tooltip
                                          title={this.props.translate(
                                            "forwardtoZatca"
                                          )}
                                          enterDelay={300}
                                        >
                                          <a
                                            onClick={(e) =>
                                              this.props.forwardToZatca([n])
                                            }
                                            style={{ color: "inherit" }}
                                          >
                                            <ForwardIcon
                                              color="success"
                                              className="actionButton"
                                              style={{ color: "green" }}
                                            />
                                          </a>
                                        </Tooltip>
                                      )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            ) : (
                              <TableCell
                                className={classes.cell}
                                key={r.id}
                                align={r.numeric ? "right" : "left"}
                                padding={r.disablePadding ? "none" : "default"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                }}
                              >
                                {this.drawValue(n[r.id], r.type, n.valueType) ||
                                  ""}
                              </TableCell>
                            )}
                          </>
                        );
                      }
                    })}
                    {this.props.isRowIcons && (
                      <TableCell
                        className={classes.cell}
                        key={n.id + "tableCel"}
                        align={"left"}
                        // padding={'default'}
                        component="td"
                        scope="row"
                        style={{
                          padding: "2px",
                          whiteSpace: "nowrap",
                          color: "inherit",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) => this.showStatistics(e, [n])}
                        >
                          <EqualizerIcon fontSize="small" />
                        </a>
                      </TableCell>
                    )}
                    {this.props.isEditable && (
                      <TableCell
                        className={classes.cell}
                        key={n.id + "tableCell1"}
                        component="td"
                        scope="row"
                        style={{
                          padding: "2px",
                          whiteSpace: "nowrap",
                          minWidth: 60,
                        }}
                      >
                        {this.props.hasAccessOfAdd && (
                          <Tooltip title="Add">
                            <a
                              href="javascript:void(0)"
                              onClick={(e) => this.onEdit(e, [n])}
                              style={{
                                marginLeft: 5,
                                marginTop: 3,
                                display: "inline-block",
                              }}
                            >
                              <AddBoxOutlinedIcon
                                className="actionButton"
                                fontSize="small"
                              />
                            </a>
                          </Tooltip>
                        )}
                        {/* {this.props.hasAccessOfUpdate && !this.props.invoiceRow  && (
                          <Tooltip title="Edit">
                            <a
                              href="javascript:void(0)"
                              onClick={(e) => this.onEdit(e, [n])}
                              style={{
                                marginLeft: 5,
                                marginTop: 3,
                                display: "inline-block",
                              }}
                            >
                              <EditIcon
                                className="actionButton"
                                fontSize="small"
                              />
                            </a>
                          </Tooltip>
                        )} */}
                        {this.props.hasAccessOfUpdate &&
                          this.props.invoiceRow &&
                          n.paymentStatus !== 3 && (
                            <Tooltip title="Edit">
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => this.onEdit(e, [n])}
                                style={{
                                  marginLeft: 5,
                                  marginTop: 3,
                                  display: "inline-block",
                                }}
                              >
                                <EditIcon
                                  className="actionButton"
                                  fontSize="small"
                                />
                              </a>
                            </Tooltip>
                          )}

                        {this.props.hasAccessOfRegenerate && (
                          <Tooltip title="Regenerate" enterDelay={300}>
                            <a
                              onClick={(e) => this.regenerateInvoice(e, [n])}
                              style={{ color: "inherit" }}
                            >
                              <ReplayIcon className="actionButton" />
                            </a>
                          </Tooltip>
                        )}
                        {this.props.payment && (
                          <Tooltip title="View Details">
                            <a
                              href="javascript:void(0)"
                              onClick={(e) => this.onEdit(e, [n])}
                              style={{
                                marginLeft: 5,
                                marginTop: 3,
                                display: "inline-block",
                              }}
                            >
                              <VisibilityIcon
                                className="actionButton"
                                fontSize="small"
                              />
                            </a>
                          </Tooltip>
                        )}
            {this.props.isShowVehiclHistory && (
                                  <Tooltip title="vehicelHistory">
                                  <a
                                    href={null}
                                    onClick={(e) => this.handleOpenShowVehicleHistory(e, [n])}
                                    style={{
                                      // marginLeft: 5,
                                      marginTop: 3,
                                      display: "inline-block",
                                    }}
                                  >
                                    <ScheduleIcon className="" fontSize="medium" />
                                  </a>
                                </Tooltip>
                            )}
                        {this.props.restore && (
                          <Tooltip title="Restore">
                            <a
                              href={null}
                              onClick={(e) => this.onRestore(e, [n])}
                              style={{
                                // marginLeft: 5,
                                marginTop: 3,
                                display: "inline-block",
                              }}
                            >
                              <RestoreIcon className="" fontSize="medium" />
                            </a>
                          </Tooltip>
                        )}
                            {this.props.hasAccessOfVehicleSendData && (
                          <Tooltip title="Sync Servers">
                            <a
                              href={null}
                              onClick={(e) => this.onVehicleSendData(e, [n])}
                              style={{
                                display: "inline-block",
                              }}
                        
                            >
                              <LaunchIcon
                                    className={
                                      this.props.restore ? "" : "actionButton"
                                    }
                                    fontSize={
                                      this.props.restore ? "medium" : "small"
                                    }/>
                            </a>
                          </Tooltip>
                        )}
                        {this.props.hasAccessOfDelete && (
                          <Tooltip
                            title={
                              this.props.restore
                                ? "Delete Permanently"
                                : "Delete"
                            }
                          >
                            <a
                              href={null}
                              onClick={(e) => this.onDelete(e, [n])}
                              style={{
                                marginLeft: this.props.restore ? 5 : "",
                                display: "inline-block",
                              }}
                            >
                              <DeleteIcon
                                className={
                                  this.props.restore ? "" : "actionButton"
                                }
                                fontSize={
                                  this.props.restore ? "medium" : "small"
                                }
                              />
                            </a>
                          </Tooltip>
                        )}
                        {this.props.hasAccessOfChangeStatus && (
                          <Tooltip title={"Change Status"}>
                            <a
                              href={null}
                              onClick={(e) => this.onChangeStatus(e, [n])}
                              style={{
                                marginLeft: this.props.restore ? 5 : "",
                                display: "inline-block",
                              }}
                            >
                              {n.disabled ? (
                                <RadioButtonUncheckedIcon
                                  className={"actionButton"}
                                  fontSize={"small"}
                                />
                              ) : (
                                <RadioButtonCheckedIcon
                                  className={"actionButton"}
                                  fontSize={"small"}
                                />
                              )}
                            </a>
                          </Tooltip>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 36 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {!this.props.isSelectedDubious&&
          <div
            style={{
              padding: "0 20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: 10 }} className="hidden-xs">
              {this.props.translate("rowsPerPage")}
            </span>
            <span style={{ marginRight: 10, minWidth: 60 }}>
              <TextField
                id="rowsPerPage"
                select
                style={{ width: 60 }}
                value={(pagination && pagination.pageSize) || 5}
                onChange={(e) => this.handleChangeRowsPerPage(e)}
                margin="dense"
              >
                {rowsPerPageOptionsList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </span>
            <span style={{ flex: 1 }}></span>

            <span
              style={{ marginRight: 5, whiteSpace: "nowrap", maxWidth: 300 }}
            >
              {this.props.translate("page")}{" "}
              <strong>{pagination && pagination.page}</strong>{" "}
              <span className="visible-responsive">
                {" "}
                / {pagination && pagination.lastPage} -{" "}
                {pagination && pagination.total}{" "}
              </span>
            </span>
            <span style={{ marginRight: 10 }} className="hidden-responsive">
              {this.props.translate("outOf")}{" "}
              <strong>{pagination && pagination.lastPage}</strong>
            </span>
            <span style={{ marginRight: 10 }} className="hidden-responsive">
              {this.props.translate("totalRecords")}{" "}
              <strong>{pagination && pagination.total}</strong>
            </span>
            <span style={{ marginRight: 10, cursor: "pointer" }}>
              <FirstPageIcon
                fontSize="small"
                onClick={(e) => this.handleChangePage("first")}
              />
            </span>
            <span style={{ marginRight: 10, cursor: "pointer" }}>
              <PrevPageIcon
                fontSize="small"
                onClick={(e) => this.handleChangePage("prev")}
              />
            </span>
            <span style={{ marginRight: 10, cursor: "pointer" }}>
              <NextPageIcon
                fontSize="small"
                onClick={(e) => this.handleChangePage("next")}
              />
            </span>
            <span style={{ marginRight: 10, cursor: "pointer" }}>
              <LastPageIcon
                fontSize="small"
                onClick={(e) => this.handleChangePage("last")}
              />
            </span>
          </div>
        }
      </Paper>
    );
  }
}

export default withStyles(styles)(withTranslationWrapper(EnhancedTable));
const rowsPerPageOptions = [5, 10, 15, 20, 25, 50, 100, 500];
const rowsPerPageOptionsVehicleHistory = [5, 10, 25,50];


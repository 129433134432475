import "date-fns";
import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import TextField from "../common/TextField";
import { withStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Place";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AsyncSelect from "../common/asyncSelect";
import Button from "../common/Button";
import Grid from "@mui/material/Grid";
import "react-toastify/dist/ReactToastify.css";
import SingleSelect from "../common/SingleSelect";
import Checkbox from "../common/Checkbox";
import { timeZonesTypes } from "./../Attributes/timezone";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  mapLayerTypes,
  coordinateFormatTypes,
  ownerUserType,
  serviceProviderUserType,
  resellerUserType,
  distributorUserType,
  adminUserType,
  accountAdminType,
  measurementStandard,
  currencies,
} from "./userDataTypes";
import "./usersModal.scss";
import { checkPrivileges, getTooltipMessage } from "../../Helpers";
import { connect } from "react-redux";
import PublishIcon from "@mui/icons-material/Publish";
import CancelIcon from "@mui/icons-material/Cancel";
import { FormHelperText } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CustomDatePicker from "../common/CustomDatePicker";

let themecolors;
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors;
  return {
    themecolors: state.themecolors,
  };
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    // margin: theme.spacing(1),
  },
  grid: {
    width: "60%",
  },
  label: {
    marginTop: -4,
  },
  shrink: {
    marginTop: 0,
  },
  focused: {
    marginTop: 0,
  },
  margin: {
    // margin: theme.spacing(1),
  },
  cssLabel: {
    "&$cssFocused": {
      // color: themecolors[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      // borderBottomColor: themecolors[500],
    },
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      // borderColor: themecolors[500],
    },
  },
  notchedOutline: {},
  input: {
    padding: "12px 14px 12px",
  },
});
let contractTypes = [
  { id: 1, name: "Maintenance" },
  { id: 2, name: "Leasing" },
  { id: 3, name: "cashCustomer" },
];

let customerTypes = [
  { key: 1, name: "garage" },
  { key: 2, name: "operation" },
  { key: 3, name: "user" },
];

let creditPeriod = [
  { key: 1, name: "0" },
  { key: 2, name: "30" },
  { key: 3, name: "60" },
  { key: 4, name: "90" },
  { key: 5, name: "120" },
];

const c_schemeIdList = [
  { key: 1, name: "TIN" },
  { key: 2, name: "CRN" },
  { key: 3, name: "MOM" },
  { key: 4, name: "MLS" },
  { key: 5, name: "700" },
  { key: 6, name: "SAG" },
  { key: 7, name: "NAT" },
  { key: 8, name: "GCC" },
  { key: 9, name: "IQA" },
  { key: 10, name: "OTH" },
];
let statuses = [
  { value: "legal", name: "Legal" },
  { value: "credit", name: "Credit" },
  { value: "advance", name: "Advance" },
  { value: "cash", name: "Cash" },
];
const defaultStatusesList = [
  { key: "FS", name: "FS" },
  { key: "NS", name: "NS" },
  { key: "OS", name: "OS" },
  { key: "PP", name: "PP" },
  { key: "RG", name: "RG" },
];

const idIssuerList = [
  { key: "CM", name: "CM" },
  { key: "CU", name: "CU" },
  { key: "CW", name: "CW" },
  { key: "EN", name: "EN" },
  { key: "FI", name: "FI" },
  { key: "GI", name: "GI" },
  { key: "IN", name: "IN" },
  { key: "MA", name: "MA" },
  { key: "MC", name: "MC" },
  { key: "MD", name: "MD" },
  { key: "ME", name: "ME" },
  { key: "MF", name: "MF" },
  { key: "MH", name: "MH" },
  { key: "MI", name: "MI" },
  { key: "MJ", name: "MJ" },
  { key: "ML", name: "ML" },
  { key: "MN", name: "MN" },
  { key: "MU", name: "MU" },
  { key: "MX", name: "MX" },
  { key: "RD", name: "RD" },
  { key: "PP", name: "PP" },
  { key: "SA", name: "SA" },
  { key: "TV", name: "TV" },
  { key: "UN", name: "UN" },
];
const productTypeList = [
  { key: "ADFL", name: "ADFL" },
  { key: "AMTG", name: "AMTG" },
  { key: "AQAR", name: "AQAR" },
  { key: "CDC", name: "CDC" },
  { key: "CDL", name: "CDL" },
  { key: "CHC", name: "CHC" },
  { key: "CHCK", name: "CHCK" },
  { key: "COM", name: "COM" },
  { key: "CRC", name: "CRC" },
  { key: "EDUF", name: "EDUF" },
  { key: "GFC", name: "GFC" },
  { key: "IAC", name: "IAC" },
  { key: "IEN", name: "IEN" },
  { key: "ILB", name: "ILB" },
  { key: "IMD", name: "IMD" },
  { key: "IMR", name: "IMR" },
  { key: "IMTG", name: "IMTG" },
  { key: "IMV", name: "IMV" },
  { key: "IPR", name: "IPR" },
  { key: "IPS", name: "IPS" },
  { key: "LEGL", name: "LEGL" },
  { key: "LND", name: "LND" },
  { key: "MBL", name: "MBL" },
  { key: "MGLD", name: "MGLD" },
  { key: "MIS", name: "MIS" },
  { key: "MSKN", name: "MSKN" },
  { key: "MTG", name: "MTG" },
  { key: "NET", name: "NET" },
  { key: "PE", name: "PE" },
  { key: "CRC", name: "CRC" },
  { key: "PLN", name: "PLN" },
  { key: "POD", name: "POD" },
  { key: "RCSR", name: "RCSR" },
  { key: "RERA", name: "RERA" },
  { key: "RMSKN", name: "RMSKN" },
  { key: "RMTG", name: "RMTG" },
  { key: "RPLN", name: "RPLN" },
  { key: "RSMEI", name: "RSMEI" },
  { key: "RSMEL", name: "RSMEL" },
  { key: "RVIN", name: "RVIN" },
  { key: "RVLS", name: "RVLS" },
  { key: "SFB", name: "SFB" },
  { key: "SME", name: "SME" },
  { key: "SMEI", name: "SMEI" },
  { key: "SMEL", name: "SMEL" },
  { key: "SMS", name: "SMS" },
  { key: "STFM", name: "STFM" },
  { key: "TMTG", name: "TMTG" },
  { key: "TPLN", name: "TPLN" },
  { key: "TOD", name: "TOD" },
  { key: "UNFS", name: "UNFS" },
  { key: "VCLM", name: "VCLM" },
  { key: "VEHE", name: "VEHE" },
  { key: "VESP", name: "VESP" },
  { key: "VIN", name: "VIN" },
  { key: "VLS", name: "VLS" },
  { key: "VRA", name: "VRA" },
  { key: "WAT", name: "WAT" },
];
const productStatuesList = [
  { key: "A", name: "A" },
  { key: "S", name: "S" },
  { key: "C", name: "C" },
  { key: "W", name: "W" },
  { key: "X", name: "X" },
  { key: "L", name: "L" },
];
const goodsServicesList = [
  { key: "ALOTH", name: "ALOTH" },
  { key: "APPLN", name: "APPLN" },
  { key: "MCHNR", name: "MCHNR" },
  { key: "CARS", name: "CARS" },
  { key: "BLDMT", name: "BLDMT" },
  { key: "TRCTH", name: "TRCTH" },
  { key: "OTHFD", name: "OTHFD" },
  { key: "LMEAT", name: "LMEAT" },
  { key: "SUGTC", name: "SUGTC" },
  { key: "FRVEG", name: "FRVEG" },
];

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    // fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
const ValidationIcon = ({ isValid, message }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {isValid ? (
      <CheckCircleIcon style={{ color: 'green', fontSize: 12 }} />
    ) : (
      <CancelIcon style={{ color: 'red', fontSize: 12 }} />
    )}
    <span style={{ marginLeft: '5px',color:"gray" }}>{message}</span>
  </div>
);
const PasswordRequirements = ({
  hasLengthValid,
  hasNumberValid,
  hasUpperCaseValid,
  hasLowerCaseValid,
  hasSpecialCharValid,
  translate,
}) => (
  <FormHelperText style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
    <ValidationIcon
      isValid={hasLengthValid}
      message={translate('Length must be at least 8 characters')}
    />
    <ValidationIcon
      isValid={hasNumberValid}
      message={translate('Must contain at least one Number')}
    />
    <ValidationIcon
      isValid={hasUpperCaseValid}
      message={translate('Must contain at least one Uppercase letter')}
    />
    <ValidationIcon
      isValid={hasLowerCaseValid}
      message={translate('Must contain at least one Lowercase letter')}
    />
    <ValidationIcon
      isValid={hasSpecialCharValid}
      message={translate('Must contain at least one Special character (!@#$%^&*(),.?:{}|<></>)')}
    />
  </FormHelperText>
);

class addUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      at_modal_visable: false,
      resultMessage: "",
      showButton: this.props.editOption ? true : false,
    };
    this.searchFromZoneList = this.searchFromZoneList.bind(this);
  }
  componentDidUpdate(prevProps) {
    const prevuserDetailsNameValue = prevProps.userDetailsNameValue;
    const currentuserDetailsNameValue = this.props.userDetailsNameValue;
    if (prevuserDetailsNameValue !== currentuserDetailsNameValue) {
      this.props.handleChange("name")({
        target: { value: currentuserDetailsNameValue },
      });
    }
    const prevuserDetailsEmailValue = prevProps.userDetailsEmailValue;
    const currentuserDetailsEmailValue = this.props.userDetailsEmailValue;
    if (prevuserDetailsEmailValue !== currentuserDetailsEmailValue) {
      this.props.handleChange("email")({
        target: { value: currentuserDetailsEmailValue },
      });
    }
  }
  async searchFromZoneList(inputValue) {
    if (!inputValue) {
      return [];
    }

    let result = timeZonesTypes.filter((i) =>
      i.key.toLowerCase().includes(inputValue.toLowerCase())
    );

    return result.map((item) => {
      return { id: item.key, key: item.key, label: item.key, value: item.key };
    });
  }
  searchAccountsList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    let fetchapi = `/api/accounts/all?accountId=${this.props.logInUser.accountId}&search=${inputValue}&limit=100`;
    if (this.props.logInUser.userType === -1) {
      fetchapi = `/api/accounts/all?accountId=${this.props.logInUser.accountId}&all=true&search=${inputValue}&limit=100`;
    }
    let response = await fetch(fetchapi, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let json = await response.json();
    let result =
      (await json.data.data) &&
      json.data.data.map((item) => ({
        ...item,
        label: item.label || item.name,
        value: item.id,
      }));
    if (result.length === 0) {
      this.setState({
        resultMessage: "No option found",
      });
    } else {
      this.setState({
        resultMessage: "",
      });
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: "Search for more.",
      });
    }
    return result;
  };
  searchUsersList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    let fetchapi = `/api/users/list?accountId=${this.props.form.accountId}&search=${inputValue}&limit=100`;
    if (this.props.logInUser.userType === -1) {
      fetchapi = `/api/users/list?userId=${this.props.logInUser.accountId}&all=true&search=${inputValue}&limit=100`;
    }
    let response = await fetch(fetchapi, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let json = await response.json();
    let result =
      (await json.data.data) &&
      json.data.data.map((item) => ({
        ...item,
        label: item.label || item.name,
        value: item.id,
      }));
    if (result.length === 0) {
      this.setState({
        resultMessage: "No option found",
      });
    } else {
      this.setState({
        resultMessage: "",
      });
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: "Search for more.",
      });
    }
    return result;
  };
  toggleRoles = (e) => {
    this.setState({
      showButton: !this.state.showButton,
    });
    return this.props.handleChange("roleId")(e);
  };

  render() {
    let userTypeArray = [];
    if (this.props.userType) {
      let { userType } = this.props;
      if (userType === -1) {
        userTypeArray = ownerUserType;
      } else if (userType === 2) {
        userTypeArray = adminUserType;
      } else if (userType === 3) {
        userTypeArray = accountAdminType;
      } else if (userType === 4) {
        userTypeArray = serviceProviderUserType;
      } else if (userType === 5) {
        userTypeArray = resellerUserType;
      } else if (userType === 6) {
        userTypeArray = distributorUserType;
      } else {
        userTypeArray = [];
      }
    }
    let defaultZones = [];
    timeZonesTypes.map((item, i) => {
      // if (i <= 9) {
      defaultZones.push({
        id: item.key,
        key: item.key,
        label: item.label,
        value: item.key,
      });
      // }
    });
    const { classes } = this.props;
    const selectMe = (element) => {
      var doc = document,
        text = element.target,
        range,
        selection;
      if (doc.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(text);
        range.select();
      } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(text);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    };

    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.props}
          translate={this.props.translate}
          visable={true}
          isButtonVisable={true}
          isNotShowAttributesBtn={true}
          title={this.props.addOption ? "sharedRequired" : ""}
          formSubmit={this.props.formSubmit}
          addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          buttonText={this.props.buttonText}
          btnclosetext="sharedBack"
          notVisableBackbtn={this.props.notVisableBackbtn}
          isVisableBtn={
            this.props.isVisableUserBtn &&
            !this.props.emailInvalidError &&
            !this.props.accountEmailInvalidError
          }
          content={
            <Fragment>
              {!this.props.editOption && (
                <>
                  <Grid container spacing={2}>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="userType"
                        select
                        label={this.props.translate("userType")}
                        value={this.props.form.userType || ""}
                        onChange={this.props.handleChange("userType")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="dense"
                        fullWidth
                        // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                        required
                      >
                        <MenuItem value="">
                          <em>{this.props.translate("none")}</em>
                        </MenuItem>
                        {userTypeArray.length &&
                          userTypeArray.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                              {this.props.translate(option.name)}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </>
              )}
              {this.props.form.userType > 2 ? (
                <>
                  <h4>{this.props.translate("accountDetails")}</h4>
                  <Grid container spacing={2}>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="contractType"
                        select
                        label={this.props.translate("contractType")}
                        value={this.props.form?.contractType}
                        onChange={this.props.handleChange("contractType")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="dense"
                        fullWidth
                        // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                        required
                      >
                        {contractTypes.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {this.props.form.userType === 4 && (
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField
                          id="customerType"
                          select
                          label={this.props.translate("customerType")}
                          value={this.props.form.customerType || ""}
                          onChange={this.props.handleChange("customerType")}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="dense"
                          fullWidth
                          // helperText={this.props.translate("Enter Value between 0 and 100")}
                          disabled={this.props.editOption}
                          // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                          required
                        >
                          {customerTypes.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                              {this.props.translate(option.name)}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="name"
                        label={this.props.translate(
                          "accountTitle (Registeration Name)"
                        )}
                        type="text"
                        placeholder=""
                        value={this.props.form.accountName}
                        onChange={this.props.handleChange("accountName")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges("userUpdate")
                              ? false
                              : true,
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="name"
                        label={this.props.translate("nameArabic")}
                        type="text"
                        placeholder=""
                        value={this.props.form?.nameArabic || ""}
                        onChange={this.props.handleChange("nameArabic")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges("userUpdate")
                              ? false
                              : true,
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="description"
                        label={this.props.translate("sharedDescription")}
                        type="text"
                        placeholder=""
                        value={this.props.form.accountDescription || ""}
                        onChange={this.props.handleChange("accountDescription")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges("userUpdate")
                              ? false
                              : true,
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="email"
                        error={this.props.accountEmailInvalidError}
                        label={this.props.translate("accountEmail")}
                        type="email"
                        placeholder=""
                        value={this.props.form.accountEmail}
                        onChange={this.props.handleChange("accountEmail")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges("userUpdate")
                              ? false
                              : true,
                        }}
                      />
                      <FormHelperText
                        error={
                          this.props.accountEmailInvalidError ? true : false
                        }
                      >
                        {this.props.translate(
                          "Please enter a valid email address"
                        )}
                      </FormHelperText>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="contact"
                        label={this.props.translate("contact")}
                        type="text"
                        placeholder=""
                        value={this.props.form.accountContact}
                        onChange={this.props.handleChange("accountContact")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges("userUpdate")
                              ? false
                              : true,
                        }}
                      />
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="contractName"
                        label={this.props.translate("contractName")}
                        type="text"
                        required
                        placeholder=""
                        value={this.props.form?.contractName || ""}
                        onChange={this.props.handleChange("contractName")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges("userUpdate")
                              ? false
                              : true,
                        }}
                      />
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        required
                        id="contractNumber"
                        label={this.props.translate("contractNumber")}
                        type="text"
                        placeholder=""
                        value={this.props.form?.contractNumber || ""}
                        onChange={this.props.handleChange("contractNumber")}
                        variant="outlined"
                        margin="dense"
                        disabled={this.props.editOption}
                        fullWidth
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges(
                              "accountCustomerAndContractNumUpdate"
                            )
                              ? false
                              : true,
                        }}
                      />
                    </Grid>
                    {(this.props.form.userType === 1 ||
                      this.props.form.userType === 2 ||
                      this.props.form.userType === 3) && (
                      <>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField
                            required
                            id="vatPercentage"
                            label={this.props.translate("vatPercentage")}
                            type="number"
                            placeholder=""
                            value={
                              this.props.form.vatPercentage !== undefined
                                ? this.props.form.vatPercentage
                                : ""
                            }
                            onChange={this.props.handleChange("vatPercentage")}
                            variant="outlined"
                            margin="dense"
                            error={this.props.vatPercentageError}
                            helperText={
                              this.props && this.props.vatPercentageError
                                ? this.props.vatPercentageError
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              readOnly:
                                !this.props.updateOption ||
                                checkPrivileges(
                                  "accountCustomerAndContractNumUpdate"
                                )
                                  ? false
                                  : true,
                            }}
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField
                            id="pacContractId"
                            label={this.props.translate("pacContractId")}
                            type="text"
                            // required
                            placeholder=""
                            value={this.props.form?.pacContractId || ""}
                            onChange={this.props.handleChange("pacContractId")}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            InputProps={{
                              readOnly:
                                !this.props.updateOption ||
                                checkPrivileges("userUpdate")
                                  ? false
                                  : true,
                            }}
                          />
                        </Grid>
                      </>
                    )}

                    {this.props.form.userType === 4 &&
                      this.props.form.customerType === 1 && (
                        <Grid item md={3} sm={6} xs={12} style={{ zIndex: 99 }}>
                          <AsyncSelect
                            value={
                              this.props.form.garage &&
                              this.props.form.garage.id
                                ? {
                                    id: this.props.form.garage.id,
                                    value: this.props.form.garage.id,
                                    label: this.props.form.garage.name,
                                    uniqueId: this.props.form.garage.uniqueId,
                                  }
                                : this.props.form.garageNumber
                                ? {
                                    label: this.props.form.garageNumber,
                                  }
                                : ""
                            }
                            placeholder={this.props.translate("garage")}
                            selectName="garage"
                            defaultOptions={this.props.defaultGarageOptions}
                            handleChange={this.props.handleChangeExpense}
                            loadOptions={this.props.searchGarageList}
                            isMulti={false}
                          />
                        </Grid>
                      )}
                    {this.props.form.userType &&
                      this.props.form.userType !== 4 && (
                        <>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="customerNumber"
                              label={this.props.translate("customerNumber")}
                              type="text"
                              placeholder=""
                              value={this.props.form?.customerNumber || ""}
                              onChange={this.props.handleChange(
                                "customerNumber"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges(
                                    "accountCustomerAndContractNumUpdate"
                                  )
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="creditLimit"
                              label={this.props.translate("creditLimit")}
                              type="number"
                              placeholder=""
                              value={this.props.form?.creditLimit || ""}
                              onChange={this.props.handleChange("creditLimit")}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges(
                                    "accountCustomerAndContractNumUpdate"
                                  )
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="creditPeriod"
                              select
                              label={this.props.translate("creditPeriod")}
                              value={this.props.form.creditPeriod || 0}
                              onChange={this.props.handleChange("creditPeriod")}
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu,
                                },
                              }}
                              margin="dense"
                              fullWidth
                              // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                              required={
                                this.props?.form?.userType === 5 ||
                                this.props?.form?.userType === 6
                              }
                            >
                              {creditPeriod.map((option) => (
                                <MenuItem key={option.key} value={option.name}>
                                  {this.props.translate(option.name)}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>

                          {this.props.form.userType === 3 ? (
                            <Grid
                              item
                              md={3}
                              sm={6}
                              xs={12}
                              style={{ position: "relative" }}
                            >
                              {/* <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ width: "96%"}}> */}
                              <TextField
                                id="c_schemeId"
                                select
                                label={this.props.translate("schemeID")}
                                value={this.props?.accountsform?.c_schemeId}
                                onChange={this.props.handleChangeAttributes(
                                  "c_schemeId"
                                )}
                                handleChangeAttributes={this.props.handleChangeAttributes(
                                  "c_schemeId"
                                )}
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                                margin="dense"
                                fullWidth
                                required
                              >
                                {c_schemeIdList.map((option) => (
                                  <MenuItem
                                    key={option.key}
                                    value={option.name}
                                  >
                                    {this.props.translate(option.name)}
                                  </MenuItem>
                                ))}
                              </TextField>
                              {/* </div><HtmlTooltip
                              title={
                                <span style={{fontSize:"18px", fontWeight: "bold", width:"200px", height:"150px", padding:"5px 0px" }}> 
                                  {this.props.translate(getTooltipMessage(this.props?.accountsform?.c_schemeId,"c_schemeId"))}

                                </span>
                              }
                              style={{ color: "green" }} // Changed the color to green
                            >
                              <InfoIcon fontSize="large"  style={{ fontSize: 24, color: "grey" , marginLeft:"5px" }} />  
                            </HtmlTooltip></div> */}
                            </Grid>
                          ) : null}
                          {this.props.form.contractType === 3 && (
                            <Grid item md={3} sm={6} xs={12}>
                              <TextField
                                id="agreedAdvancePayment"
                                label={this.props.translate(
                                  "agreedAdvancePayment"
                                )}
                                type="number"
                                placeholder=""
                                value={
                                  this.props?.accountsform
                                    ?.agreedAdvancePayment || ""
                                }
                                onChange={this.props.handleChangeAttributes(
                                  "agreedAdvancePayment"
                                )}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                              />
                            </Grid>
                          )}
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              // required
                              id={`c_${this.props?.accountsform?.c_schemeId?.toLowerCase()}Number`}
                              label={this.props.translate(
                                `c_${this.props?.accountsform?.c_schemeId?.toLowerCase()}Number`
                              )}
                              type="text"
                              placeholder=""
                              value={this.props.newField || ""}
                              onChange={this.props.handleChangeAttributes(
                                `number`
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                            {/* <TextField
                            // required
                            id="c_crNumber"
                            label={this.props.translate("crNumber")}
                            type="text"
                            placeholder=""
                            value={this.props?.accountsform?.c_crNumber || ""}
                            onChange={this.props.handleChangeAttributes(
                              "c_crNumber"
                            )}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                          /> */}
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              // required
                              id="c_installmentPayment"
                              label={this.props.translate("installmentPayment")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform
                                  ?.c_installmentPayment || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_installmentPayment"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              // InputProps={{
                              //   readOnly:
                              //     !this.props.updateOption ||
                              //     checkPrivileges("userUpdate")
                              //       ? false
                              //       : true,
                              // }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              // required
                              id="c_repaymentPeriod"
                              label={this.props.translate("repaymentPeriod")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_repaymentPeriod ||
                                ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_repaymentPeriod"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              // InputProps={{
                              //   readOnly:
                              //     !this.props.updateOption ||
                              //     checkPrivileges("userUpdate")
                              //       ? false
                              //       : true,
                              // }}
                            />
                          </Grid>

                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_taxNumber"
                              label={this.props.translate("taxNumber")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_taxNumber || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_taxNumber"
                              )}
                              required={
                                this.props?.form?.userType === 5 ||
                                this.props?.form?.userType === 6
                              }
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ width: "96%" }}>
                                <TextField
                                  id="c_idIssuer"
                                  select
                                  label={this.props.translate("idIssuer")}
                                  value={this.props?.accountsform?.c_idIssuer}
                                  onChange={this.props.handleChangeAttributes(
                                    "c_idIssuer"
                                  )}
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  margin="dense"
                                  fullWidth
                                  required
                                >
                                  {idIssuerList.map((option) => (
                                    <MenuItem
                                      key={option.key}
                                      value={option.name}
                                    >
                                      {this.props.translate(option.name)}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <HtmlTooltip
                                title={
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      width: "200px",
                                      height: "150px",
                                      padding: "5px 0px",
                                    }}
                                  >
                                    {/* {this.props.translate("111111111")}  */}
                                    {getTooltipMessage(
                                      this.props?.accountsform?.c_idIssuer,
                                      "c_idIssuer"
                                    )}
                                  </span>
                                }
                                style={{ color: "green" }} // Changed the color to green
                              >
                                <InfoIcon
                                  fontSize="large"
                                  style={{
                                    fontSize: 24,
                                    color: "grey",
                                    marginLeft: "5px",
                                  }}
                                />
                              </HtmlTooltip>
                            </div>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ width: "96%" }}>
                                <TextField
                                  id="c_productType"
                                  select
                                  label={this.props.translate("productType")}
                                  value={
                                    this.props?.accountsform?.c_productType
                                  }
                                  onChange={this.props.handleChangeAttributes(
                                    "c_productType"
                                  )}
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  margin="dense"
                                  fullWidth
                                  required
                                >
                                  {productTypeList.map((option) => (
                                    <MenuItem
                                      key={option.key}
                                      value={option.name}
                                    >
                                      {this.props.translate(option.name)}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <HtmlTooltip
                                title={
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      width: "200px",
                                      height: "150px",
                                      padding: "5px 0px",
                                    }}
                                  >
                                    {/* {this.props.translate("111111111")}  */}
                                    {getTooltipMessage(
                                      this.props?.accountsform?.c_productType,
                                      "c_productType"
                                    )}
                                  </span>
                                }
                                style={{ color: "green" }} // Changed the color to green
                              >
                                <InfoIcon
                                  fontSize="large"
                                  style={{
                                    fontSize: 24,
                                    color: "grey",
                                    marginLeft: "5px",
                                  }}
                                />
                              </HtmlTooltip>
                            </div>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ width: "96%" }}>
                                <TextField
                                  id="c_productStatus"
                                  select
                                  label={this.props.translate("productStatus")}
                                  value={
                                    this.props?.accountsform?.c_productStatus
                                  }
                                  onChange={this.props.handleChangeAttributes(
                                    "c_productStatus"
                                  )}
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  margin="dense"
                                  fullWidth
                                  required
                                >
                                  {productStatuesList.map((option) => (
                                    <MenuItem
                                      key={option.key}
                                      value={option.name}
                                    >
                                      {this.props.translate(option.name)}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <HtmlTooltip
                                title={
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      width: "200px",
                                      height: "150px",
                                      padding: "5px 0px",
                                    }}
                                  >
                                    {/* {this.props.translate("111111111")}  */}
                                    {getTooltipMessage(
                                      this.props?.accountsform?.c_productStatus,
                                      "c_productStatus"
                                    )}
                                  </span>
                                }
                                style={{ color: "green" }} // Changed the color to green
                              >
                                <InfoIcon
                                  fontSize="large"
                                  style={{
                                    fontSize: 24,
                                    color: "grey",
                                    marginLeft: "5px",
                                  }}
                                />
                              </HtmlTooltip>
                            </div>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ width: "96%" }}>
                                <TextField
                                  id="c_defaultStatus"
                                  select
                                  label={this.props.translate("defaultStatus")}
                                  value={
                                    this.props?.accountsform?.c_defaultStatus
                                  }
                                  onChange={this.props.handleChangeAttributes(
                                    "c_defaultStatus"
                                  )}
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  margin="dense"
                                  fullWidth
                                  required
                                >
                                  {defaultStatusesList.map((option) => (
                                    <MenuItem
                                      key={option.key}
                                      value={option.name}
                                    >
                                      {this.props.translate(option.name)}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <HtmlTooltip
                                title={
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      width: "200px",
                                      height: "150px",
                                      padding: "5px 0px",
                                    }}
                                  >
                                    {/* {this.props.translate("111111111")}  */}
                                    {getTooltipMessage(
                                      this.props?.accountsform?.c_defaultStatus,
                                      "c_defaultStatus"
                                    )}
                                  </span>
                                }
                                style={{ color: "green" }} // Changed the color to green
                              >
                                <InfoIcon
                                  fontSize="large"
                                  style={{
                                    fontSize: 24,
                                    color: "grey",
                                    marginLeft: "5px",
                                  }}
                                />
                              </HtmlTooltip>
                            </div>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ width: "96%" }}>
                                <TextField
                                  id="c_goodsServices"
                                  select
                                  label={this.props.translate("goodsServices")}
                                  value={
                                    this.props?.accountsform?.c_goodsServices
                                  }
                                  onChange={this.props.handleChangeAttributes(
                                    "c_goodsServices"
                                  )}
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  margin="dense"
                                  fullWidth
                                  required
                                >
                                  {goodsServicesList.map((option) => (
                                    <MenuItem
                                      key={option.key}
                                      value={option.name}
                                    >
                                      {this.props.translate(option.name)}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <HtmlTooltip
                                title={
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      width: "200px",
                                      height: "150px",
                                      padding: "5px 0px",
                                    }}
                                  >
                                    {getTooltipMessage(
                                      this.props?.accountsform?.c_goodsServices,
                                      "c_goodsServices"
                                    )}
                                  </span>
                                }
                                style={{ color: "green" }} // Changed the color to green
                              >
                                <InfoIcon
                                  fontSize="large"
                                  style={{
                                    fontSize: 24,
                                    color: "grey",
                                    marginLeft: "5px",
                                  }}
                                />
                              </HtmlTooltip>
                            </div>
                          </Grid>
                          {/* {console.log("this.props?.accountsform=====", this.props?.accountsform)} */}
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_address"
                              label={this.props.translate("address")}
                              type="text"
                              placeholder=""
                              value={this.props?.accountsform?.c_address || ""}
                              onChange={this.props.handleChangeAttributes(
                                "c_address"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_addressArabic"
                              label={this.props.translate("addressArabic")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_addressArabic || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_addressArabic"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_buildingNo"
                              label={this.props.translate("buildingNo")}
                              type="text"
                              required={
                                this.props?.form?.userType === 5 ||
                                this.props?.form?.userType === 6
                              }
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_buildingNo || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_buildingNo"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          {/* <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_buildingNoArabic"
                              label={this.props.translate("buildingNoArabic")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_buildingNoArabic ||
                                ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_buildingNoArabic"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid> */}
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_streetName"
                              required={
                                this.props?.form?.userType === 5 ||
                                this.props?.form?.userType === 6
                              }
                              label={this.props.translate("streetName")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_streetName || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_streetName"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_streetNameArabic"
                              label={this.props.translate("streetNameArabic")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_streetNameArabic ||
                                ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_streetNameArabic"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_district"
                              label={this.props.translate(
                                "district (City Subdivision)"
                              )}
                              type="text"
                              placeholder=""
                              required={
                                this.props?.form?.userType === 5 ||
                                this.props?.form?.userType === 6
                              }
                              value={this.props?.accountsform?.c_district || ""}
                              onChange={this.props.handleChangeAttributes(
                                "c_district"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_districtArabic"
                              label={this.props.translate("districtArabic")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_districtArabic || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_districtArabic"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_city"
                              required={
                                this.props?.form?.userType === 5 ||
                                this.props?.form?.userType === 6
                              }
                              label={this.props.translate("city")}
                              type="text"
                              placeholder=""
                              value={this.props?.accountsform?.c_city || ""}
                              onChange={this.props.handleChangeAttributes(
                                "c_city"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_cityArabic"
                              label={this.props.translate("cityArabic")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_cityArabic || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_cityArabic"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_country"
                              label={this.props.translate("country")}
                              type="text"
                              required={
                                this.props?.form?.userType === 5 ||
                                this.props?.form?.userType === 6
                              }
                              placeholder=""
                              value={this.props?.accountsform?.c_country || ""}
                              onChange={this.props.handleChangeAttributes(
                                "c_country"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_countryArabic"
                              label={this.props.translate("countryArabic")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_countryArabic || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_countryArabic"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_postalCode"
                              label={this.props.translate("postalCode")}
                              type="text"
                              required={
                                this.props?.form?.userType === 3 ||
                                this.props?.form?.userType === 5 ||
                                this.props?.form?.userType === 6
                              }
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_postalCode || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_postalCode"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_additionalNo"
                              label={this.props.translate("additionalNo")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_additionalNo || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_additionalNo"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_otherSellerID"
                              label={this.props.translate("otherSellerID")}
                              type="text"
                              placeholder=""
                              value={
                                this.props?.accountsform?.c_otherSellerID || ""
                              }
                              onChange={this.props.handleChangeAttributes(
                                "c_otherSellerID"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          {/* <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="c_VATNumber"
                        label={this.props.translate("VATNumber")}
                        type="text"
                        placeholder=""
                        value={this.props?.accountsform?.c_VATNumber || ""}
                        onChange={this.props.handleChangeAttributes(
                          "c_VATNumber"
                        )}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges("userUpdate")
                              ? false
                              : true,
                        }}
                      />
                    </Grid> */}
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField
                              id="c_Location"
                              label={this.props.translate("Region")}
                              type="text"
                              placeholder=""
                              value={this.props?.accountsform?.c_Location || ""}
                              onChange={this.props.handleChangeAttributes(
                                "c_Location"
                              )}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly:
                                  !this.props.updateOption ||
                                  checkPrivileges("userUpdate")
                                    ? false
                                    : true,
                              }}
                            />
                          </Grid>
                          {/* <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="c_contractComponents"
                        label={this.props.translate("contractComponents")}
                        type="text"
                        placeholder=""
                        value={
                          this.props?.accountsform?.c_contractComponents || ""
                        }
                        onChange={this.props.handleChangeAttributes(
                          "c_contractComponents"
                        )}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges("userUpdate")
                              ? false
                              : true,
                        }}
                      />
                    </Grid> */}
                          {/* <Grid item md={3} sm={6} xs={12}>
                <TextField
                  id='termsConditions'
                  label={this.props.translate('termsConditions')}
                  type='text'
                  placeholder=''
                  value={this.props.form?.termsConditions || ''}
                  onChange={this.props.handleChange('termsConditions')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly:
                      !this.props.updateOption || checkPrivileges('accountCustomerAndContractNumUpdate')
                        ? false
                        : true
                  }}
                />
              </Grid> */}
                          {/* <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="c_pricing"
                        label={this.props.translate("pricing")}
                        type="text"
                        placeholder=""
                        value={this.props.accountsform?.c_pricing || ""}
                        onChange={this.props.handleChangeAttributes("c_pricing")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges(
                              "accountCustomerAndContractNumUpdate"
                            )
                              ? false
                              : true,
                        }}
                      />
                    </Grid> */}
                        </>
                      )}

                    {(this.props.form.userType === 1 ||
                      this.props.form.userType === 2 ||
                      this.props.form.userType === 3) && (
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField
                          id="monthlyRevenueTarget"
                          label={this.props.translate("monthlyRevenueTarget")}
                          type="number"
                          placeholder=""
                          value={this.props.form?.monthlyRevenueTarget || ""}
                          onChange={this.props.handleChange(
                            "monthlyRevenueTarget"
                          )}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          // InputProps={{
                          //   readOnly:
                          //     !this.props.updateOption || checkPrivileges('accountCustomerAndContractNumUpdate')
                          //       ? false
                          //       : true
                          // }}
                        />
                      </Grid>
                    )}
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="yearlyRevenueTarget"
                        label={this.props.translate("yearlyRevenueTarget")}
                        type="number"
                        placeholder=""
                        value={this.props.form?.yearlyRevenueTarget || ""}
                        onChange={this.props.handleChange(
                          "yearlyRevenueTarget"
                        )}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          readOnly:
                            !this.props.updateOption ||
                            checkPrivileges("userUpdate")
                              ? false
                              : true,
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="status"
                        select
                        label={this.props.translate("status")}
                        value={this.props.form.status || ""}
                        onChange={this.props.handleChange("status")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="dense"
                        fullWidth
                        // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                      >
                        {statuses.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {this.props.form.userType &&
                      this.props.form.userType !== 4 && (
                        <>
                          <Grid item md={3} sm={6} xs={12}>
                            {/* {  console.log("this.props.contractEndDateError====", this.props.contractEndDateError)} */}
                            <CustomDatePicker
                              format="dd/MM/yyyy"
                              // error={false}
                              label={this.props.translate(
                                "defaultSatisfactionDate"
                              )}
                              fullWidth
                              value={
                                this.props &&
                                this.props.form &&
                                this.props.form.defaultSatisfactionDate
                                  ? new Date(
                                      `${this.props.form.defaultSatisfactionDate}`
                                    )
                                  : null
                              }
                              onChange={this.props.handleChange(
                                "defaultSatisfactionDate"
                              )}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <CustomDatePicker
                              format="dd/MM/yyyy"
                              // error={false}
                              label={this.props.translate("settleDate")}
                              fullWidth
                              value={
                                this.props &&
                                this.props.form &&
                                this.props.form.settleDate
                                  ? new Date(`${this.props.form.settleDate}`)
                                  : null
                              }
                              onChange={this.props.handleChange("settleDate")}
                            />
                          </Grid>

                          <Grid item md={3} sm={6} xs={12}>
                            <CustomDatePicker
                              error={false}
                              format="dd/MM/yyyy"
                              label={this.props.translate("contractStartDate")}
                              fullWidth
                              value={
                                this.props &&
                                this.props.form &&
                                this.props.form.contractStartDate
                                  ? `${this.props.form.contractStartDate}`
                                  : null
                              }
                              onChange={this.props.handleChange(
                                "contractStartDate"
                              )}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <CustomDatePicker
                              format="dd/MM/yyyy"
                              label={this.props.translate("contractEndDate")}
                              fullWidth
                              value={
                                this.props &&
                                this.props.form &&
                                this.props.form.contractEndDate
                                  ? new Date(
                                      `${this.props.form.contractEndDate}`
                                    )
                                  : null
                              }
                              onChange={this.props.handleChange(
                                "contractEndDate"
                              )}
                              error={
                                this.props && this.props.contractEndDateError
                              }
                              // helperText={
                              //   this.props && this.props.contractEndDateError
                              //     ? "Contract end date must be after contract start date"
                              //     : ""
                              // }
                            />
                          </Grid>
                        </>
                      )}
                  </Grid>{" "}
                </>
              ) : (
                ""
              )}

              {/* {this.props.form.userType && (<> */}
              <h4>{this.props.translate("userDetail")}</h4>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="name"
                    label={this.props.translate("sharedName")}
                    type="text"
                    placeholder=""
                    value={
                      this.props.userTypeValue === 4
                        ? this.props.form.name
                          ? this.props.form.name
                          : this.props.userDetailsNameValue
                        : this.props.form.name
                    }
                    onChange={this.props.handleChange("name")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="email"
                    error={
                      this.props.duplicateEntry || this.props.emailInvalidError
                    }
                    label={this.props.translate("userEmail")}
                    type="email"
                    placeholder=""
                    value={
                      this.props.userTypeValue === 4
                        ? this.props.form.email
                          ? this.props.form.email
                          : this.props.userDetailsEmailValue
                        : this.props.form.email
                    }
                    onChange={this.props.handleChange("email")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                  />
                  <FormHelperText
                    error={this.props.emailInvalidError ? true : false}
                  >
                    {this.props.translate("Please enter a valid email address")}
                  </FormHelperText>
                </Grid>
                {!this.props.editOption && (
                  <>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="password"
                        label={this.props.translate("userPassword")}
                        type="password"
                        placeholder=""
                        value={this.props.form.password}
                        onChange={this.props.handleChange("password")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                      />
                                                       <PasswordRequirements
          hasLengthValid={this.props.hasLengthValid}
          hasNumberValid={this.props.hasNumberValid}
          hasUpperCaseValid={this.props.hasUpperCaseValid}
          hasLowerCaseValid={this.props.hasLowerCaseValid}
          hasSpecialCharValid={this.props.hasSpecialCharValid}
          translate={this.props.translate}
        />
                      
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="confirmPassword"
                        label={this.props.translate("confirmPassword")}
                        type="password"
                        error={
                          !(this.props.isConfirmPassChk)
                        }
                        placeholder=""
                        value={this.props.form.confirmPassword}
                        onChange={this.props.handleChange("confirmPassword")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                      />
                                                  <FormHelperText style={{ color: this.props.themecolors.textColor }}>
                                <ValidationIcon
                                  isValid={this.props.isConfirmPassChk}
                                  message={this.props.translate('Both Passwords must be Equal')}
                                />
                              </FormHelperText>
                    </Grid>
                    {this.props.userType === -1 && (
                      <>
                        <Grid item sm={6} md={3} xs={12} style={{ zIndex: 99 }}>
                          <AsyncSelect
                            isMulti={false}
                            value={this.props.accountSelectedData}
                            {...this.props}
                            selectName="accountId"
                            placeholder={this.props.translate("searchAccount")}
                            resultMessage={this.state.resultMessage}
                            defaultOptions={this.props.defaultAccountsList}
                            handleChange={this.props.timezone_handleChange}
                            loadOptions={this.searchAccountsList.bind()}
                          />
                        </Grid>
                        <Grid item sm={6} md={3} xs={12} style={{ zIndex: 99 }}>
                          <AsyncSelect
                            // isDisabled={this.props.loadingUser ? true : false}
                            isMulti={false}
                            value={this.props.userSelectedData}
                            {...this.props}
                            selectName="parentId"
                            placeholder={this.props.translate("searchUsers")}
                            resultMessage={this.state.resultMessage}
                            defaultOptions={this.props.defaultUsersList}
                            handleChange={this.props.timezone_handleChange}
                            loadOptions={this.searchUsersList.bind()}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="city"
                    label={this.props.translate("city")}
                    type="city"
                    placeholder=""
                    value={this.props.form.city || ""}
                    onChange={this.props.handleChange("city")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                {!this.state.showButton && this.props.roleTypes.length ? (
                  <Grid
                    item
                    md={3}
                    sm={6}
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span
                      onClick={(e) => this.toggleRoles(e)}
                      style={{ cursor: "pointer" }}
                    >
                      <b>{this.props.translate("selectCustomRole")}</b>
                    </span>
                  </Grid>
                ) : (
                  ""
                )}
                {checkPrivileges("role") && this.state.showButton && (
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id="role"
                      select
                      label={this.props.translate("role")}
                      value={this.props.form.roleId || ""}
                      onChange={this.props.handleChange("roleId")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="dense"
                      fullWidth
                    >
                      {/* {!this.props.editOption && 
                              (<MenuItem key={'defaultRole'} value={'defaultRole'} >
                                <em>{this.props.translate('default')}</em>
                              </MenuItem>
                              )}
                            {!this.props.editOption && <Divider style={{background:'black'}}/> } */}

                      {this.props.roleTypes.length ? (
                        this.props.roleTypes.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">
                          <em>{this.props.translate("none")}</em>
                        </MenuItem>
                      )}
                    </TextField>
                    {!this.props.editOption ? (
                      <span
                        onClick={(e) => this.toggleRoles(e)}
                        style={{ cursor: "pointer" }}
                      >
                        <b>Use Default Role</b>
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}
              </Grid>
              <h4>{this.props.translate("sharedPreferences")}</h4>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="phone"
                    label={this.props.translate("sharedPhone")}
                    type="text"
                    placeholder=""
                    value={this.props.form.phone}
                    onChange={this.props.handleChange("phone")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="coordinateFormat"
                    select
                    label={this.props.translate("CoordinateFormat")}
                    value={this.props.form.coordinateFormat || ""}
                    onChange={this.props.handleChange("coordinateFormat")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="dense"
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>{this.props.translate("none")}</em>
                    </MenuItem>
                    {coordinateFormatTypes.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="map"
                    select
                    label={this.props.translate("mapTitle")}
                    value={this.props.form.map || ""}
                    onChange={this.props.handleChange("map")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="dense"
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>{this.props.translate("none")}</em>
                    </MenuItem>
                    {mapLayerTypes.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="latitude"
                    type="number"
                    label={this.props.translate("positionLatitude")}
                    placeholder=""
                    value={this.props.form.latitude || ""}
                    onChange={this.props.handleChange("latitude")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="longitude"
                    type="number"
                    label={this.props.translate("positionLongitude")}
                    value={this.props.form.longitude || ""}
                    onChange={this.props.handleChange("longitude")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="zoom"
                    label={this.props.translate("serverZoom")}
                    type="number"
                    placeholder=""
                    value={this.props.form.zoom}
                    error={
                      this.props.form.zoom > 19 || this.props.form.zoom < 3
                        ? true
                        : false
                    }
                    helperText={this.props.translate("enterValue3to18")}
                    onChange={this.props.handleChange("zoom")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Tooltip title="Add Location">
                    <IconButton onClick={this.props.handleClickShowPostion}>
                      {<EditIcon />}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <h4>{this.props.translate("sharedPermissions")}</h4>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <CustomDatePicker
                    format="dd/MM/yyyy"
                    error={false}
                    label={this.props.translate("userExpirationTime")}
                    minDate={new Date()} // for check not to select date before today
                    fullWidth={true}
                    value={
                      this.props.form && this.props.form.expirationTime
                        ? `${this.props.form.expirationTime}`
                        : null
                    }
                    onChange={this.props.handleChange("expirationTime")}
                  />
                  {this.props.form.expirationTime && (
                    <Tooltip title="Set No Limit">
                      <a
                        href="javascript:void(0)"
                        onClick={(e) => this.props.setExpirationTime(e)}
                      >
                        No Limit
                      </a>
                    </Tooltip>
                  )}
                </Grid>

                {this.props.form.userType === 4 && (
                  <Grid item xl={3} sm={6} md={4} xs={12}>
                    <Checkbox
                      checked={
                        (this.props.unlimitedLimit &&
                          this.props.unlimitedLimit) ||
                        (this.props.form.deviceLimit === -1 &&
                          this.props.form.userLimit === -1 &&
                          this.props.form.vehicleLimit === -1)
                      }
                      onChange={(e) =>
                        this.props.changeLimit("unlimitedLimit", e)
                      }
                      label={this.props.translate("unlimitedLimit")}
                    />
                  </Grid>
                )}

                {this.props.form &&
                  this.props.form.userType &&
                  this.props.form.userType === 1 && (
                    <>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField
                          error={this.props.deviceLimitError}
                          id="deviceLimit"
                          label={this.props.translate("userDeviceLimit")}
                          type="number"
                          placeholder=""
                          value={this.props.form.deviceLimit || 0}
                          onChange={this.props.handleChange("deviceLimit")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField
                          id="vehicleLimit"
                          error={this.props.vehicleLimitError}
                          label={this.props.translate("userVehicleLimit")}
                          type="number"
                          placeholder=""
                          value={this.props.form.vehicleLimit || 0}
                          onChange={this.props.handleChange("vehicleLimit")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                {this.props.form &&
                  this.props.form.userType &&
                  this.props.form.userType !== 1 && (
                    <>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField
                          error={this.props.deviceLimitError}
                          id="deviceLimit"
                          label={this.props.translate("userDeviceLimit")}
                          type="number"
                          placeholder=""
                          value={this.props.form.deviceLimit || 0}
                          onChange={this.props.handleChange("deviceLimit")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          // helperText={this.props.logInUser.userType !==-1 ?
                          //   `Remaining Units ${this.props.form.deviceLimit>0?this.props.deviceLimit - this.props.form.deviceLimit:this.props.deviceLimit }`
                          //     : null}
                          // helperText={
                          //   this.props.deviceLimitError
                          //     ? this.props.translate(
                          //         this.props.unitsLimitsMeaasge
                          //       )
                          //     : this.props.addOption && this.props.logInUser.userType !==-1 ?
                          //     `Remaining Units ${this.props.form.deviceLimit>0?this.props.deviceLimit - this.props.form.deviceLimit:this.props.deviceLimit }`
                          //     : this.props.editOption && this.props.selectedUser.parentId !==1 ?
                          //     `Remaining Units ${this.props.form.deviceLimit>0?this.props.deviceLimit - this.props.form.deviceLimit:this.props.deviceLimit }`
                          //    : null
                          // }
                          // helperText={
                          //   this.props.deviceLimitError
                          //     ? this.props.translate(
                          //         this.props.unitsLimitsMeaasge
                          //       )
                          //     : this.props.addOption &&
                          //       this.props.logInUser.userType !== -1
                          //     ? `Remaining Units ${
                          //         this.props.form.deviceLimit > 0
                          //           ? this.props.deviceLimit -
                          //             this.props.form.deviceLimit
                          //           : this.props.deviceLimit
                          //       }`
                          //     : this.props.editOption &&
                          //       this.props.selectedUser.parentId !== 1
                          //     ? `Created Units ${this.props.createdUnits}`
                          //     : null
                          // }
                        />
                        {!this.props.deviceLimitError &&
                          this.props.editOption &&
                          this.props.form.deviceLimit !== -1 &&
                          (this.props.selectedUnitsLimits.count ||
                            this.props.selectedUnitsLimits.count === 0) && (
                            <Tooltip
                              title={this.props.translate("remainingUnits")}
                            >
                              <a>
                                {this.props.translate("remaining")} :{" "}
                                {this.props.form.deviceLimit -
                                  (this.props.selectedUnitsLimits.count +
                                    this.props.selectedUnitsLimits
                                      .reserveLimit)}
                              </a>
                            </Tooltip>
                          )}
                      </Grid>
                      {this.props.logInUser.userType !== 2 && (
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField
                            id="userLimit"
                            error={this.props.userLimitError}
                            label={this.props.translate("userUserLimit")}
                            type="number"
                            placeholder=""
                            value={this.props.form.userLimit || 0}
                            onChange={this.props.handleChange("userLimit")}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            // helperText={
                            //   this.props.userLimitError
                            //     ? this.props.translate(
                            //         this.props.usersLimitsMeaasge
                            //       )
                            //     : this.props.addOption &&
                            //       this.props.logInUser.userType !== -1
                            //     ? `Remaining Users ${
                            //         this.props.form.userLimit > 0
                            //           ? this.props.usersLimit -
                            //             this.props.form.userLimit
                            //           : this.props.usersLimit
                            //       }`
                            //     : this.props.editOption &&
                            //       this.props.selectedUser.parentId !== 1
                            //     ? `Created Users ${this.props.createdUsers}`
                            //     : null
                            // }

                            // helperText={this.props.logInUser.userType !==-1 ?
                            //   `Remaining Users ${this.props.form.userLimit>0 ? this.props.usersLimit - this.props.form.userLimit:this.props.usersLimit }`
                            //     : null
                            // }
                          />
                          {!this.props.userLimitError &&
                            this.props.editOption &&
                            this.props.form.userLimit !== -1 &&
                            (this.props.selectedUserLimits.count ||
                              this.props.selectedUserLimits.count === 0) && (
                              <Tooltip
                                title={this.props.translate("remainingUser")}
                              >
                                <a>
                                  {this.props.translate("remaining")} :{" "}
                                  {this.props.form.userLimit -
                                    (this.props.selectedUserLimits.count +
                                      this.props.selectedUserLimits
                                        .reserveLimit)}
                                </a>
                              </Tooltip>
                            )}
                        </Grid>
                      )}
                      {this.props.logInUser.userType !== 2 && (
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField
                            id="vehicleLimit"
                            error={this.props.vehicleLimitError}
                            label={this.props.translate("userVehicleLimit")}
                            type="number"
                            placeholder=""
                            value={this.props.form.vehicleLimit || 0}
                            onChange={this.props.handleChange("vehicleLimit")}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            // helperText={
                            //   this.props.vehicleLimitError
                            //     ? this.props.translate(
                            //         this.props.vehicleLimitsMeaasge
                            //       )
                            //     : this.props.addOption &&
                            //       this.props.logInUser.userType !== -1
                            //     ? `Remaining Vehicles ${
                            //         this.props.form.vehicleLimit > 0
                            //           ? this.props.vehicleLimit -
                            //             this.props.form.vehicleLimit
                            //           : this.props.vehicleLimit
                            //       }`
                            //     : this.props.editOption &&
                            //       this.props.selectedUser.parentId !== 1
                            //     ? `Created Vehicles ${this.props.createdUsers}`
                            //     : null
                            // }

                            // helperText={this.props.logInUser.userType !==-1 ?
                            //   `Remaining Users ${this.props.form.userLimit>0 ? this.props.usersLimit - this.props.form.userLimit:this.props.usersLimit }`
                            //     : null
                            // }
                          />
                          {!this.props.vehicleLimitError &&
                            this.props.editOption &&
                            this.props.form.vehicleLimit !== -1 &&
                            (this.props.selectedUserLimits.count ||
                              this.props.selectedUserLimits.count === 0) && (
                              <Tooltip
                                title={this.props.translate("remainingUser")}
                              >
                                <a>
                                  {this.props.translate("remaining")} :{" "}
                                  {this.props.form.vehicleLimit -
                                    (this.props.selectedUserLimits.count +
                                      this.props.selectedUserLimits
                                        .reserveLimit)}
                                </a>
                              </Tooltip>
                            )}
                        </Grid>
                      )}
                    </>
                  )}
              </Grid>
              <h4 style={{ margin: "10px 0" }}>
                {this.props.translate("sharedAttributes")}
              </h4>
              <Grid container spacing={2}>
                {/* <Grid item sm={3} xs={12}>
                  <TextField
                    id='web.liveRouteLength'
                    label={this.props.translate('attributeWebLiveRouteLength')}
                    placeholder=''
                    type='number'
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes['web.liveRouteLength']) ||
                      ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('web.liveRouteLength', e)
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                {/* <Grid item sm={3} xs={12}>
                  <TextField
                    id='web.maxZoom'
                    type='number'
                    label={this.props.translate('attributeWebMaxZoom')}
                    placeholder=''
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes['web.maxZoom']) ||
                      ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('web.maxZoom', e)
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                {/* <Grid item sm={3} xs={12}>
                  <TextField
                    id='web.selectZoom'
                    type='number'
                    label={this.props.translate('attributeWebSelectZoom')}
                    placeholder=''
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes['web.selectZoom']) ||
                      ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('web.selectZoom', e)
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                <Grid item sm={3} xs={12}>
                  <SingleSelect
                    array={defaultZones}
                    async
                    selectName="timezone"
                    isClearable
                    // defaultOptions={defaultZones}
                    loadOptions={this.searchFromZoneList}
                    label={this.props.translate("sharedTimezone")}
                    value={
                      this.props.form.attributes &&
                      this.props.form.attributes["timezone"]
                        ? timeZonesTypes.find(
                            (t) => t.key === this.props.form.attributes.timezone
                          )
                        : ""
                    }
                    handleChange={this.props.timezone_handleChange}
                    canAssign={true}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="measurementStandard"
                    select
                    label={this.props.translate("measurementStandard")}
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.measurementStandard) ||
                      ""
                    }
                    onChange={(e) =>
                      this.props.changeAttribute2("measurementStandard", e)
                    }
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="dense"
                    fullWidth
                  >
                    {measurementStandard.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.value)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xl={3}
                  sm={6}
                  md={4}
                  xs={12}
                  style={{ marginTop: 0, maxWidth: "25%" }}
                >
                  <TextField
                    id="currencyLabel"
                    select
                    label={this.props.translate("currencies")}
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.currencyLabel) ||
                      ""
                    }
                    onChange={(e) =>
                      this.props.changeAttribute2("currencyLabel", e)
                    }
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="dense"
                    fullWidth
                  >
                    {currencies &&
                      currencies.map((option) => (
                        <MenuItem key={option.key} value={option.value}>
                          {this.props.translate(option.key)}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xl={3} sm={6} md={4} xs={12}>
                  <Checkbox
                    checked={
                      this.props.form.attributes &&
                      this.props.form.attributes.viewModel
                    }
                    onChange={(e) =>
                      this.props.changeAttribute2("viewModel", e)
                    }
                    canAssign
                    canRemove
                    label={this.props.translate("viewModel")}
                  />
                </Grid>

                {/* <Grid item sm={6} xs={12}>
                    <div style={{ display: 'inline-flex', flexWrap: 'wrap', marginTop: '15px' }}> */}
                {/* {this.props?.form?.attributes?.images?.map((imageUrl, key) => (
                            <div style={{ position: 'relative', margin: '0 15px 15px 0' }}>
                              <Tooltip
                                key={key}
                                placement='right-end'
                                title={this.props.translate('clickForPreview')}
                              >
                                <img
                                  style={{ width: 120, height: '100px', cursor: 'pointer' }}
                                  alt={`driverImage-${key}`}
                                  src={`data:image/jpeg;base64,${imageUrl?.data}` || ''}
                                  // onClick={() => this.props.imagePopup(this.props.form.driverImg[key])}
                                />
                              </Tooltip>
                              <CloseIcon 
                                style={{ position: 'absolute', top: '-10px', right: '-10px', cursor: 'pointer', zIndex: '99', background: '#fff', borderRadius: '50%' }} 
                                onClick={() => this.props.removeImage(key)}
                              />
                            </div>
                          ))} */}
                {/* </div>
                  </Grid>  */}

                <Grid item xl={3} sm={6} md={4} xs={12}>
                  <Checkbox
                    checked={
                      this.props.form.attributes &&
                      this.props.form.attributes.viewIMEI
                    }
                    onChange={(e) => this.props.changeAttribute2("viewIMEI", e)}
                    canAssign
                    canRemove
                    label={this.props.translate("viewIMEI")}
                  />
                </Grid>
                {this.props.editOption ? (
                  <Grid item xs={12} style={{ marginBottom: "10px" }}>
                    <Button
                      variant="contained"
                      margin="dense"
                      component="label"
                      onChange={(e) => this.props.uploadFiles(e)}
                    >
                      <Tooltip title="Upload Image">
                        <input
                          type="file"
                          hidden
                          multiple
                          accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        />
                        {this.props.translate("uploadImagesDoc")}{" "}
                        <PublishIcon
                          style={{ color: "#fff", marginLeft: 15 }}
                        />
                      </Tooltip>
                    </Button>
                    <div style={{ display: "block" }}>
                      <div
                        style={{
                          display: "inline-flex",
                          flexWrap: "wrap",
                          marginTop: "15px",
                        }}
                      >
                        {this.props.userFilesData &&
                        this.props.userFilesData.length > 0
                          ? this.props.userFilesData.map((data, key) => (
                              <div
                                style={{
                                  position: "relative",
                                  margin: "15px 15px 20px 0",
                                }}
                                key={key}
                              >
                                <Tooltip
                                  placement="right-end"
                                  title={data.fileName}
                                >
                                  <a
                                    style={{
                                      padding: "10px",
                                      background: "#fff",
                                      borderRadius: "5px",
                                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                      cursor: "pointer",
                                      textDecoration: "none",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width: "135px",
                                      display: "block",
                                      textAlign: "center",
                                    }}
                                    download={data.fileName}
                                    href={data.url}
                                    target={"_blank"}
                                  >
                                    {data.fileName}
                                  </a>
                                </Tooltip>
                                <CancelIcon
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-10px",
                                    cursor: "pointer",
                                    zIndex: "99",
                                    background: "#fff",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() =>
                                    this.props.removeUserFile(data.id)
                                  }
                                />
                              </div>
                            ))
                          : null}
                        {this.props.uploadDoc && this.props.uploadDoc.length > 0
                          ? this.props.uploadDoc.map((data, key) => (
                              <div
                                style={{
                                  position: "relative",
                                  margin: "15px 15px 20px 0",
                                }}
                                key={key}
                              >
                                <Tooltip placement="right-end" title={data}>
                                  <a
                                    style={{
                                      padding: "10px",
                                      background: "#fff",
                                      borderRadius: "5px",
                                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                      cursor: "pointer",
                                      textDecoration: "none",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width: "135px",
                                      display: "block",
                                      textAlign: "center",
                                    }}
                                  >
                                    {data}
                                  </a>
                                </Tooltip>
                                <CancelIcon
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-10px",
                                    cursor: "pointer",
                                    zIndex: "99",
                                    background: "#fff",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => this.props.removeFiles(key)}
                                />
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </Grid>
                ) : null}
                {/* {this.props.form.attributes && this.props.form.attributes.measurementStandard &&
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id='distanceUnit'
                      select
                      label={this.props.translate('settingsDistanceUnit')}
                      value={
                        (this.props.form.attributes &&
                          this.props.form.attributes.distanceUnit) ||
                        ''
                      }
                      onChange={e =>
                        this.props.changeAttribute2('distanceUnit', e)
                      }
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin='dense'
                      fullWidth
                    >
                      {checkUnitsWithStandardType(
                        this.props.form.attributes.measurementStandard, 'distanceUnit').map(option => (
                          <MenuItem key={option.key} value={option.key}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>}
                {this.props.form.attributes && this.props.form.attributes.measurementStandard &&
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id='speedUnit'
                      select
                      label={this.props.translate('settingsSpeedUnit')}
                      value={
                        (this.props.form.attributes &&
                          this.props.form.attributes.speedUnit) ||
                        ''
                      }
                      onChange={e => this.props.changeAttribute2('speedUnit', e)}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin='dense'
                      fullWidth
                    >
                      {checkUnitsWithStandardType(
                        this.props.form.attributes.measurementStandard, 'speedUnit').map(option => (
                          <MenuItem key={option.key} value={option.key}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>}
                {this.props.form.attributes && this.props.form.attributes.measurementStandard &&
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id='volumeUnit'
                      select
                      label={this.props.translate('settingsVolumeUnit')}
                      value={
                        (this.props.form.attributes &&
                          this.props.form.attributes.volumeUnit) ||
                        ''
                      }
                      onChange={e => this.props.changeAttribute2('volumeUnit', e)}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin='dense'
                      fullWidth
                    >
                      {checkUnitsWithStandardType(
                        this.props.form.attributes.measurementStandard, 'volumeUnit').map(option => (
                          <MenuItem key={option.key} value={option.key}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>} */}
              </Grid>
              <Grid container spacing={2}>
                {this.props.editOption ? (
                  <Grid item md={6} sm={4} xs={6}>
                    <div className="license-card clearfix">
                      <Grid container spacing={16}>
                        <Grid item xs={12}>
                          <label style={{ fontSize: 10 }}>
                            {this.props.translate("userToken")}
                          </label>
                          <Button
                            className="pull-right button-white"
                            onClick={this.props.generateToken}
                          >
                            <Tooltip title={this.props.translate("getToken")}>
                              {<RefreshIcon />}
                            </Tooltip>
                          </Button>
                          <p onClick={(e) => selectMe(e)}>
                            <code style={{ wordBreak: "break-all" }}>
                              {this.props.form.token &&
                                this.props.ServerSetting &&
                                this.props.ServerSetting.attributes &&
                                (this.props.ServerSetting.attributes.adminUrl +
                                  "/login?&" +
                                  btoa(this.props.form.token) ||
                                  "")}
                            </code>
                          </p>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                ) : (
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      label={this.props.translate("userToken")}
                      value={this.props.form.token || ""}
                      onChange={this.props.handleChange("Token")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title={this.props.translate("getToken")}>
                              <IconButton onClick={this.props.generateToken}>
                                {<RefreshIcon />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.props.form.token && (
                      <Tooltip title={this.props.translate("setNoToken")}>
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => this.props.setNoToken(e)}
                        >
                          {this.props.translate("noToken")}
                        </a>
                      </Tooltip>
                    )}
                  </Grid>
                )}
              </Grid>
              {/* </>)} */}
            </Fragment>
          }
        />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(addUserModal));

import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Layout/Loader";
import axios from "axios";
import TextField from "../../Components/common/TextField";
import { FormControlLabel, Grid } from "@mui/material";
import Button from "../../Components/common/Button";
import InvoiceModal from "../../Components/DeliveryNote";
import Table from "../../Components/common/tableWithBackEndPagination";
import Table1 from "../../Components/common/TableServices";
import ExportImportMenu from "../../Components/common/ExportImportMenu";
import CustomDialog from "../../Components/common/Dialog";
import { checkPrivileges } from "../../Helpers";
import withRoutes from "../../HOC/HocRoutes";
import Checkbox from "../../Components/common/Checkbox";
import MenuItem from "../../Components/common/MenuItem";
import UnitMenu from "../../Components/common/CommonMenu";

// import TableServices from "../../Components/common/TableServices";
let source;
let expenseMainTypes = [];
let expenseType = [];
let garageList = [];
let servicesList = [];

class ContractManagment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisable: false,
      // loader: true,
      activeOperation: "",
      selectedItem: "",
      itemPrivileges: [],
      selectedAccount: {},
      selectedServices: {},
      selectedSubTypesAllServices: {},
      tableData: [],
      openTable: false,
      rowsPerPage: 5,
      openExpenseForm: false,
      expenseForm: {},
      isVisableSubmitBtn: true,
      mode: "create",
      onDeleteConfirmation: false,
      selectedServiceTypeId: "",
      invoicesData: [],
      searchInvoice: "",
      deliveryNoteNumber: "",
      deliveryNoteRefNumber: "",
      invoicesList: [],
      filteredData: false,
      loading: false,
      to: "",
      from: "",
      account: "",
      vehcile: "",
      area: "",
      showServices: false,
      page: 1,
      pageSize: 20,
      invoiceData: {},
      value: "maintenance",
      searchInvoiceText: "",
      openFilter: false,
      selectSingleInvoice: false,
      filterInvoices: "",
      contractNumber: "",
      leasing: false,
      showImportedInvoiceError: false,
      importedInvoiceData: {},
      singleAccount: false,
      showRegeneratedModal: false,
      bulkInvoicesDate: "",
      bulkInvoiceLoader: true,
      newTableData: [],
      accounts: "",
      contractType: 1,
      loader: false,
      loader1: false,
      servicesList: "",
      expenseMainTypes: [],
      searchType: "",

      countChecked: 0,
      anchorEl: null,
      statusFilter: [],
    };

    this.getGarageList = this.getGarageList.bind(this);
    this.selecteFilters = this.selecteFilters.bind(this);
  }
  // componentWillMount() {
  //   // this.props.dispatch(toast.removeAll());

  //   // expenseMainTypes = [];
  //   this.getContractsData();
  // }

  componentDidMount() {
    this.setState(
      {
        statusFilter: { 3: true },
        page: 1,
        countChecked: 1,
        pageSize: 20,
        value: "maintenance", // Example default value
        userTypes: "&userTypes=3", // Default userTypes
      },
      () => {
        this.getContractsData();
      }
    );
  }
  handleChangeTabs = (e) => {
    if (e === "leasing") {
      this.setState(
        {
          value: e,
          leasing: true,
          // cashCustomer:false
        },
        () => {
          this.getContractsData();
        }
      );
    } else if (e === "maintenance") {
      this.setState(
        {
          value: e,
          leasing: false,
          // cashCustomer:false
        },
        () => {
          this.getContractsData();
        }
      );
    } else if (e === "cashCustomer") {
      this.setState(
        {
          value: e,
          leasing: false,
          // cashCustomer:true
        },
        () => {
          this.getContractsData();
        }
      );
    }
  };
  getGarageList = async (id) => {
    let url = id ? `api/garages?get&userId=${id}` : `/api/garages`;
    let result = await axios.get(url);
    garageList =
      result &&
      result.data &&
      result.data.data &&
      result.data.data.map((item) => {
        return {
          id: item.id,
          key: item.id,
          name: item.name,
          label: item.name,
          uniqueId: item.id,
          valueType: "string",
        };
      });
    this.setState({ garageList: garageList });
  };

  getServicesList = async (id) => {
    let url = id
      ? `/api/services/due?&userId=${id}&page=1&limit=20`
      : `/api/services/due?page=1&limit=20`;
    // /api/services/due?page=1&limit=20
    let result = await axios.get(url);
    servicesList =
      result &&
      result.data &&
      result.data.data &&
      result.data.data.data &&
      result.data.data.data.map((item) => {
        return {
          id: item.id,
          key: item.id,
          name: item.name,
          label: item.name,
          uniqueId: item.id,
          valueType: "string",
        };
      });

    this.setState({ servicesList: servicesList });
  };

  // componentDidMount() {

  // }

  addItem = () => {
    this.setState({
      isVisable: true,
      activeOperation: "add",
      selectedItem: "",
    });
  };
  removedItem = (item) => {
    this.setState({
      isVisable: true,
      activeOperation: "remove",
      selectedItem: item,
    });
  };
  editItem = (item) => {
    this.setState({
      isVisable: true,
      activeOperation: "edit",
      selectedItem: item,
    });
  };

  // ----------------------------- Filtered Invoices -----------------------------------
  selecteFilters = async (
    to,
    from,
    account,
    vehcile,
    area,
    invoiceStatus,
    stationCity,
    garageVerticals,
    invoicemades
  ) => {
    this.setState(
      {
        // selectedAccount:item,
        to: to,
        from: from,
        account: account,
        vehcile: vehcile,
        area: area,
        page: 1,
        pageSize: 20,
        itemSearch: "",
        filteredData: true,
        invoiceStatus: invoiceStatus,
        stationCity: stationCity,
        garageVerticals: garageVerticals,
        invoicemades: invoicemades,
      },
      () => {
        this.getInvoices(to, from, account, vehcile, area);
      }
    );
  };

  getInvoices = (to, from, account, vehcile, area) => {
    let {
      page,
      pageSize,
      value,
      selectSingleInvoice,
      deliveryNoteRefNumber,
      deliveryNoteNumber,
      invoiceStatus,
      stationCity,
      garageVerticals,
      invoicemades,
    } = this.state;

    let selectedInvoice =
      value === "maintenance"
        ? "deliverynotes"
        : value === "leasing"
        ? "leaseinvoices"
        : value === "cashCustomer"
        ? "cashinvoices"
        : "";
    let apiUrl;
    if (selectSingleInvoice) {
      if (to) {
        apiUrl = `/api/${selectedInvoice}/get?&to=${to}&from=${from}${account}${vehcile}${area}&page=${page}&limit=${pageSize}&deliveryNoteNumber=${deliveryNoteNumber}&deliveryNoteRefNumber=${deliveryNoteRefNumber}`;
      } else {
        apiUrl = `/api/${selectedInvoice}/get?&contractNumber=${account}&page=${page}&limit=${pageSize}&deliveryNoteNumber=${deliveryNoteNumber}&deliveryNoteRefNumber=${deliveryNoteRefNumber}`;
      }
    } else {
      if (to) {
        apiUrl = `/api/${selectedInvoice}/get?&to=${to}&from=${from}${account}${vehcile}${area}&page=${page}&limit=${pageSize}&deliveryNoteNumber=${deliveryNoteNumber}&deliveryNoteRefNumber=${deliveryNoteRefNumber}`;
      } else {
        apiUrl = `/api/${selectedInvoice}/get?&page=${page}&limit=${pageSize}&deliveryNoteNumber=${deliveryNoteNumber}&deliveryNoteRefNumber=${deliveryNoteRefNumber}`;
      }
    }

    // Check if the user has the required privilege for invoiceStatus
    if (checkPrivileges("deliverynoteInvalidView")) {
      if (invoiceStatus !== undefined) {
        apiUrl += `&invoiceStatus=${invoiceStatus}`;
      }
    } else {
      // If the user does not have the privilege, restrict invoiceStatus to 1 only if it has a value
      if (invoiceStatus !== undefined) {
        apiUrl += `&invoiceStatus=${invoiceStatus}`;
      }
    }
    if (stationCity) {
      apiUrl += `&stationCity=${stationCity}`;
    }
    if (garageVerticals) {
      const queryString = garageVerticals
        .map((item) => `&dnTypes=${item}`)
        .join("");
      apiUrl += `${queryString}`;
    }
    if (invoicemades) {
      const queryString = invoicemades
        .map((item) => `&invoiceMade=${item}`)
        .join("");
      apiUrl += `${queryString}`;
    }

    source = axios.CancelToken.source();
    this.setState(
      {
        loader: true,
      },
      () => {
        axios
          .get(apiUrl, {
            cancelToken: source.token,
          })
          .then((response) => {
            // console.log("res====",  response?.data?.data);
            // console.log("notes====",  response?.data?.data === "no delivery notes found" );
            this.setState({
              // filterInvoices:  response?.data?.data || {},
              filteredData: true,
              // page:1,
              //   hasNext : false,
              //   data:[],
              // pageSize : 20,
              // openTable: false,
              filterInvoices:
                response?.data?.data === "no delivery notes found"
                  ? {
                      page: 1,
                      hasNext: false,
                      data: [],
                      pageSize: 20,
                      total: 0,
                    }
                  : response?.data?.data || {},
              openTable: true,
              loader: false,
              openFilter: true,
              // showServices: false,
            });
          })
          .catch((e) => {
            // console.log("e ===", e);
          });
      }
    );
  };

  invoiceSelection = (item) => {
    this.fetchServices(item);
    // this.getServicesList(item)
    this.getGarageList(item.userId);
    this.getServicesList(item.userId);
    this.setState(
      {
        openFilter: true,
        selectSingleInvoice: true,
        // openFilter: false,
        singleAccount: true,
        selectedAccount: item,
        to: "",
        from: "",
        account: "",
        vehcile: "",
        area: "",
      },
      () => {
        this.getInvoices(
          this.state.to,
          this.state.from,
          item.contractNumber,
          this.state.vehcile,
          this.state.area
        );
      }
    );
  };

  fetchInvoices = async (id) => {
    let { page, pageSize, searchInvoice } = this.state;
    let cc = this.state.value === "maintenance" ? false : true;
    let api;

    if (id) {
      if (this.state.value === "maintenance") {
        api = `/api/deliverynotes/get?contractNumber=${id}&page=${page}&limit=${pageSize}&search=${searchInvoice}`;
      } else if (this.state.value === "leasing") {
        api = `/api/leaseinvoices/get?contractNumber=${id}&page=${page}&limit=${pageSize}&search=${searchInvoice}`;
      } else if (this.state.value === "cashCustomer") {
        api = `/api/cashinvoices/get?contractNumber=${id}&page=${page}&limit=${pageSize}&search=${searchInvoice}`;
      }
    } else {
      if (this.state.value === "maintenance") {
        api = `/api/deliverynotes/get?&page=${page}&limit=${pageSize}&search=${searchInvoice}`;
      } else if (this.state.value === "leasing") {
        api = `/api/leaseinvoices/get?&page=${page}&limit=${pageSize}&search=${searchInvoice}`;
      } else if (this.state.value === "cashCustomer") {
        api = `/api/cashinvoices/get?&page=${page}&limit=${pageSize}&search=${searchInvoice}`;
      }
    }
    source = axios.CancelToken.source();
    await axios
      .get(api, {
        cancelToken: source.token,
      })
      .then((response) => {
        this.setState({
          invoicesData: response?.data?.data || [],
          openTable: true,
          filteredData: false,
          showServices: true,
          // loader: false,
        });
      })
      .catch((e) => {
        console.log("e ===", e);
      });
  };

  handleChangeSearch = (name) => (event) => {
    // searchType
    this.setState({
      [name]: event.target.value,
    });
  };

  regenerateInvoices = async (to, from, account, invalidOnly) => {
    this.setState(
      {
        showRegeneratedModal: true,
      },
      () => this.regenerateBulkInvoices(to, from, account, invalidOnly)
    );
  };

  regenerateBulkInvoices = async (to, from, account, invalidOnly) => {
    let { page, pageSize, selectedAccount } = this.state;
    let result;
    if (this.state.singleAccount) {
      result = await axios.get(
        `/api/invoices/recompute?contractNumber=${selectedAccount.contractNumber}&from=${from}&to=${to}&invalidOnly=${invalidOnly}`
      );
    } else {
      result = await axios.get(
        `/api/invoices/recompute?${account}&from=${from}&to=${to}&invalidOnly=${invalidOnly}`
      );
    }

    const arr = Object.entries(result?.data?.data).map(([key, value]) => ({
      key,
      value,
    }));

    // const arr = Object.entries(result?.data?.data).map(([key, value]) => ({
    //   [key]: value,
    // }));
    this.setState({
      bulkInvoicesDate: arr,
      bulkInvoiceLoader: false,
    });
  };

  selecteItem = (item) => {
    this.setState(
      {
        selectedAccount: item,
        page: 1,
        pageSize: 20,
        itemSearch: "",
        filteredData: false,
      },
      () => {
        this.fetchInvoices(item.contractNumber);
      }
    );
  };

  saveData = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        // this.setState({ loading: false })
      }, 0);
    }
    // this.setState({ loading: false })
  };

  downloadInvoices = (
    to,
    from,
    account,
    vehcile,
    area,
    type,
    invoiceStatus,
    stationCity,
    garageVerticals,
    invoicemades
  ) => {
    let { leasing } = this.state;

    let accept;
    if (type === "downloadPdf") {
      accept = "application/pdf";
    } else {
      accept =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    let header;
    header = {
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: accept,
      }),
      method: "GET",
    };

    // {checkPrivileges("deliverynoteInvalidView") ?
    // url= `/api/deliverynotes/export?invoiceStatus=${invoiceStatus}&to=${to}&from=${from}${account}${vehcile}${area}`
    //       :
    //           url = `/api/deliverynotes/export?to=${to}&from=${from}${account}${vehcile}${area}`
    //         }

    let apiUrl = `/api/deliverynotes/export?to=${to}&from=${from}${account}${vehcile}${area}`;

    if (checkPrivileges("deliverynoteInvalidView")) {
      if (invoiceStatus !== undefined) {
        apiUrl += `&invoiceStatus=${invoiceStatus}`;
      }
    } else {
      // If the user does not have the privilege, restrict invoiceStatus to 1 only if it has a value
      if (invoiceStatus !== undefined) {
        apiUrl += `&invoiceStatus=${invoiceStatus}`;
      }
    }
    if (stationCity) {
      apiUrl += `&stationCity=${stationCity}`;
    }
    if (garageVerticals) {
      const queryString = garageVerticals
        .map((item) => `&dnTypes=${item}`)
        .join("");
      apiUrl += `${queryString}`;
    }
    if (invoicemades) {
      const queryString = invoicemades
        .map((item) => `&invoiceMade=${item}`)
        .join("");
      apiUrl += `${queryString}`;
    }

    this.setState(
      {
        loader: true,
      },
      () => {
        fetch(
          apiUrl,
          // `/api/invoices/export?leasing=${leasing}&to=${to}&from=${from}${account}${vehcile}${area}`,
          { ...header }
        )
          .then((response1) => {
            if (response1.status === 200) {
              if (type === "downloadPdf") {
                response1.blob().then((response) => {
                  this.setState(
                    {
                      selectedAreaId: "",
                      selectedvehicleId: "",
                      to: "",
                      from: "",
                      loader: false,
                    },
                    () => {
                      this.saveData(response, "deliverynotes.pdf");
                    }
                  );
                });
              } else {
                response1.blob().then((response) => {
                  this.setState(
                    {
                      selectedAreaId: "",
                      selectedvehicleId: "",
                      to: "",
                      from: "",
                      loader: false,
                    },
                    () => {
                      this.saveData(response, "deliverynotes.xlsx");
                    }
                  );
                });
              }
            } else {
              throw response1;
            }
          })

          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }
    );
  };

  fetchServices = (item) => {
    let id =
      item && item.userId
        ? item.userId
        : this.props.ServerSetting && this.props.ServerSetting.userId;
    let api =
        this.props.ServerSetting && this.props.ServerSetting.contractNumber
          ? `/api/expensetypes?userId=${id}`
          : `/api/expensetypes`,
      source = axios.CancelToken.source();
    axios
      .get(api, {
        cancelToken: source.token,
      })
      .then((response) => {
        response.data &&
          response.data &&
          response.data.data &&
          response.data.data.map((type) => {
            expenseMainTypes.push({
              key: type.id,
              id: type.id,
              type: type.name,
              name: type.name,
              subType: type.name,
            });
          });
        this.setState({ expenseMainTypes: expenseMainTypes });
        // }
      })
      .catch((e) => {
        console.log("e ===", e);
      });
  };

  onCloseModal = () => {
    this.setState({
      isVisable: false,
      activeOperation: "",
      selectedItem: "",
    });
    this.props.navigate("/contractManagement");
  };

  SearchItem = (searchValue) => {
    source.cancel();
    this.setState({
      page: 1,
      itemSearch: searchValue,
      // loader: true,
      defaultUserList: false,
    });
  };

  handleChange = (name, value) => {
    this.setState(
      {
        expenseForm: {
          ...this.state.expenseForm,
          [name]: value,
        },
      },
      () => {
        this.checkRequiredExpenses();
      }
    );
  };

  checkRequiredExpenses = () => {
    const { name, contractNumber } = this.state.expenseForm;
    if (name && contractNumber) {
      this.setState({ isVisableSubmitBtn: false });
    } else this.setState({ isVisableSubmitBtn: true });
  };

  closeExpenseForm = () => {
    this.setState({ openExpenseForm: false, mode: "create" });
  };

  submitExpenseForm = () => {
    let url =
      this.state.mode === "create"
        ? `/api/expensetypes/`
        : `/api/expensetypes/${this.state.expenseForm.id}`;
    fetch(url, {
      method: this.state.mode === "create" ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...this.state.expenseForm,
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.setState(
            { createSubType: false, openExpenseForm: false },
            () => {
              this.fetchAccountServices(this.state.selectedAccount.id);
            }
          );

          toast.success(
            this.state.mode === "create"
              ? this.props.translate("serviceCreated")
              : this.props.translate("serviceUpdated")
          );
        }
      })
      .catch((error) => {
        console.log("error ==", error);
      });
  };

  editExpenseForm = (item) => {
    this.setState({
      expenseForm: item,
      openExpenseForm: true,
      mode: "update",
      openTable: false,
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  searchItem = (e) => {
    let text = e.target.value;
    const u =
      this.state.allServicesList &&
      this.state.allServicesList.data &&
      this.state.allServicesList.data.filter((u) =>
        (u.id + u.name).toLowerCase().includes(text.toLowerCase())
      );
    this.setState({ selectedServices: u });
  };

  searchInvoices = (e, name) => {
    source.cancel();

    // Check if the provided name is either "deliveryNoteNumber" or "deliveryNoteRefNumber"
    if (name === "deliveryNoteNumber" || name === "deliveryNoteRefNumber") {
      this.setState({ [name]: e.target.value }, () => {
        // this.getInvoices(
        //   this.state.to,
        //   this.state.from,
        //   this.state.account || this.state.selectedAccount.contractNumber,
        //   this.state.vehcile,
        //   this.state.area
        // );
      });
    }
  };
  searchgetInvoices = () => {
    this.getInvoices(
      this.state.to,
      this.state.from,
      this.state.account || this.state.selectedAccount.contractNumber,
      this.state.vehcile,
      this.state.area
    );
  };

  setPagination = () => {
    let nAcc;

    if (
      this.state.openFilter ||
      this.state.filteredData ||
      this.state.showServices
    ) {
      nAcc = { ...this.state.filterInvoices };
    }
    // else if (this.state.showServices) {
    //   nAcc = { ...this.state.invoicesData };
    // }
    else {
      nAcc = { ...this.state.invoiceData };
    }

    let lastPage = nAcc.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nAcc.data;
    nAcc.pageSize = this.state.pageSize;
    nAcc.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nAcc;
    // }
    // return {}
  };

  handleChangeRowsPerPage = (value) => {
    if (
      this.state.openFilter ||
      this.state.filteredData ||
      this.state.showServices
    ) {
      this.setState({ page: 1, pageSize: value }, () =>
        this.getInvoices(
          this.state.to,
          this.state.from,
          this.state.account || this.state.selectedAccount.contractNumber,
          this.state.vehcile,
          this.state.area
        )
      );
    } else {
      this.setState({ page: 1, pageSize: value }, () =>
        this.getContractsData()
      );
    }
  };

  handleChangePage = (value) => {
    if (
      this.state.openFilter ||
      this.state.filteredData ||
      this.state.showServices
    ) {
      console.log("va 1======", value, this.state.pageSize);

      this.setState({ page: value }, () =>
        this.getInvoices(
          this.state.to,
          this.state.from,
          this.state.account || this.state.selectedAccount.contractNumber,
          this.state.vehcile,
          this.state.area
        )
      );
    } else {
      console.log("va2======", value, this.state.pageSize);

      this.setState({ page: value }, () => this.getContractsData());
    }
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  goBack = () => {
    this.setState({
      // this.state.openFilter ||
      // this.state.filteredData ||
      // this.state.showServices
      // cashCustomer:false,
      deliveryNoteNumber: "",
      deliveryNoteRefNumber: "",
      singleAccount: false,
      openTable: false,
      showServices: false,
      filteredData: false,
      openFilter: false,
      selectSingleInvoice: false,
    });
  };

  searchInvoiceText = (e) => {
    source.cancel();
    this.setState(
      {
        searchInvoiceText: e.target.value,
      },
      () => {
        this.getContractsData();
      }
    );
  };

  openImportTable = (item) => {
    const array = Object.entries(item).map(([invoiceNo, msg]) => ({
      invoiceNo: parseInt(invoiceNo),
      msg,
    }));
    this.setState({
      showImportedInvoiceError: true,
      importedInvoiceData: array,
    });
  };

  onCloseImportedModal = () => {
    this.setState({
      showImportedInvoiceError: false,
      showRegeneratedModal: false,
      // bulkInvoicesDate: "",
    });
  };

  openFilter = () => {
    this.setState(
      {
        to: "",
        from: "",
        account: "",
        vehcile: "",
        area: "",
        openFilter: true,
        singleAccount: false,
        selectSingleInvoice: false,
        // loader: true,
        // pageSize:-1
      },
      () => {
        this.getInvoices(
          this.state.to,
          this.state.from,
          this.state.account,
          this.state.vehcile,
          this.state.area
        );
        // ; this.getMoreAccounts()
      }
    );
  };
  getContractsData = () => {
    let { page, pageSize, searchInvoiceText, value, openFilter, userTypes } =
      this.state;
    // let newPAgeSize;
    let newPageSize = openFilter ? -1 : 20;
    // let cc = value === "maintenance" ? 1 : 2;
    let cc =
      value === "maintenance"
        ? 1
        : this.state.value === "leasing"
        ? 2
        : this.state.value === "cashCustomer"
        ? 3
        : "";

    let apiUrl;
    if (value === "leasing") {
      apiUrl = `/api/leaseinvoices/contracts?page=${page}&limit=${pageSize}&search=${searchInvoiceText}${
        userTypes || ""
      }`;
    } else if (value === "maintenance") {
      apiUrl = `/api/deliverynotes/contracts?page=${page}&limit=${pageSize}&search=${searchInvoiceText}${
        userTypes || ""
      }`;
    } else if (value === "cashCustomer") {
      apiUrl = `/api/cashinvoices/contracts?page=${page}&limit=${pageSize}&search=${searchInvoiceText}${
        userTypes || ""
      }`;
    }
    source = axios.CancelToken.source();

    this.setState(
      {
        loader1: true,
        contractType: cc,
      },
      () => {
        axios
          .get(apiUrl, {
            cancelToken: source.token,
          })
          .then((response) => {
            this.setState({
              invoiceData: response?.data?.data || {},
              loader1: false,
            });
          })
          .catch((e) => {
            console.log("e ===", e);
          });
      }
    );
  };

  getMoreAccounts = () => {
    let cc =
      this.state.value === "maintenance"
        ? 1
        : this.state.value === "leasing"
        ? 2
        : this.state.value === "cashCustomer"
        ? 3
        : "";
    let { page, pageSize, searchInvoiceText } = this.state;
    let apiUrl = `/api/accounts/get?userId=${this.props.logInUser.id}&contractType=${cc}&all=true&page=${page}&limit=-1&search=${searchInvoiceText}`;

    source = axios.CancelToken.source();

    this.setState(
      {
        accounts: [],
        // pageSize:newPageSize,
        // loader: true // set loader to true
      },
      () => {
        axios
          .get(apiUrl, {
            cancelToken: source.token,
          })
          .then((response) => {
            if (response.status === 200) {
              // this.props.dispatch(getAccounts(response.data.data));
              this.setState({
                accounts: response?.data?.data.data || {},
                // loader: false
              });
            }
          })
          .catch((e) => {
            // if (axios.isCancel(e)) {
            // }
          });
      }
    );
  };

  // SearchItem = (searchValue) => {
  //   source.cancel();
  //   this.setState(
  //     {
  //       page: 1,
  //       searchInvoiceText: searchValue.target.value,
  //     },
  //     () => this.getMoreAccounts()
  //   );
  // };

  openFilter1 = () => {
    this.setState({
      openFilter1: !this.state.openFilter1,
    });
  };
  handleClickFilter = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleCloseFilter = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeFilter = (name, event) => {
    this.setState(
      (prevState) => {
        // Update the statusFilter and count of selected checkboxes
        const updatedStatusFilter = {
          ...prevState.statusFilter,
          [name]: event.target.checked,
        };

        // Generate `userTypes` query string
        const userTypes = Object.keys(updatedStatusFilter)
          .filter((key) => updatedStatusFilter[key]) // Include only checked filters
          .map((key) => `&userTypes=${key}`)
          .join("");

        return {
          statusFilter: updatedStatusFilter,
          countChecked:
            Object.values(updatedStatusFilter).filter(Boolean).length,
          userTypes,
          page: 1,
        };
      },
      () => {
        this.getContractsData(); // Fetch updated data
      }
    );
  };
  render() {
    const { value } = this.state;
    if (this.props.logInUser) {
      return (
        <Layout
          {...this.props}
          {...this.state}
          contractType={this.state.contractType}
          // getContractsData={this.getMoreAccounts}
          SearchItem={this.SearchItem}
          addItem={this.addItem}
          selecteFilters={this.selecteFilters}
          selecteItem={this.selecteItem}
          removedItem={this.removedItem}
          editItem={this.editItem}
          downloadInvoices={this.downloadInvoices}
          regenerateInvoices={this.regenerateInvoices}
          noSidebar={
            this.state.openFilter || this.state.selectSingleInvoice
              ? ""
              : "noSidebar"
          }
        >
          {!this.state.showServices && !this.state.openFilter ? (
            <>
              <Grid
                container
                columns={{ xs: 12, sm: 12 }}
                style={{ display: "flex" }}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h4>{this.props.translate("Contracts")}</h4>
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleChangeTabs("maintenance")}
                    style={{
                      width: "50%",
                      borderTopRightRadius: " 15px",
                      borderTopLeftRadius: "15px",
                      marginLeft: "5px",
                      backgroundColor:
                        value === "maintenance" ? "#00C179" : "#ffff",
                      color: value === "maintenance" ? "#ffff" : "#00C179",
                    }}
                  >
                    {this.props.translate("maintenance")}
                  </Button>

                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={() => this.handleChangeTabs("leasing")}
                    style={{
                      width: "50%",
                      borderTopRightRadius: " 15px",
                      borderTopLeftRadius: "15px",
                      marginLeft: "5px",
                      backgroundColor:
                        value === "leasing" ? "#00C179" : "#ffff",
                      color: value === "leasing" ? "#ffff" : "#00C179",
                    }}
                  >
                    {" "}
                    {this.props.translate("leasing")}
                  </Button>
                  <Button
                  variant="contained"
                  disabled={false}
                  onClick={() => this.handleChangeTabs("cashCustomer")}
                  style={{
                    width: "50%",
                    borderTopRightRadius: " 15px",
                    borderTopLeftRadius: "15px",
                    marginLeft: "5px",
                    backgroundColor: value === "cashCustomer" ? "#00C179" : "#ffff",
                    color: value === "cashCustomer" ? "#ffff" : "#00C179",
                  }}
                >
                  {" "}
                  {this.props.translate("cashCustomer")}
                </Button> */}
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div style={{ width: "15%", marginRight: 5, marginTop: 6 }}>
                    <Button
                      // disabled={devices.data && devices.data.length === 0}
                      aria-label="filter"
                      size="small"
                      color="inherit"
                      onClick={this.openFilter}
                      style={{ marginRight: "10px" }}
                    >
                      {this.props.translate("allDeliveryNotes")}
                    </Button>
                  </div>
                  <div style={{ width: "25%", marginRight: 20 }}>
                    <TextField
                      fullWidth
                      label={this.props.translate("search")}
                      value={this.state.searchInvoiceText}
                      onChange={(e) => this.searchInvoiceText(e)}
                    />
                  </div>
                  <div style={{ width: "20%", marginRight: 5, marginTop: 6 }}>
                    {this.state.openFilter1 && (
                      <div style={{ marginRight: 20 }}>
                        <Filter
                          countChecked={this.state.countChecked}
                          anchorEl={this.state.anchorEl}
                          handleChangeFilter={this.handleChangeFilter}
                          handleClickFilter={this.handleClickFilter}
                          handleCloseFilter={this.handleCloseFilter}
                          statusFilter={this.state.statusFilter}
                          userType={this.props.logInUser.userType}
                        />
                      </div>
                    )}
                    {!this.state.openFilter1 && (
                      <Button
                        aria-label="filter"
                        size="small"
                        color="inherit"
                        onClick={this.openFilter1}
                        style={{ marginRight: "20px" }}
                      >
                        {this.props.translate("filter")}
                      </Button>
                    )}
                  </div>
                  <div style={{ width: "10%", marginRight: 5, marginTop: 6 }}>
                    <ExportImportMenu
                      fileName="deliverynotes"
                      checkPrivilege="serviceCreate"
                      isServices
                      data={this.state?.invoicesData?.data || []}
                      themecolors={this.props.themecolors}
                      categoryName={this.state?.selectedServiceTypeId || ""}
                      openImportTable={this.openImportTable}
                      translate={this.props.translate}
                      noPdf
                    />
                  </div>
                </Grid>
              </Grid>

              {this.state.loader1 ? (
                <Loader />
              ) : (
                <Table
                  rows={
                    (this.state.invoiceData && this.state.invoiceData.data) ||
                    []
                  }
                  {...this.props}
                  pagination={this.setPagination()}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleChangePage={this.handleChangePage}
                  SearchItem={this.SearchItem}
                  onClick={this.invoiceSelection}
                  btnName1={"accounts"}
                  rowsPerPage={15}
                  isEditable={true}
                  themecolors={this.props.themecolors}
                  filterDropdown
                  // setSerialNo
                  isCursoPointer
                  rowDefinition={
                    this.props.value === "cashCustomer"
                      ? [
                          {
                            id: "issuingParty",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("accountName"),
                          },
                          {
                            id: "receivingParty",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("receivingParty"),
                          },
                          {
                            id: "contractNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("contractNumber"),
                          },
                          {
                            id: "creditDebitDate",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("creditDebitDate"),
                          },
                          {
                            id: "created",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("created"),
                          },
                          {
                            id: "netAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("netAmount"),
                          },
                          {
                            id: "refNum",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("refNum"),
                          },
                        ]
                      : [
                          {
                            id: "accountName",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("accountName"),
                          },
                          {
                            id: "contractName",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("contractName"),
                          },
                          {
                            id: "contractNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("contractNumber"),
                          },
                          {
                            id: "contractStartDate",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("contractStartDate"),
                          },
                          {
                            id: "contractEndDate",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("contractEndDate"),
                          },
                          {
                            id: "totalBalance",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("totalBalance"),
                          },
                          {
                            id: "totalPaid",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("totalPaid"),
                          },
                          {
                            id: "totalRevenue",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("totalRevenue"),
                          },
                        ]
                  }
                />
              )}
            </>
          ) : null}

          {(this.state.openTable && this.state.showServices) ||
          this.state.openFilter ? (
            <>
              <Grid
                style={{ flex: 1, flexDirection: "row", background: "#fff" }}
              >
                <InvoiceModal
                  {...this.state}
                  getInvoices={this.searchgetInvoices}
                  handleChangeSearch={this.handleChangeSearch}
                  invoicesData={
                    this.state.openFilter || this.state.filteredData
                      ? this.state.filterInvoices
                      : this.state.invoicesData
                  }
                  // invoicesData={this.state.invoicesData}
                  selectedSubTypesAllServices={
                    this.state.selectedSubTypesAllServices
                  }
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  translate={this.props.translate}
                  // rowsPerPage={this.state.rowsPerPage}

                  // this.getServicesList(item)
                  getGarageList={this.getGarageList}
                  fetchServices={this.fetchServices}
                  getServicesList={this.getServicesList}
                  handleChangePage={this.handleChangePage}
                  searchInvoices={this.searchInvoices}
                  themecolors={this.props.themecolors}
                  selectedServiceTypeId={
                    this.state?.selectedServiceTypeId || ""
                  }
                  selectService={this.selectService}
                  setPagination={this.setPagination}
                  expenseMainTypes={expenseMainTypes}
                  selectedAccount={this.state?.selectedAccount || {}}
                  fetchInvoices={this.fetchInvoices}
                  deliveryNoteNumber={this.state.deliveryNoteNumber}
                  deliveryNoteRefNumber={this.state.deliveryNoteRefNumber}
                  // servicesList={servicesList}
                  // garageList={garageList}
                  goBack={this.goBack}
                  leasing={this.state.value === "maintenance" ? false : true}
                />
              </Grid>
            </>
          ) : null}

          {this.state.showRegeneratedModal && (
            <CustomDialog
              title="Regenerated Invoice"
              visable={true}
              onClose={this.onCloseImportedModal}
              bodyPadding={10}
              hideDragIcon
              fullWidth
              isVisableBtn
              // noFullScreen
            >
              {this.state.bulkInvoiceLoader ? (
                <Loader component="filter" defaultStyle />
              ) : (
                <Table1
                  rows={
                    (this.state.bulkInvoicesDate &&
                      this.state.bulkInvoicesDate) ||
                    []
                  }
                  translate={this.props.translate}
                  // pagination={this.setPagination()}
                  // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  // handleChangePage={this.handleChangePage}
                  // SearchItem={this.SearchItem}
                  // onClick={this.invoiceSelection}
                  // btnName1={"accounts"}
                  rowsPerPage={10}
                  // isEditable={true}
                  themecolors={this.props.themecolors}
                  // filterDropdown
                  // setSerialNo
                  isCursoPointer
                  rowDefinition={[
                    {
                      id: "key",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("invoiceNumber"),
                    },
                    {
                      id: "value",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("invoiceResponse"),
                    },
                  ]}
                />
              )}
            </CustomDialog>
          )}

          {this.state.showImportedInvoiceError && (
            <CustomDialog
              title="Imported Invoice"
              visable={true}
              onClose={this.onCloseImportedModal}
              bodyPadding={10}
              hideDragIcon
              fullWidth
              isVisableBtn
              noFullScreen
            >
              <Table1
                rows={this.state.importedInvoiceData || []}
                rowsPerPage={10}
                // isEditable={true}
                themecolors={this.props.themecolors}
                translate={this.props.translate}
                // filterDropdown
                // setSerialNo
                isCursoPointer
                rowDefinition={[
                  {
                    id: "invoiceNo",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("invoiceNumber"),
                  },
                  {
                    id: "msg",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("invoiceResponse"),
                  },
                ]}
              />

              {/* <div>
                <table>
                  <tr
                    style={{
                      borderBottom: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td>
                      <strong>Invoice no</strong>
                    </td>
                    <td>
                      <strong>Error</strong>
                    </td>
                  </tr>

                  {Object.entries(this.state.importedInvoiceData).map(
                    (item, i) => {
                      return (
                        <tr
                          style={{
                            borderBottom: "1px solid black",
                            paddingTop: 2,
                          }}
                        >
                          <td style={{ padding: 10 }}>
                            <strong>{item[0]}</strong>
                          </td>
                          <td style={{ padding: 10 }}>{item[1]}</td>
                        </tr>
                      );
                    }
                  )}
                </table>
              </div> */}
            </CustomDialog>
          )}
        </Layout>
      );
    } else {
      fetch(
        "/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05",
        {
          headers: { Accept: "application/json; odata=verbose" },
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((res) => {
              if (res.status === "success") {
                let userInfo = res.data;
                this.props.setActiveLanguage(
                  userInfo?.attributes?.lang || "en"
                );
                this.props.dispatch(logInUserInfo(userInfo));
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");

                toast.error(this.props.translate(err[1]));
                this.props.navigate("/login");
              }
            });
          } else {
            this.props.navigate("/login");
            throw response;
          }
        })
        .catch((e) => {
          toast.error("somethingWentWrong");
        });
      return null;
    }
  }
}

const Filter = (props) => {
  const html = (
    <div>
      <a
        className="v-filter-field"
        href={null}
        aria-owns={props.anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={props.handleClickFilter}
      >
        {props.countChecked} Selected
      </a>
      <UnitMenu
        id="simple-menu"
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        onClose={props.handleCloseFilter}
      >
        <MenuItem
          component={FormControlLabel}
          style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
          control={
            <Checkbox
              name="user"
              checked={props.statusFilter[1]}
              onChange={(e) => props.handleChangeFilter("1", e)}
              canAssign
              canRemove
            />
          }
          label="User"
        />
        <MenuItem
          component={FormControlLabel}
          style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
          control={
            <Checkbox
              name="admin"
              checked={props.statusFilter[2]}
              onChange={(e) => props.handleChangeFilter("2", e)}
              canAssign
              canRemove
            />
          }
          label="Admin"
        />
        {(props.userType > 3 || props?.userType === -1) && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="adminAccount"
                checked={props.statusFilter[3]}
                onChange={(e) => props.handleChangeFilter("3", e)}
                canAssign
                canRemove
              />
            }
            label="Admin Account"
          />
        )}
        {(props.userType > 4 || props?.userType === -1) && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="ServiceProvider"
                checked={props.statusFilter[4]}
                onChange={(e) => props.handleChangeFilter("4", e)}
                canAssign
                canRemove
              />
            }
            label="Service Provider"
          />
        )}
        {(props.userType > 5 || props?.userType === -1) && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="Reseller"
                checked={props.statusFilter[5]}
                onChange={(e) => props.handleChangeFilter("5", e)}
                canAssign
                canRemove
              />
            }
            label="Reseller"
          />
        )}
        {props.userType === -1 && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="Distributor"
                checked={props.statusFilter[6]}
                onChange={(e) => props.handleChangeFilter("6", e)}
                canAssign
                canRemove
              />
            }
            label="Distributor"
          />
        )}
      </UnitMenu>
    </div>
  );
  return <div>{html}</div>;
};

const ExpenseForm = ({
  form,
  handleChange,
  translate,
  submitForm,
  closeEditForm,
  mode,
  isVisableSubmitBtn,
  checkPrivileges,
}) => {
  return (
    <div style={{ padding: 10 }}>
      <h4 className="page-title">{translate("services")}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="name"
            required
            margin="dense"
            label={translate("sharedName")}
            variant="outlined"
            fullWidth
            value={form?.name || ""}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="description"
            margin="dense"
            label={translate("description")}
            variant="outlined"
            fullWidth
            value={form?.description || ""}
            onChange={(e) => handleChange("description", e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id='customerNumber'
              margin='dense'
              label={translate('Customer Number')}
              variant='outlined'
              fullWidth
              value={form?.customerNumber || ''}
              onChange={e => handleChange('customerNumber', e.target.value)}
            />
          </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="contractNumber"
            margin="dense"
            label={translate("contractNumber")}
            variant="outlined"
            fullWidth
            readOnly
            // type='number'
            value={form?.contractNumber || ""}
            // onChange={e => handleChange('contractNumber', e.target.value)}
          />
        </Grid>
      </Grid>
      <div style={{ display: "flex", marginTop: 10 }}>
        <div style={{ marginLeft: "auto" }} />
        <Button
          size="small"
          style={{ marginRight: 15 }}
          className="button-white"
          onClick={closeEditForm}
        >
          {translate("sharedCancel")}{" "}
        </Button>
        {checkPrivileges("expensetypeCreate") && (
          <Button
            size="small"
            onClick={submitForm}
            disabled={isVisableSubmitBtn}
          >
            {translate(mode === "update" ? "update" : "sharedCreate")}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accounts: state.accounts,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themecolors,
});

export default connect(mapStateToProps)(withRoutes(ContractManagment));

import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Loader from "./Loader";
import {  setCookie } from "../Helpers";
import CommonSection from "../Helpers/commonSection";
import { checkPrivileges } from "../Helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./index.scss";
import withRoutes from "../HOC/HocRoutes";
import Dialog from "../Components/common/Dialog";

export function IsAuthenticated(props) {
  const info = JSON.parse(localStorage.getItem("userInfo"));
  if (info && info.id) {
    return true;
  } else {
    if (props && props.history) {
      props.history.push("/logout");
    }
  }
  return false;
}

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      collapse_sidebar: "",
      isOpenDubiousDelivery:false,
    };

    this.collapseSidebar = this.collapseSidebar.bind(this);
  }
  collapseSidebar(value) {
    this.setState({ collapse_sidebar: value || "" });
  }

  handleBackToAdmin = () => {
    //
    fetch("/api/session", {
      method: "DELETE",
    })
      .then((response) => {
        response.json().then((res) => {
          if (res.status === "success") {
            localStorage.setItem("token", "");
            setCookie("JSESSIONID", 0);
            localStorage.setItem("userInfo", "");
            localStorage.setItem("domainToken", "");
            this.props.navigate("/login");
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
         
              toast.error(
                this.props.translate(err[1])
            );
          }
        });
      })
      .catch((e) => {
     
          toast.error(
           "somethingWentWrong"
        );
      });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.logInUser?.dueDeliveryNoteCount !== this.props.logInUser?.dueDeliveryNoteCount) {
      const newCount =this.props.logInUser?.dueDeliveryNoteCount|| 0;
      const isDubiousDeliveryShown = localStorage.getItem('isDubiousDeliveryShow');
      if(isDubiousDeliveryShown&&this.props.logInUser&&newCount > 0){
  this.handleOpenDubiousDeliveryCount()
      }
    }
  }
  handleOpenDubiousDeliveryCount=()=>{
    this.setState({
      isOpenDubiousDelivery:true
    },()=>{
      localStorage.removeItem("isDubiousDeliveryShow")
    })
  }
  dubiousDeliveryModalClose=()=>{
    this.setState({isOpenDubiousDelivery: false})
    
  }


  render() {
    if (this.props.logInUser.id && IsAuthenticated(this.props)) {
      return (
        <div
          className={
            this.state.collapse_sidebar +
            (this.props.sidebarToggle ? " side-menu-open " : " ")
          }
          style={{background:"#FFFFFF"}}
        >
          <CommonSection {...this.props} />
          {/* <NotificationSystem /> */}
          <Header
            {...this.props}
            userInfo={this.props.logInUser}
            handleDrawer={this.props.handleDrawer}
          />
          {this.props.logInUser &&
          checkPrivileges("rptmpl") &&
          ((this.props?.location &&
            this.props?.location?.pathname &&
            this.props?.location?.pathname?.indexOf &&
            this.props.location.pathname.indexOf("/roleManagement") !== -1) ||
            this.props.location.pathname.indexOf("/contractManagement") !==
              -1 ||
            this.props.location.pathname.indexOf("/new_payment") !== -1 ||
            this.props.location.pathname.indexOf("/templateGenerator") !== -1 ||
            this.props.location.pathname.indexOf("/reports") !== -1 ||
            this.props.location.pathname.indexOf("/triggers") !== -1 ||
            this.props.location.pathname.indexOf("/schedule-Reports-And-Notifications") !== -1 ||
            this.props.location.pathname.indexOf("/missing-items") !== -1 ||
            this.props.location.pathname.indexOf("/missed-opportunities") !== -1 ||
            this.props.location.pathname.indexOf("/delivery-note") !== -1 ||
            this.props.location.pathname.indexOf("/simah") !== -1 ||
            this.props.location.pathname.indexOf("/credit_debit_notes") !==
              -1 ||
            this.props.location.pathname.indexOf("/invoice") !== -1) ? (
            <Sidebar {...this.props} sidebarClose={this.collapseSidebar} />
              ) :null}

          <div
            className={
              // checkPrivileges("accountHidebar")?"main-view-1 no-sidebar":
              " main-view no-sidebar " +
              (this.props.sidebarToggle ? " side-menu-open " : " ") +
              this.props.classFromChildren
            }
            >
            {localStorage.getItem("adminToken") ||
            localStorage.getItem("domainToken") ? (
              <>
                <div className="demo-notification-bar notification-info">
                  <span>
                    {this.props.translate(
                      "youAreTemporarilyLoggedInAsAnotherUser"
                    )}
                  </span>
                  <a
                    style={{ cursor: "pointer" }}
                    href={null}
                    onClick={this.handleBackToAdmin}
                  >
                    {this.props.translate("switch")}
                  </a>
                  <span>{this.props.translate("backToYourAccount")}</span>
                </div>
              </>
            ) : (
              ""
            )}
            
              {/* {this.props.MaintenanceDashboard &&(
                <div className="has-padding">
                  <h3>Dashboard</h3>
                </div>)} */}
      {/* { console.log("this.props.MaintenanceDashboard====", this.props.MaintenanceDashboard)} */}
            <div className="has-padding" style={{background:this.props.MaintenanceDashboard ?"#FFFFFF": "",
                    margin:this.props.MaintenanceDashboard?"10px 20px 0 5px": ""}}>{this.props.children}</div>
          </div>
          {this.state.isOpenDubiousDelivery&&checkPrivileges("deliverynoteMoreThanOneVehicleView") ? (
                      <Dialog
                        open
                        isDubiousDelivery
                        // title={this.props.translate('sendCommand')}
                        title={`${this.props.translate('Alert : Repeated Service')}`}
                        themeColors={this.props.themecolors}
                        disableFooter
                        noFullScreen={true}
                        onClose={this.dubiousDeliveryModalClose}
                        dialogHeight={150}
                        draggable
                        bodyPadding={0}
                      >
<div style={{ padding: "16px" }}>
  <h2 style={{ fontWeight: 400, marginBottom: "10px" }}>
    Dear Valued Client,<br />
    You have <strong>{this.props.logInUser?.dueDeliveryNoteCount ?? 0}</strong> repeated services in the last <strong>7</strong> days.
  </h2>
  <h2 style={{ marginTop: "15px", fontWeight: 400, textAlign: "right" }}>
    عزيزي العميل،<br />
    لديك <strong>{this.props.logInUser?.dueDeliveryNoteCount ?? 0}</strong> خدمات مكررة خلال <strong>آخر ٧ أيام</strong>.
  </h2>
</div>



                      </Dialog>):null}
                     

          <Footer {...this.props} />
        </div>
      );
    } else {
      return <Loader />;
    }
  }
}

const mapState = (state) => ({
  logInUser: state.logInUsers,
  deviceLimit: state.deviceLimit,
});

const mapStateToProps = connect(mapState);

export default mapStateToProps(withRoutes(Layout));
